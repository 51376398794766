import { Dispatch, SetStateAction } from 'react';
import { v4 as uuid } from 'uuid';
import { PrivateReplyActionType } from '../../../../pages/automation/constants';
import { ResponseActionType } from '../../../../pages/automation/types';
import { getSegregatedBroadcasts } from '../../../../pages/broadcasts/utils';
import { GCChildCondition, GCChildConditionsIfStatement, GCChildConditionsIfStatementVariableType, GCConditions } from '../../../../types/api-calls';
import { BroadcastsUI, TaggingGC, VariablesTypeUI, VariableType } from '../../../entities/entities.types';
import { PlatformActionConfigPayload } from '../../../platform-action-config/platform-action-config.types';
import {
  botPlatformsTooltip,
  geoVariable,
  getNewAgeGroups,
  operatorsForBroadcasts,
  operatorsForDistanceValueUnit,
  operatorsSupportingValueUnit,
  unitTypesValue,
  userInboxLabelVariable,
} from '../tagging-gc-content.constants';

export const addMoreIfStatements = (
  updatedTaggingGC: GCConditions,
  setUpdatedTaggingGC: (conditions: GCConditions) => void,
  id?: string,
  newVariable?: GCChildConditionsIfStatementVariableType,
) => {
  setUpdatedTaggingGC({
    ...updatedTaggingGC,
    childConditions:
      updatedTaggingGC && updatedTaggingGC.childConditions
        ? updatedTaggingGC.childConditions.map((childCondition: GCChildCondition) => {
            if (childCondition.id === id) {
              return {
                ...childCondition,
                ifStatements: [...childCondition.ifStatements, getNewRule(newVariable)],
              };
            }
            return childCondition;
          })
        : [],
  });
};

const getNewGroup = () => {
  return {
    id: uuid(),
    logicalOperator: 'and',
    ifStatements: [
      {
        id: uuid(),
        variable: { type: '', macro: '', name: '' },
        condition: 'equal',
        value: '',
        valueUnit: unitTypesValue.DAYS,
      },
    ],
  };
};
const getNewRule = (newVariable?: GCChildConditionsIfStatementVariableType) => {
  return {
    id: uuid(),
    variable: newVariable || { type: '', macro: '', name: '' },
    condition: 'equal',
    value: '',
    valueUnit: unitTypesValue.DAYS,
  };
};

export const addGroupToConditions = (updatedTaggingGC: GCConditions, setUpdatedTaggingGC: (conditions: GCConditions) => void) => {
  setUpdatedTaggingGC({
    ...updatedTaggingGC,
    logicalOperator: updatedTaggingGC && updatedTaggingGC.logicalOperator ? updatedTaggingGC.logicalOperator : 'and',
    childConditions: updatedTaggingGC && updatedTaggingGC.childConditions ? [...updatedTaggingGC.childConditions, getNewGroup()] : [getNewGroup()],
  });
};
export const removeRule = (updatedTaggingGC: GCConditions, setUpdatedTaggingGC: (conditions: GCConditions) => void, groupId?: string, ruleId?: string[]) => {
  setUpdatedTaggingGC({
    ...updatedTaggingGC,
    childConditions:
      updatedTaggingGC?.childConditions &&
      updatedTaggingGC.childConditions
        .map((childCondition: GCChildCondition) => {
          if (childCondition.id === groupId) {
            const ifStatements = childCondition.ifStatements.filter((ifStatement: GCChildConditionsIfStatement) => !(ruleId || []).includes(ifStatement.id || ''));
            return {
              ...childCondition,
              ifStatements,
            };
          }
          return childCondition;
        })
        .filter((childCondition: GCChildCondition) => childCondition.ifStatements.length),
  });
};

export const createAgeGroupCondition = (updatedConditions: GCConditions, setUpdatedConditions: (conditions: GCConditions) => void, groupId?: string, ruleId?: string) => {
  const childConditions = updatedConditions?.childConditions?.map((childCondition: GCChildCondition) => {
    if (groupId === childCondition.id) {
      childCondition.logicalOperator = 'and';
      childCondition.ifStatements = childCondition.ifStatements.slice();
      const newAgeGroups = getNewAgeGroups();
      childCondition.ifStatements.splice(
        childCondition.ifStatements.findIndex((ifStatement: GCChildConditionsIfStatement) => ifStatement.id === ruleId),
        1,
        ...newAgeGroups,
      );
    }
    return childCondition;
  });
  setUpdatedConditions({ ...updatedConditions, childConditions });
};

export const updateAgeGroupCondition = (
  updatedConditions: GCConditions,
  setUpdatedConditions: (conditions: GCConditions) => void,
  groupId: string,
  condition: string,
  ruleIds: string[],
) => {
  const childConditions = updatedConditions?.childConditions?.map((childCondition: GCChildCondition) => {
    return groupId === childCondition.id
      ? {
          ...childCondition,
          ifStatements: childCondition.ifStatements.map((ifStatement: GCChildConditionsIfStatement) => {
            if (ruleIds.includes(ifStatement.id || '')) {
              return {
                ...ifStatement,
                value: '',
                metadata: { ...(ifStatement.metadata || {}), condition },
              };
            }
            return ifStatement;
          }),
        }
      : childCondition;
  });
  setUpdatedConditions({
    ...updatedConditions,
    childConditions,
  });
};

export const updateGroupRule = (
  updatedConditions: GCConditions,
  setUpdatedConditions: (conditions: GCConditions) => void,
  groupId?: string,
  ruleId?: string,
  key?: string,
  value?: string | GCChildConditionsIfStatementVariableType | null | { [key: string]: any },
) => {
  const childConditions = updatedConditions?.childConditions?.map((childCondition: GCChildCondition) => {
    if (groupId === childCondition.id) {
      // ruleId is passed as null if group level object has to be changed

      if (ruleId) {
        return {
          ...childCondition,
          ifStatements: childCondition.ifStatements.map((ifStatement: GCChildConditionsIfStatement) => {
            if (ifStatement.id === ruleId) {
              if (key === 'variable' && ifStatement?.variable?.macro === userInboxLabelVariable.macro && (value !== userInboxLabelVariable.macro || !value)) {
                ifStatement.value = '';
              }
              if (key === 'condition' && typeof value === 'string') {
                if (operatorsForBroadcasts?.includes(value)) {
                  ifStatement.value = '';
                  ifStatement.variable = { name: '', macro: '', id: '' };
                } else if (!operatorsForBroadcasts?.includes(value) && checkBroadcastSelectedTargetConditions(ifStatement?.variable?.macro)) {
                  ifStatement.variable = { name: '', macro: '', type: '' };
                }
              }

              if (!(operatorsForDistanceValueUnit.includes(ifStatement.condition) || operatorsSupportingValueUnit.includes(ifStatement.condition))) {
                delete ifStatement['valueUnit'];
                delete ifStatement['metadata'];
              }
              if (operatorsSupportingValueUnit.includes(ifStatement.condition)) {
                delete ifStatement['metadata'];
              }
              return {
                ...ifStatement,
                [key as string]: value,
              };
            }
            return ifStatement;
          }),
        };
      }
      return {
        ...childCondition,
        [key as string]: value,
      };
    }
    return childCondition;
  });

  setUpdatedConditions({
    ...updatedConditions,
    childConditions,
  });
};
export const editTargetingCondition = (updatedTaggingGC: GCConditions, setUpdatedTaggingGC: (conditions: GCConditions) => void, logicalOperator: string) => {
  setUpdatedTaggingGC({
    ...updatedTaggingGC,
    logicalOperator,
  });
};

export const handleReplyTypeChange = (updatedTaggingGC: TaggingGC, setUpdatedTaggingGC: Dispatch<SetStateAction<TaggingGC>>, actionId: string, actionType: string) => {
  const { actions = [] } = updatedTaggingGC;

  const updatedActions = actions.map((action: ResponseActionType) => {
    if (action.id === actionId) {
      const actionToUpdate = {
        ...action,
        type: actionType,
      };
      if ([PrivateReplyActionType.TEXT, PrivateReplyActionType.PUBLIC, PrivateReplyActionType.TAG].includes(actionType)) {
        delete actionToUpdate.replyNodes;
      } else if ([PrivateReplyActionType.MODULE].includes(actionType)) {
        delete actionToUpdate.replyMessage;
      } else {
        delete actionToUpdate.replyNodes;
        delete actionToUpdate.replyMessage;
      }
      return actionToUpdate;
    }
    return action;
  });

  setUpdatedTaggingGC({
    ...updatedTaggingGC,
    actions: updatedActions,
  });
};

export const handleReplyMessageChange = (updatedTaggingGC: TaggingGC, setUpdatedTaggingGC: Dispatch<SetStateAction<TaggingGC>>, newValue: string, actionId: string) => {
  const { actions } = updatedTaggingGC;

  const newActions = actions?.map((act: ResponseActionType) => {
    if (act.id === actionId) {
      return {
        ...act,
        replyMessage: newValue,
      };
    }
    return act;
  });

  setUpdatedTaggingGC({
    ...updatedTaggingGC,
    actions: newActions,
  });
};

export const getActionsMentionsIntersectionAsPerCreateFor = (
  createFor: { [key: string]: boolean } | undefined,
  actionPlatformConfig: PlatformActionConfigPayload,
  platform: string,
) => {
  let formattedActions: any[] = actionPlatformConfig?.[platform as keyof typeof actionPlatformConfig]?.comment?.actions || [];
  if (actionPlatformConfig?.[platform as keyof typeof actionPlatformConfig]) {
    if (createFor?.comments && (createFor?.mentions || createFor?.postMentions)) {
      if (actionPlatformConfig[platform as keyof typeof actionPlatformConfig].comment?.actions) {
        formattedActions = actionPlatformConfig[platform as keyof typeof actionPlatformConfig].comment?.actions || [];
      }
      if (actionPlatformConfig[platform as keyof typeof actionPlatformConfig].mention?.actions) {
        formattedActions = formattedActions.concat(actionPlatformConfig[platform as keyof typeof actionPlatformConfig].mention?.actions);
        return formattedActions.filter((currentAction: string, currentIndex: number) => formattedActions.indexOf(currentAction) !== currentIndex);
      }
    } else if (createFor?.comments) {
      if (actionPlatformConfig[platform as keyof typeof actionPlatformConfig].comment?.actions) {
        formattedActions = actionPlatformConfig[platform as keyof typeof actionPlatformConfig].comment?.actions || [];
      }
    } else {
      if (actionPlatformConfig[platform as keyof typeof actionPlatformConfig].mention?.actions) {
        formattedActions = actionPlatformConfig[platform as keyof typeof actionPlatformConfig].mention?.actions || [];
      }
    }
  }
  return formattedActions;
};

export const getGeoTargetingVariable = (variables: VariablesTypeUI) => {
  return Object.values(variables).find((value: VariableType) => value.macro === geoVariable.macro);
};

export const getNameTargetingVariable = (variables: VariablesTypeUI, name: string, type: string) => {
  return Object.values(variables).find((value: VariableType) => value.name === name && value.type === type);
};

export const getLabelsFromRegex = (regexString: string = ''): string[] => {
  if (regexString.length) {
    const pipeReplaced = regexString.replace(/\\\|/g, '__PIPE__');
    const minString = pipeReplaced.split(')|(')[0].replace('^((', '').replace(/\\(.)/g, '$1');
    const labels = minString.split('|');
    return labels.map((label: string) => label.replace(/__PIPE__/g, '|'));
  }
  return [];
};

export const checkBroadcastSelectedTargetConditions = (str: string = '') => {
  return str && str.includes('{{messageHistory.broadcastHistory.');
};

export const shortenToAcronym = (str: string = '') => {
  const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  const acronym = (matches || ['']).join('');
  return acronym;
};

//if entity = array add platformName in option-label. else option = {label = entityName , value= entityId}
export const getOptionsWithIdName = (
  entities: {
    name: string;
    id: string;
  }[],
  addPlatformInfo: boolean,
) => {
  let entityOptions: any = [];
  if (entities && Array.isArray(entities)) {
    entityOptions =
      entities &&
      entities.map((l: any) => {
        const platformBotsLength = (l.allPlatformBots || []).length;
        let platformName = platformBotsLength && addPlatformInfo ? (platformBotsLength > 1 ? 'Multiple' : botPlatformsTooltip[l.allPlatformBots[0].platform]) : '';
        platformName = (platformName || '').split(' ').length > 1 ? shortenToAcronym(platformName) : platformName;

        return {
          value: l.id,
          label: `${l.name}${platformName ? ` (${platformName})` : ''}`,
        };
      });
  } else if (entities) {
    entityOptions =
      Object.keys(entities) &&
      Object.keys(entities)
        .map((entityId: string) => entities[entityId])
        .map((entity: any) => {
          return {
            value: entity.id,
            label: entity.name,
          };
        });
  }
  return entityOptions;
};

//sort options alphabetically
function sortByName(a: { label: string; value: string }, b: { label: string; value: string }) {
  const bname1 = a && a.label ? a.label.toLowerCase() : '';
  const bname2 = b && b.label ? b.label.toLowerCase() : '';
  return bname1 > bname2 ? 1 : bname2 > bname1 ? -1 : 0;
}

//option name and value as per entityKeys like this= ['name' , 'id'] picked out from entity object
export const getOptionsWithName = (
  entities: {
    name: string;
    id: string;
  }[],
  entityKeys: string[],
) => {
  let entityOptions = [];
  entityOptions =
    entities &&
    entities.map((entityName: any) => {
      return {
        value: entityKeys && entityKeys.length ? (entityName[entityKeys[0]] !== undefined ? entityName[entityKeys[0]] : '') : entityName,
        label: entityKeys && entityKeys.length ? (entityName[entityKeys[1]] !== undefined ? entityName[entityKeys[1]] : '') : entityName,
      };
    });
  return entityOptions;
};

const addSelectedOption = (
  entityList: {
    label: string;
    value: string;
  }[],
  entityOption: string,
) => {
  if (!entityOption[0] || entityOption[0].toLowerCase() === 'all') {
    return entityList;
  }

  if (entityList?.filter((el: any) => el.value === entityOption[0]).length === 0) {
    entityList.push({ value: entityOption[0], label: entityOption[1] ? entityOption[1] : entityOption[0] });
  }
};

export const getOptions = (
  options: {
    name: string;
    id: string;
  }[],
  getById: boolean,
  defaultValue: string | null,
  optionKeys: string[],
  selectedOption: string | null,
  doNotAddDefaultValue: boolean,
  addPlatformInfo: boolean = false,
) => {
  let entityOptions = getById ? getOptionsWithIdName(options, addPlatformInfo) : getOptionsWithName(options, optionKeys);
  entityOptions = entityOptions.sort(sortByName);
  if (!doNotAddDefaultValue) {
    defaultValue ? entityOptions.unshift(defaultValue) : entityOptions.unshift({ value: 'all', label: 'All' });
  }
  selectedOption && selectedOption.length && addSelectedOption(entityOptions, selectedOption);
  return entityOptions;
};

//use archive & repeat broadcast to create options for segment broadcast recv not-recv conditions
export const getBroadcastOptionsForTargeting = (broadcasts: BroadcastsUI, timeZone: string) => {
  const { archive = [], repeat = [] } = getSegregatedBroadcasts(broadcasts, timeZone || '');
  const broadcastsData: any = [...archive, ...repeat];

  if (!broadcastsData?.length) {
    return [];
  }

  return (
    broadcastsData &&
    broadcastsData.length > 0 &&
    getOptions(
      broadcastsData.filter((broadcast: any) => broadcast),
      false,
      null,
      ['id', 'broadcastName'],
      null,
      true,
    )
  );
};
