/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { Bot, BroadcastsUI, Metrics, Modules, SegmentTagUI, VariableType } from '../../common/entities/entities.types';
import { amplifyShortDomain, shortUrlKeyLength } from '../../config';
import {
  advancedTwigTemplateRegex,
  checkObjNotation,
  combinedRegexForVars,
  combinedTwigPattern,
  defaultTwigTemplate,
  punctuationsNotIncludedInURL,
  /*urlRegexForClickTracking,*/ urlRegexForClickTrackingNew,
  variableRegex,
} from '../../utils/regex';
import { BroadcastsResponse, BroadcastTypeAPI, BroadcastTypeAPIResponses, BroadcastTypeUI, Option, SelectedSegments } from './types';

import { getTwilioSmartEncodedString } from '../../utils/segment-counter/twilio-smart-encoder';
import { segmentTagColor, SEGMENT_COUNT_THRESHOLD, shortURLClickTrackers, TIME_IN_MINUTES_FOR_BROADCASTS_POLLING } from './config';
import {
  actBlueBaseUrl,
  actBlueURLRegex,
  actBlueHost,
  amplifyDomain,
  amplifyFeatureDomain,
  broadcastResponseTypes,
  broadcastScheduleConst,
  cliqzDomain,
  fanCountConstant,
  mergeTagConstant,
  TEMPLATE_BROADCAST_INITIALS_REGEX,
  timezoneTypes,
  trillerDomain,
  usNumbersCondition,
  waTemplateErrors,
  waTemplateStatus,
  actBlueRefCodABIDParam,
  broadcastVariableMacro,
  mentionLinkConstant,
  donationIndexStartDate,
  donationUiVariables,
  broadcastVariableMacroSquareBracket,
  broadcastTypes,
  broadcastTypeLabels,
  subscribedIfStmtCondition,
  segmentTypeConst,
  segmentTagTypeConst,
  actBlueUrlrefcodeParam,
} from './constants';
import { Segment, Segments, SegmentTags } from '../segments/segment.types';
import { ALLCONSTANT } from '../automation/constants';
import { defaultValuesInAdvancedOptions, transformMessageForSegmentCount } from './transformer';
import { AddCommaSeparator, humanizeNumber } from '../../common/utils/humanize';
import { SegmentGroup, SegmentCondition } from '../../common/components/tagging-gc-content/tagging-gc-content.constants';
import _, { cloneDeep, sortBy } from 'lodash';
import { getTimeZone } from '../../common/utils/time-zones';
import { convertFromTwigSyntaxToDisplayText, removeIdsFromConditions, removeRegexMatchAndGiveList, updateMatcherStringsWithList } from '../../utils';
import { GCChildCondition, GCConditions } from '../../types/api-calls';
import { GroupConditionType, TagSelectOptions } from '../automation/types';
import { InstaFBPlatforms } from '../../config/platform-action-map';
import { unparse } from 'papaparse';
import { html2unicode } from '../../common/utils/unicodeTransforms';
import { getSegmentsCount } from '../../utils/segment-counter';
import { variableTypeColorMapForMentions } from '../../common/components/custom-mention-editor/utils';
import { isValidUrl } from '../../common/utils';
import { extractDateFormattedValues, getDateFormatterDisplayText } from '../../utils/twig-utils';
import { MergeTagMode } from './components/manage-broadcast/components/merge-tags-modal';

export function isRepeatBroadcast(broadcast: BroadcastTypeUI | BroadcastTypeAPI) {
  if (!(broadcast && broadcast.repeat)) {
    return false;
  }
  return broadcast.repeat === 'None' ? false : true;
}

export function isTemplateBroadcast(broadcast: BroadcastTypeUI) {
  return broadcast && broadcast.broadcastName.match(TEMPLATE_BROADCAST_INITIALS_REGEX);
}

export function isAutomaticBroadcast(broadcast: BroadcastTypeAPI) {
  if (!(broadcast && broadcast.type)) {
    return false;
  }
  return broadcast.type.toLowerCase() === 'automatic';
}

export function getTypeForBroadcast(jobType: string) {
  return broadcastTypeLabels[jobType] || '';
}

export function getBroadcastTypeForAPI(jobType: string) {
  return Object.keys(broadcastTypeLabels).find((key: string) => broadcastTypeLabels[key] === jobType) || '';
}

export const getPercentage = (total: number, available: number, upto?: number) => {
  const res = total ? (available / total) * 100 : 0;
  return res > 100 ? 100 : res.toFixed(upto || upto === 0 ? upto : 2);
};

export const isDraftBroadcast = (broadcast: BroadcastTypeUI | null) => {
  return broadcast?.draft || broadcast?.paused;
};

export const isScheduledBroadcast = (broadcast: BroadcastTypeUI, timezone: string) => {
  return broadcast?.scheduleType === broadcastScheduleConst.LATER.value && !checkIfScheduledForPast(broadcast.startDate, timezone, broadcast.timeZone) && !broadcast.paused;
};

export const getSegregatedBroadcasts = (broadcasts: BroadcastsUI, timezone: string, sortBroadcastsAsPerScheduledDate: boolean = false) => {
  const drafts: BroadcastTypeUI[] = [];
  const archive: BroadcastTypeUI[] = [];
  const recent: BroadcastTypeUI[] = [];
  const scheduled: BroadcastTypeUI[] = [];
  const repeat: BroadcastTypeUI[] = [];
  const templates: BroadcastTypeUI[] = [];

  const currentTime = moment();
  timezone = getTimeZone(timezone);
  Object.values(broadcasts).forEach((element: BroadcastTypeUI) => {
    const scheduledBotTime = moment.utc(element.date + ' ' + element.time, 'YYYY-MM-DD HH:mm').tz(timezone);
    // console.log(scheduledBotTime, 'scheduledBotTime---');

    // const scheduledUtcTime = moment.utc(element.date + ' ' + element.time, 'YYYY-MM-DD HH:mm');
    // const broadcastDate = element.timeZone === 'bot' ? scheduledBotTime : scheduledUtcTime

    //datetime
    let endTime;

    // scheduledTime - Currently what we show in bot timezone
    // startTime - When we would expect the broadcast to start. For bot time this is the same as scheduled time.
    //             For user time this is - 14 hours from scheduled time.
    // endTime - When we would expect the broadcast to end. For repeat jobs this is null (infinity).
    //           For non-repeat bot time this is the same as scheduled time. For non-repeat user time this is + 12 hours from scheduled time.
    if (element.timeZone === 'bot') {
      // startTime = scheduledBotTime;
      endTime = isRepeatBroadcast(element) ? null : scheduledBotTime;
    } else {
      // user time zone
      // startTime = moment.tz(element.date + ' ' + element.time, 'YYYY-MM-DD HH:mm', 'Etc/GMT-14')
      endTime = isRepeatBroadcast(element) ? null : moment.tz(`${element.date} ${element.time}`, 'YYYY-MM-DD HH:mm', 'Etc/GMT+12');
    }

    if (element.isArchived) {
      archive.push(element);
    }
    if (isDraftBroadcast(element)) {
      if (isTemplateBroadcast(element)) {
        templates.push(element);
      } else {
        drafts.push(element);
      }
    } else if (isRepeatBroadcast(element)) {
      repeat.push(element);
    } else if (isScheduledBroadcast(element, timezone)) {
      scheduled.push(element);
    } else if (endTime && endTime?.add(30, 'days') >= currentTime) {
      recent.push(element);
    } else {
      archive.push(element);
    }
  });
  if (sortBroadcastsAsPerScheduledDate) {
    const sortedRecent: any[] = [...recent]?.sort(toSortBroadcastsAsPerScheduledDate);
    const sortedArchived: any[] = [...archive]?.sort(toSortBroadcastsAsPerScheduledDate);
    const sortedScheduled: any[] = [...scheduled]?.sort(toSortBroadcastsAsPerScheduledDate);
    const sortedTemplates: any[] = [...templates]?.sort(toSortBroadcastsAsPerScheduledDate);
    const sortedRepeat: any[] = [...repeat]?.sort(toSortBroadcastsAsPerScheduledDate);
    const sortedDrafts: any[] = [...drafts]?.sort(toSortBroadcastsAsPerScheduledDate);

    return { drafts: sortedDrafts, recent: sortedRecent, archive: sortedArchived, scheduled: sortedScheduled, repeat: sortedRepeat, templates: sortedTemplates };
  }
  return { drafts, recent, archive, scheduled, repeat, templates };
};

export const isBroadcastSentWithin5Min = (broadcasts: BroadcastTypeUI[]) => {
  const filteredBroadcast: any = [];
  broadcasts.forEach((broadcast: BroadcastTypeUI) => {
    const calculatedDateString = `${broadcast.date}T${broadcast.time}`;
    const addMergedDT = { ...broadcast, mergedDateAndTime: calculatedDateString };
    filteredBroadcast.push(addMergedDT);
  });
  return sortBy(filteredBroadcast, 'mergedDateAndTime').reverse()[0];
};

export const formatAnalytics = (analytics: BroadcastTypeUI['analytics']) => {
  let messageEngaged = 0;
  let messageSent = 0;
  let totalBroadcastSent = 0;
  let allClicks = 0;
  let activeSessions = 0;
  let engagedClicks = 0;
  if (analytics) {
    analytics.forEach(({ metric, number }: any) => {
      switch (metric) {
        case 'sent':
          messageSent = number;
          break;
        case 'broadcasts':
          totalBroadcastSent = number;
          break;
        case 'engaged':
          messageEngaged = number;
          engagedClicks = number;
          break;
        case 'delivered':
          activeSessions = number;
          break;
        case 'clicks':
          allClicks = number;
          break;
        default:
          break;
      }
    });
  }
  return { messageEngaged, messageSent, allClicks, activeSessions, engagedClicks, totalBroadcastSent };
};
export const isUrlPresentInBroadcastMessage = (broadcast: BroadcastTypeUI) => {
  return broadcast.actions?.some((action: BroadcastTypeUI['actions']) => {
    return action?.components?.some((component: any) => {
      if (component?.type === 'module') {
        return true;
      }
      const urls = detectURLandFindLength(component.text);
      return urls?.totalLengthOfUrls;
    });
  });
};

export const getShortUrl = (customDomain: any) => {
  return `${customDomain || amplifyShortDomain}/${'#'.repeat(shortUrlKeyLength)}`;
};

export const detectURLandFindLength = (message: string = '', customDomain?: string, appendSpaceBeforeUrl?: boolean) => {
  let text = message;
  // const urlRegexForClickTracking=forUrlTracking?urlRegexForUrlTracking: urlRegexForClickTracking;
  const sortUrlStub = getShortUrl(customDomain);
  let numberOfUrlsEndingWithPunctuationOrStartWithNonSpace = 0;

  if (!text) {
    return { numberOfUrls: 0, totalLengthOfUrls: 0, messageWithShortUrl: '', numberOfUrlsEndingWithPunctuationOrStartWithNonSpace: 0 };
  }
  const indicesToPutSpacesAfter: number[] = [];
  const allUrls = Array.from(text.matchAll(new RegExp(actBlueURLRegex.source + '|' + urlRegexForClickTrackingNew.source, 'g')), (m: RegExpMatchArray) => {
    const inputString = m['input'];
    const matchIndex = m['index'];
    if (inputString && matchIndex) {
      const probablePunctuationPosition = matchIndex + m[0].length;
      if (inputString.charAt(probablePunctuationPosition) && punctuationsNotIncludedInURL.includes(inputString.charAt(probablePunctuationPosition))) {
        numberOfUrlsEndingWithPunctuationOrStartWithNonSpace++;
      }
      if (inputString.charAt(matchIndex - 1) && !/\s/.test(inputString.charAt(matchIndex - 1))) {
        // we are making sure urls are surrounded by white space
        if (appendSpaceBeforeUrl) {
          indicesToPutSpacesAfter.push(matchIndex);
        } else {
          numberOfUrlsEndingWithPunctuationOrStartWithNonSpace++;
        }
      }
    }
    return m[0];
  });
  text = insertACharacterAtMultipleIndices(text, indicesToPutSpacesAfter, ' ');
  const totalLengthOfUrls = allUrls.reduce((acc: number, cur: string) => acc + cur.length, 0);
  const messageWithShortUrl = text.replace(new RegExp(actBlueURLRegex.source + '|' + urlRegexForClickTrackingNew.source, 'g'), sortUrlStub);

  return { numberOfUrls: allUrls.length, totalLengthOfUrls, messageWithShortUrl, numberOfUrlsEndingWithPunctuationOrStartWithNonSpace, allUrls };
};
const validUelInitails = ['http://', 'https://']; //'www.',

const exactMatchException = ['YYYY.MM.DD', 'YYYY.MM.DD'];

const exactMatchExceptionAsRegexSource = exactMatchException.join('|');
export const detectInvalidURL = (text: string = '') => {
  const newText = text.replace(/(<a[\s]+([^>]+)>)|(<\/a>)/g, ''); //replacing anchor tags
  // eslint-disable-next-line no-useless-escape
  const regexForURL = /([-a-zA-Z0-9@:%_\+~#?&//=]{2,256}[.])+[-a-zA-Z0-9@:%_\+~#?&//=]{2,256}/;
  const regex = new RegExp(combinedRegexForVars.source + '|' + regexForURL.source + '|' + exactMatchExceptionAsRegexSource, 'igm');
  let isMatch = Array.from(newText.matchAll(regex));
  const regexInExeption = new RegExp(combinedRegexForVars.source + '|' + exactMatchExceptionAsRegexSource, 'i');
  isMatch = isMatch.filter((url: RegExpMatchArray) => {
    return !validUelInitails.filter((initials: string) => url[0].indexOf(initials) === 0 || regexInExeption.test(url[0])).length;
  });
  const inValidURLs = isMatch.map((url: RegExpMatchArray) => url[0]);
  return { havingInValidURLs: inValidURLs.length ? true : false, inValidURLs };
};

export const detectInvalidActBlueURL = (text: string = '') => {
  const newText = text.replace(/(<a[\s]+([^>]+)>)|(<\/a>)|(<span[\s]+([^>]+)>)|(<\/span>)/g, ''); //replacing anchor and span tags
  // eslint-disable-next-line no-useless-escape
  const actBlueURLs = Array.from(newText.matchAll(actBlueURLRegex));
  let inValidActBlueURLs: any[] = actBlueURLs.filter((url: RegExpMatchArray) => {
    try {
      const urlObj = new URL(url[0]);
      return !urlObj.searchParams.get(actBlueUrlrefcodeParam);
    } catch {
      //eslint-disable-next-line no-console
      console.log('URL Parsing error at detectInvalidActBlueURL');
    }
    return false;
  });
  inValidActBlueURLs = inValidActBlueURLs.map((url: RegExpMatchArray) => url[0]);
  return { havingInValidActBlueURLs: inValidActBlueURLs.length ? true : false, inValidActBlueURLs };
};

export function addShortURLTrackingInTextandRemoveSpecialChars(message: string, enableClickTracking: boolean) {
  // const regEX = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
  const shortURLStartMacro = shortURLClickTrackers.start;
  const shortURLEndMacro = shortURLClickTrackers.end;
  let label = message;
  if (label) {
    const { encodedString } = getTwilioSmartEncodedString(label);
    label = encodedString;
    const indicesToPutSpacesAfter: number[] = [];
    Array.from(label.matchAll(new RegExp(actBlueURLRegex.source + '|' + urlRegexForClickTrackingNew.source, 'g')), (m: RegExpMatchArray) => {
      const inputString = m['input'];
      const matchIndex = m['index'];
      if (inputString && matchIndex) {
        const probablePunctuationPosition = matchIndex + m[0].length;
        if (inputString.charAt(matchIndex - 1) && !/\s/.test(inputString.charAt(matchIndex - 1))) {
          // we are making sure urls are surrounded by white space
          indicesToPutSpacesAfter.push(matchIndex);
          // label = inputString.slice(0, matchIndex) + ' ' + inputString.slice(matchIndex);
        }
        if (inputString.charAt(probablePunctuationPosition) && punctuationsNotIncludedInURL.includes(inputString.charAt(probablePunctuationPosition))) {
          //if url has ended with punctuation we need to put an space after the url, to show it correctly in few devices
          indicesToPutSpacesAfter.push(probablePunctuationPosition);
          // label = inputString.slice(0, probablePunctuationPosition) + ' ' + inputString.slice(probablePunctuationPosition);
        }
      }
      return m[0];
    });
    label = insertACharacterAtMultipleIndices(label, indicesToPutSpacesAfter, ' ');
    if (enableClickTracking) {
      // const matches = regEX.exec(label);
      // allUrls &&
      //   allUrls.forEach((match: string) => {
      //     const trakedURL = `${shortURLStartMacro}${match}${shortURLEndMacro}`;
      //     if (!label.includes(trakedURL)) {
      //       label = label.replace(match, trakedURL);
      //     } else {
      //       const firstOccurecnce = trakedURL;
      //       const firstOccurecnceIndex = label.indexOf(trakedURL);
      //       const labelWithoutFirstOccurecnce = label.replace(trakedURL, '');
      //       label = labelWithoutFirstOccurecnce.replace(match, trakedURL);
      //       label = label.slice(0, firstOccurecnceIndex) + firstOccurecnce + label.slice(firstOccurecnceIndex);
      //     }
      //     console.log(label);

      //   });
      const addRefCodeABIDAndMacroInActBlueURL = (url: string) => {
        try {
          const actBlueURL = new URL(url);
          if (!actBlueURL.searchParams.has(actBlueRefCodABIDParam) || actBlueURL.searchParams.get(actBlueRefCodABIDParam) !== broadcastVariableMacro) {
            const href = url + `${(actBlueURL.searchParams as any).size ? '&' : ''}${actBlueRefCodABIDParam}=${broadcastVariableMacro}`;
            return `${shortURLStartMacro}${href}${shortURLEndMacro}`;
          }
        } catch {
          //eslint-disable-next-line no-console
          console.log('Error Parsing url:', url);
        }
        return `${shortURLStartMacro}${url}${shortURLEndMacro}`;
      };
      label = label.replaceAll(urlRegexForClickTrackingNew, (match: string) => {
        const url = match;
        const urlObj = new URL(url);
        if (new RegExp(actBlueURLRegex.source, 'g').test(url)) {
          return url;
        } else if (urlObj.hostname === actBlueHost) {
          //if actBlue regex check fails for actBlue urls
          const actBlueURLWithRefCodeABIDAndMacro = addRefCodeABIDAndMacroInActBlueURL(url);
          return actBlueURLWithRefCodeABIDAndMacro;
        }
        return `${shortURLStartMacro}${url}${shortURLEndMacro}`;
      });
      label = label.replaceAll(actBlueURLRegex, (match: string) => {
        const url = match;
        //if ActBlue url found, add refcodeABID param to track donations in BE
        try {
          const actBlueURLWithRefCodeABIDAndMacro = addRefCodeABIDAndMacroInActBlueURL(url);
          return actBlueURLWithRefCodeABIDAndMacro;
        } catch {
          //eslint-disable-next-line no-console
          console.log('Error Parsing url:', url);
        }
        return `${shortURLStartMacro}${url}${shortURLEndMacro}`;
      });
    }
  }
  return label;
}

export const insertACharacterAtMultipleIndices = (stringToModify: string, indices: number[] = [], char: string) => {
  if (indices.length) {
    const stringAsArray = stringToModify.split('');
    const sortedIndices = indices.sort(function (a: number, b: number) {
      return b - a;
    });
    sortedIndices.forEach((index: number) => {
      stringAsArray.splice(index, 0, char);
    });
    return stringAsArray.join('');
  }
  return stringToModify;
};
export const removeRefCodeABIDFromActBlueURLPresentInText = (text: string) => {
  let updatedText = text;
  try {
    const allUrls = updatedText.match(urlRegexForClickTrackingNew);
    if (allUrls && allUrls.length) {
      allUrls.forEach((urlMatch: string) => {
        const url = new URL(urlMatch);
        if (!actBlueURLRegex.test(urlMatch) && url.host === actBlueHost) {
          //if actBlue regex check fails for actBlue urls
          const actBlueUrl = urlMatch.replaceAll(
            /*eslint-disable-next-line no-useless-escape*/
            new RegExp(`(&)?${actBlueRefCodABIDParam}=(${broadcastVariableMacro}|${broadcastVariableMacroSquareBracket.replace(/[\[\]\\]/g, '\\$&')})?`, 'g'),
            '',
          );
          updatedText = updatedText.replace(urlMatch, actBlueUrl);
        }
      });
    }
    const actBlueUrls = updatedText.match(actBlueURLRegex);
    if (actBlueUrls && actBlueUrls.length) {
      actBlueUrls.forEach((urlMatch: string) => {
        const url = new URL(urlMatch);
        if (url.searchParams.has(actBlueRefCodABIDParam)) {
          const actBlueUrl = urlMatch.replaceAll(
            /*eslint-disable-next-line no-useless-escape*/
            new RegExp(`(&)?${actBlueRefCodABIDParam}=(${broadcastVariableMacro}|${broadcastVariableMacroSquareBracket.replace(/[\[\]\\]/g, '\\$&')})?`, 'g'),
            '',
          );
          updatedText = updatedText.replace(urlMatch, actBlueUrl);
        }
      });
    }
  } catch {
    //eslint-disable-next-line no-console
    console.log('Error: parsing the text for appending RefCodeABID InActBlueURL.');
  }
  return updatedText;
};
export function removeShortURLTrackingFromText(message: string) {
  const shortURLStartMacro = shortURLClickTrackers.start;
  const shortURLEndMacro = shortURLClickTrackers.end;
  if (message) {
    //Url tracking constant added & removed twice to add for text immediately after actBlue.
    message = message.replaceAll(shortURLStartMacro, `${shortURLStartMacro} `).replaceAll(shortURLEndMacro, ` ${shortURLEndMacro}`);
    message = removeRefCodeABIDFromActBlueURLPresentInText(message);
    message = message.replaceAll(`${shortURLStartMacro} `, '').replaceAll(` ${shortURLEndMacro}`, '');
  }
  return message;
}

export function removeURLTracking(responses: any[]) {
  const newRes = responses.map((response: any) => {
    if (response?.components) {
      response.components = response.components.map((component: any) => {
        if (component?.type === 'text') {
          response.enableClickTracking = component.text.includes(shortURLClickTrackers.start) && component.text.includes(shortURLClickTrackers.end);
          let text = removeShortURLTrackingFromText(component.text);
          if (component.templateEngine === 'twig') {
            text = twigVarSyntaxToAmplifySyntax(text);
          }
          return {
            ...component,
            text,
          };
        }
        return component;
      });
    }
    return response;
  });
  return newRes;
}

export const getSegmentsAsOptions = (segments: Segments, doNotAddALLOption?: boolean) => {
  let allUsers = 0;
  const sortedSegments =
    Object.values(segments)?.sort(function (a: any, b: any) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }) || [];

  const segmentAsOptions = sortedSegments.map((value: Segment) => {
    const isNotTooOld = moment().subtract(36, 'hours').diff(moment(value.userCountUpdated)) <= 0;
    if (isNotTooOld && value.totalContacts) {
      allUsers += value.totalContacts || 0;
    }

    return {
      value: value.id,
      label: value.name,
      type: segmentTypeConst,
      color: segmentTagColor[segmentTypeConst],
    };
  });
  if (!doNotAddALLOption) {
    segmentAsOptions.unshift({ value: ALLCONSTANT, label: 'All', type: segmentTypeConst } as any);
  }
  return { segmentAsOptions, allUsers };
};

export const segmentTagIdNameDelimiter = '___';

export const getTagIdGroupNameAsKey = (segmentId: string, name: string) => {
  return `${segmentId}${segmentTagIdNameDelimiter}${name}`;
};

export const getSegmentIdTagIdForAPI = (tagKey: string) => {
  const [tagId, tagGroupName] = tagKey?.split(segmentTagIdNameDelimiter) || [tagKey];
  if (tagGroupName) {
    return { tagId, tagGroupName };
  }
  return { segmentId: tagId };
};

export const getSegmentTagsAsOptions = (segmentTags: SegmentTags) => {
  const segmentTagsAsOptions: any = [];
  const sortedTags =
    Object.values(segmentTags)?.sort(function (a: any, b: any) {
      if (a.prefix < b.prefix) return -1;
      if (a.prefix > b.prefix) return 1;
      return 0;
    }) || [];
  sortedTags?.forEach((segmentTag: SegmentTagUI) => {
    segmentTag.groups.forEach((group: SegmentTagUI['groups'][0]) => {
      segmentTagsAsOptions.push({
        value: getTagIdGroupNameAsKey(segmentTag.id, group.name),
        label: `${segmentTag.prefix} Cohort ${group.name}`,
        type: segmentTagTypeConst,
        color: segmentTagColor[segmentTagTypeConst],
      });
    });
  });
  return { segmentTagsAsOptions };
};

export const humanizeUserCount = (userCount: number, doNotIncludeUsersText: boolean = false) => {
  if (typeof userCount === 'number') {
    if (userCount < 2) {
      return `${userCount} ${doNotIncludeUsersText ? '' : fanCountConstant.fan}`;
    } else if (userCount <= 9999) {
      return `${AddCommaSeparator(userCount)} ${doNotIncludeUsersText ? '' : fanCountConstant.fans}`;
    } else if (userCount <= 99999) {
      return `~${humanizeNumber(userCount, 1)} ${doNotIncludeUsersText ? '' : fanCountConstant.fans}`;
    } else if (userCount <= 999999) {
      return `~${humanizeNumber(userCount, 0)} ${doNotIncludeUsersText ? '' : fanCountConstant.fans}`;
    }
    return `~${humanizeNumber(userCount, 1)} ${doNotIncludeUsersText ? '' : fanCountConstant.fans}`;
  }
  return 'NA';
};

export const getFirstSegmentIdFromConditions = (condition?: GCConditions) => {
  let selectedSegment = ALLCONSTANT;
  if (!condition) {
    return selectedSegment;
  }

  condition.childConditions?.find((childCondition: any) => {
    if (childCondition.segmentId) {
      selectedSegment = childCondition.segmentId;
      return childCondition.segmentId;
    }
    return false;
  });

  return selectedSegment;
};

export const hasAdvancedBroadcastOptions = (broadcastSate: any) => {
  return Object.keys(defaultValuesInAdvancedOptions as any).some((key: any) => {
    return (defaultValuesInAdvancedOptions as any)[key] !== (broadcastSate as any)[key];
  });
};

const componentTypes = ['text', 'image'];
const componentTypesAllowedWithLocalVar = ['text', 'image', 'platform_template', 'storeUserVariable'];
const logicalOperator = {
  AND: 'and',
  OR: 'or',
};
export const broadcastContainsLocalVars = (broadcastResponses?: any) => {
  if (
    broadcastResponses?.length > 1 ||
    ![broadcastResponseTypes.MMS, broadcastResponseTypes.SMS, broadcastResponseTypes.TEMPLATES, broadcastResponseTypes.TEXT].includes(broadcastResponses?.[0]?.type)
  ) {
    return { localVarComponentsList: [], containsLocalVars: false };
  }
  let containsLocalVarComponent = false;
  const localVarComponentsList: any = [];

  const hasAllAllowedComponents = broadcastResponses[0]?.components?.every((component: any) => {
    if (!containsLocalVarComponent && component.type === 'storeUserVariable') {
      containsLocalVarComponent = true;
      localVarComponentsList.push(component);
    }
    return componentTypesAllowedWithLocalVar.includes(component.type);
  });

  return { containsLocalVars: hasAllAllowedComponents && containsLocalVarComponent, localVarComponentsList };
};

export const checkComplexTargetingAndResponse = (broadcastFromAPI: BroadcastTypeAPI, checkForDefaultUsTargeting?: boolean) => {
  let isComplexTargeting = true;
  let isComplexResponse = false;
  // let isComplexBroadcastWarning;
  let preventMessageEditWithWarning;
  //  let allowBroadcastUpdate = true;
  let isOldStructureSupportedByACM = false;

  //complex targeting is marked false if we are able to display segment conditions in its advanced component now in use in cm referenced from pro.
  let hasAdvancedSegments = false;
  //we add hasDefaultUsTargetingCondition:  default US tageting for sms broadcasts created via cm if !globalTargeting

  //removing the usNumberCondition to check for further complexity
  const broadcast = {
    ...broadcastFromAPI,
    conditions: {
      ...broadcastFromAPI.conditions,
      childConditions: broadcastFromAPI.conditions?.childConditions?.filter((childCondition: GCChildCondition) => {
        if (
          broadcastFromAPI?.conditions?.logicalOperator === 'and' &&
          childCondition.ifStatements &&
          childCondition.ifStatements?.length === 1 &&
          childCondition.ifStatements?.[0]?.variable?.macro === usNumbersCondition.ifStatements[0].variable.macro &&
          childCondition.logicalOperator === usNumbersCondition.logicalOperator &&
          childCondition.ifStatements[0]?.condition === usNumbersCondition.ifStatements[0].condition &&
          childCondition.ifStatements[0]?.value === usNumbersCondition.ifStatements[0].value
        ) {
          return false;
        }
        return true;
      }),
    },
  };

  const { hasAdvancedSegments: containsAdvancedSegments, hasUsTargetingCondition: containsUsTargetingConditionOnly } = isAdvancedSegmentConditions(
    broadcastFromAPI,
    checkForDefaultUsTargeting,
  );
  const { containsLocalVars } = broadcastContainsLocalVars(broadcast?.responses);

  // If conditions property will not be in the object or empty childConditions
  if (!broadcast?.conditions || !broadcast?.conditions?.childConditions || !broadcast?.conditions?.childConditions?.length) {
    isComplexTargeting = false;
  } else if (isNewBroadcastConditions(broadcast)) {
    isComplexTargeting = false;
  } else if (isOldBroadcastConditions(broadcast)) {
    isComplexTargeting = false;
    isOldStructureSupportedByACM = true;
  } else if (containsAdvancedSegments) {
    hasAdvancedSegments = true;
    isComplexTargeting = false;
  } else if (containsLocalVars) {
    isComplexTargeting = false;
  } else {
    isComplexTargeting = true;
    // isComplexBroadcastWarning = 'Note: This broadcast has complex targeting conditions or responses, and may NOT visible here.';
  }
  // Response Targetting
  if (broadcast?.responses?.length! > 1) {
    // isComplexTargeting = true;
    preventMessageEditWithWarning = 'Note: This broadcast contains complex or multiple responses, hence its message can’t be modified.';
    isComplexResponse = true;
    // isComplexBroadcastWarning = 'Note: This broadcast has complex as it contains multiple responses, and may NOT visible here.';
  } else if (
    // Checking notification-broadcast with single responses with module or text type
    !isComplexTargeting &&
    broadcast?.jobType === broadcastTypes.NOTIFICATION &&
    broadcast?.responses?.length === 1 &&
    [broadcastResponseTypes.MODULE, broadcastResponseTypes.TEXT].includes(broadcast?.responses[0].type) &&
    (broadcast?.responses[0]?.type === broadcastResponseTypes.MODULE ? broadcast?.responses[0]?.value?.split(',')?.length === 1 : true)
  ) {
    isComplexResponse = false;
  } else if (
    broadcast?.responses?.length === 1 &&
    (![broadcastResponseTypes.MMS, broadcastResponseTypes.SMS, broadcastResponseTypes.TEMPLATES, broadcastResponseTypes.MODULE].includes(broadcast?.responses[0].type) ||
      broadcast?.responses[0]?.components?.some((component: any) => {
        return !componentTypes.includes(component.type) && component.type !== 'platform_template';
      })) &&
    !containsLocalVars
  ) {
    // isComplexBroadcastWarning = 'Note: This broadcast has complex targeting conditions or responses, and may NOT visible here.';
    isComplexResponse = true;
    preventMessageEditWithWarning = 'Note: This broadcast contains complex or multiple responses, hence its message can’t be modified.';
  } else if (containsLocalVars) {
    isComplexResponse = false;
    preventMessageEditWithWarning = 'Note: This broadcast contains complex or multiple responses, hence its message can’t be modified.';
  }
  // isComplexTargeting = isComplexTargeting || isRepeatBroadcast(broadcast);

  return {
    isComplexTargeting,
    isComplexResponse,
    isOldStructureSupportedByACM,
    hasAdvancedSegments,
    hasDefaultUsTargetingCondition: containsUsTargetingConditionOnly,
    ...(containsLocalVars ? { hasLocalVars: containsLocalVars } : {}),
    // ...(containsUserVars ? { hasUserVars: containsUserVars } : {}),
    // ...(isComplexBroadcastWarning ? { isComplexBroadcastWarning } : {}),
    ...(preventMessageEditWithWarning ? { preventMessageEditWithWarning } : {}),
  };
};

const isNewBroadcastConditions = (broadcast: BroadcastTypeAPI): Boolean => {
  let isNonComplexAdvancedCondition: Boolean = false;
  const allConditions: any = broadcast?.conditions;
  // If child conditions length is 1 and logical operator could be 'AND'/'OR' to support PRO segments
  // if (broadcast?.conditions?.childConditions?.length === 1 && broadcast?.conditions?.childConditions && broadcast?.conditions?.childConditions[0].segmentId === '') {
  //   isNonComplexAdvancedCondition = true;
  // }

  if (!allConditions?.childConditions?.length) {
    return true;
  }

  // If child conditions length is equals to 2 and all are segments means new format.
  if (broadcast?.conditions?.childConditions?.length && broadcast?.conditions?.childConditions?.length < 3) {
    //broadcast?.conditions?.logicalOperator === logicalOperator.AND
    const isFirstOuterSegmentHasExclude = allConditions?.childConditions?.[0]?.childConditions?.[0] && !!allConditions?.childConditions?.[0]?.childConditions?.[0]?.notCondition;
    //all groups together contain all only include or all only exclude.  eg: 4 groups with all include conditions only , 3groups with all exclude conditions only
    let allGroupsContainIncludeOrExclude = true;
    let nonSegmentConditionGroupsLength = 0;
    let eachSegmentGroupWithAllIncOrExcl = true;

    // below if no segment group has a combination of include and exclude conditions.
    allConditions?.childConditions?.forEach((childCondition: any) => {
      if (childCondition?.segmentId === undefined) {
        nonSegmentConditionGroupsLength = nonSegmentConditionGroupsLength + 1;
        return;
      }
      // ChildConditions should have OR operator and also inner child condition should have segmentId and all the childConditions segment type should match to 0th segment.
      const isFirstSegmentHasNotCondition = childCondition?.childConditions && childCondition?.childConditions[0]?.notCondition;
      const logicalOperatorForIncludeExcludeSegmentGroup = isFirstSegmentHasNotCondition ? logicalOperator.AND : logicalOperator.OR;
      allGroupsContainIncludeOrExclude && (allGroupsContainIncludeOrExclude = Boolean(isFirstSegmentHasNotCondition) === isFirstOuterSegmentHasExclude); //notCondition=exclude

      if (
        eachSegmentGroupWithAllIncOrExcl &&
        !(
          childCondition?.childConditions?.every(
            (innerChildCondition: any) => (innerChildCondition.segmentId || innerChildCondition.tagId) && innerChildCondition.notCondition === isFirstSegmentHasNotCondition,
          ) && (childCondition?.childConditions?.length > 1 ? childCondition.logicalOperator === logicalOperatorForIncludeExcludeSegmentGroup : true)
        )
      ) {
        eachSegmentGroupWithAllIncOrExcl = false;
      }
    });

    isNonComplexAdvancedCondition =
      (broadcast?.conditions?.childConditions?.length === nonSegmentConditionGroupsLength && broadcast?.conditions?.logicalOperator === 'and') ||
      (eachSegmentGroupWithAllIncOrExcl &&
        isFirstOuterSegmentHasExclude !== undefined &&
        (allConditions?.childConditions?.length === 1 ||
          (allGroupsContainIncludeOrExclude && !isFirstOuterSegmentHasExclude
            ? allConditions.logicalOperator === logicalOperator.OR
            : allConditions.logicalOperator === logicalOperator.AND)));
  }
  return isNonComplexAdvancedCondition;
};

const isAdvancedSegmentConditions = (broadcast: BroadcastTypeAPI, checkForDefaultUsTargeting?: boolean): { hasAdvancedSegments: boolean; hasUsTargetingCondition: boolean } => {
  const allSegmentConditions: any = [];
  const allGeneralConditions: any = []; //other than segment conditions
  broadcast?.conditions?.childConditions?.forEach((childC: GCChildCondition) =>
    childC?.segmentId !== undefined ? allSegmentConditions?.push(childC) : allGeneralConditions.push(childC),
  );

  const hasUsTargetingCondition: any =
    checkForDefaultUsTargeting &&
    allGeneralConditions?.length === 1 &&
    broadcast?.conditions?.logicalOperator === 'and' &&
    _.isEqual(allGeneralConditions?.[0], usNumbersCondition);

  const containsGeneralConditionsWithoutUsTargeting = !!allGeneralConditions?.length && !hasUsTargetingCondition && broadcast?.conditions?.logicalOperator === 'or';
  const hasBothConditions = (broadcast?.conditions?.childConditions?.length || 0) > 2; // && broadcast?.conditions?.logicalOperator === 'or'

  if (!allSegmentConditions?.length && !allGeneralConditions?.length) {
    return { hasAdvancedSegments: false, hasUsTargetingCondition };
  } else if (allSegmentConditions?.length) {
    let hasAllIncludeConditionsOnly = true;
    let hasAdvancedSegments = false;

    if ((broadcast?.conditions?.logicalOperator === logicalOperator.OR && !hasUsTargetingCondition) || allSegmentConditions?.length > 2) {
      hasAdvancedSegments = true;
    }
    allSegmentConditions?.forEach((childCondition: any) => {
      const isFirstSegmentHasNotCondition = childCondition?.childConditions && childCondition?.childConditions[0]?.notCondition;
      const logicalOperatorForIncludeExcludeSegmentGroup = isFirstSegmentHasNotCondition ? logicalOperator.AND : logicalOperator.OR;
      if (childCondition?.childConditions?.length > 1 ? childCondition.logicalOperator !== logicalOperatorForIncludeExcludeSegmentGroup : false) {
        hasAdvancedSegments = true;
      }

      if (childCondition?.childConditions?.length > 1 && childCondition?.logicalOperator !== logicalOperator.OR) {
        hasAllIncludeConditionsOnly = false;
      }

      childCondition?.childConditions?.forEach((innerChildCondition: any) => {
        if (innerChildCondition.notCondition !== isFirstSegmentHasNotCondition) {
          hasAdvancedSegments = true;
        }
        innerChildCondition?.notCondition && (hasAllIncludeConditionsOnly = false);
      });
    });

    if (hasAllIncludeConditionsOnly && allSegmentConditions?.logicalOperator !== logicalOperator.OR) {
      //consider group operator for allInclude
      hasAllIncludeConditionsOnly = false;
      hasAdvancedSegments = true;
    }
    hasAdvancedSegments =
      hasAllIncludeConditionsOnly && allSegmentConditions?.length < 3 && (!hasUsTargetingCondition || !allGeneralConditions?.length)
        ? broadcast?.conditions?.logicalOperator !== logicalOperator.OR
        : hasAdvancedSegments;

    return {
      hasAdvancedSegments: hasAdvancedSegments || containsGeneralConditionsWithoutUsTargeting || hasBothConditions,
      hasUsTargetingCondition,
    };
  }

  return {
    hasAdvancedSegments: containsGeneralConditionsWithoutUsTargeting || hasBothConditions,
    hasUsTargetingCondition,
  };
};

const isOldBroadcastConditions = (broadcast: BroadcastTypeAPI): Boolean => {
  let isOldConditionStructure: Boolean = false;
  if (broadcast?.conditions?.childConditions?.length === 1 && (broadcast?.conditions?.childConditions[0]?.segmentId || broadcast?.conditions?.childConditions[0]?.tagId)) {
    isOldConditionStructure = true;
  }
  return isOldConditionStructure;
};

export const compareStrings = (a: string, b: string) => {
  return a.length < b.length ? { biggerString: b, smallString: a } : { biggerString: a, smallString: b };
};

export const appendText = (text: string, shouldAppend: boolean, textToAppend: string, returnTextToAppendIfEmpty: boolean = false) => {
  return shouldAppend && (text || returnTextToAppendIfEmpty) && textToAppend ? text + '\n\n' + textToAppend : text;
};

export const removeAppendedText = (text: string, shouldRemove: boolean, textToRemove: string, previousTextToRemove: string) => {
  if (shouldRemove && text) {
    const { biggerString, smallString } = compareStrings(textToRemove, previousTextToRemove);
    if (biggerString && text.includes(`\n\n${biggerString}`)) {
      return text.replace(`\n\n${biggerString}`, '');
    } else if (smallString && text.includes(`\n\n${smallString}`)) {
      return text.replace(`\n\n${smallString}`, '');
    }
  }
  return text;
};

export const migrateToNewStructure = (conditions?: GCConditions) => {
  if (conditions) {
    const excluded: any = [];
    const included: any = [];
    const newStruture: any = { childConditions: [], logicalOperator: logicalOperator.AND };
    conditions?.childConditions?.forEach((childCondition: any) => {
      if (childCondition.notCondition) {
        excluded.push(childCondition);
      } else {
        included.push(childCondition);
      }
    });
    if (included.length) {
      newStruture.childConditions?.push({ logicalOperator: logicalOperator.OR, childConditions: included, segmentId: '' });
    }
    if (excluded.length) {
      newStruture.childConditions?.push({ logicalOperator: logicalOperator.OR, childConditions: excluded, segmentId: '' });
    }
    return newStruture;
  }
  return conditions;
};

export const checkIfScheduledForPast = (broadcastStartDate: number, timeZone: string, timeZoneType: string) => {
  timeZone = getTimeZone(timeZone);
  const accountOffset = moment.tz(timeZone).utcOffset();
  const startDate = (timeZoneType === timezoneTypes.USER ? moment(broadcastStartDate) : moment(broadcastStartDate).tz(timeZone)).utcOffset(accountOffset, true);
  if (startDate < moment().tz(timeZone)) return true;
  return false;
};
export const checkIfAdvancedSegmentsValid = (advancedSegmentConditions?: any) => {
  let hasAllValidAdvancedSegments = true;

  advancedSegmentConditions?.childConditions?.forEach((childCondition?: any) => {
    const containsInvalidAdvancedSegments =
      childCondition?.segmentId === undefined || childCondition?.childConditions?.some((childC?: any) => !childC?.segmentId && !childC?.tagId);
    if (containsInvalidAdvancedSegments) {
      hasAllValidAdvancedSegments = false;
      return;
    }
  });

  return hasAllValidAdvancedSegments;
};

export const addSubsribedCondition = (conditions?: (SegmentGroup | GroupConditionType)[]) => {
  const modifiledConditions: any = {
    logicalOperator: 'and',
    childConditions: [
      {
        logicalOperator: 'and',
        childConditions: conditions,
      },
    ],
  };
  return modifiledConditions;
};

export const getSendAsMMSValue = (broadcast: BroadcastsResponse[0]) => {
  if (broadcast?.responses?.length === 1 && broadcast?.responses[0]?.components) {
    return broadcast?.responses[0]?.components?.some((component: any) => {
      return component?.platformOptions?.twilio_sms?.sendAsMms;
    });
  }
  return false;
};

export const getUserVariablesData = (broadcast: BroadcastTypeUI | BroadcastTypeAPI) => {
  const currentUserVariableSetComponent: any = broadcast?.setVariables?.filter((b: any) => {
    return b?.elseStoreVariables?.[0]?.variable?.type === 'user';
  });

  if (currentUserVariableSetComponent?.length) {
    return [{ variable: currentUserVariableSetComponent?.[0]?.variable, value: currentUserVariableSetComponent?.[0]?.value }];
  }

  return [];
};

export const getplatformOptions = (mediaUrl: string, sendAsMMS: boolean, disableSendAsMms?: boolean) => {
  if (mediaUrl || !sendAsMMS || disableSendAsMms) {
    return;
  }
  return {
    /* eslint-disable @typescript-eslint/naming-convention */
    twilio_sms: {
      sendAsMms: sendAsMMS,
    },
  };
};

export const getIncludeExcludeSegmentsIds = (selectedSegments: SelectedSegments[]) => {
  const includeSegments: any[] = [];
  const excludeSegments: any[] = [];

  selectedSegments &&
    selectedSegments.length &&
    selectedSegments.forEach((singleSegment: any) => {
      if (singleSegment.hasOwnProperty('segmentId')) {
        if (singleSegment.notCondition) {
          excludeSegments.push(singleSegment.segmentId);
        } else {
          includeSegments.push(singleSegment.segmentId);
        }
      } else if (singleSegment.hasOwnProperty('tagId')) {
        const segmentId = getTagIdGroupNameAsKey(singleSegment.tagId, singleSegment.tagGroupName);
        if (singleSegment.notCondition) {
          excludeSegments.push(segmentId);
        } else {
          includeSegments.push(segmentId);
        }
      }
    });
  return { includeSegments: includeSegments.length ? includeSegments : ['all'], excludeSegments };
};

const segmentIdToCondition = (singleSegmentId: string, segments: Segments, segmentTags: SegmentTags) => {
  let condition;
  if (segments?.[singleSegmentId]) {
    condition = {
      segmentId: singleSegmentId,
      ...removeIdsFromConditions(segments[singleSegmentId]?.conditions),
    };
  } else {
    const [segmentTagId, groupName] = singleSegmentId.split(segmentTagIdNameDelimiter);

    const segmentTag = segmentTags?.[segmentTagId];
    if (segmentTag) {
      const group = segmentTag.groups?.find((group: SegmentTagUI['groups'][0]) => {
        return group.name === groupName;
      });
      if (group) {
        condition = {
          tagId: segmentTagId,
          tagGroupName: group.name,
          ...removeIdsFromConditions(group?.conditions),
        };
      }
    }
  }
  return condition;
};

export const getAdvancedSegmentConditionsWithoutIds = (segmentConditions: any = [], otherChildConditions: any = []) => {
  const segments: any = _.cloneDeep(segmentConditions);
  segments.childConditions = [...otherChildConditions, ...segmentConditions.childConditions];
  return removeIdsFromConditions(segments)?.childConditions;
};
export const getSegmentsChildConditions = (
  selectedSegmentIds: SelectedSegments,
  segments: Segments,
  segmentTags: SegmentTags,
  isCliqzTypeBotConfig?: boolean,
  enableDefaultGlobalTargeting?: boolean,
) => {
  const includeChildCondition: SegmentCondition[] = [];
  const excludeChildCondition: SegmentCondition[] = [];
  const childConditions: (SegmentGroup | GroupConditionType)[] = isCliqzTypeBotConfig && !enableDefaultGlobalTargeting ? [usNumbersCondition] : [];

  if (selectedSegmentIds.included.length && selectedSegmentIds.included.includes(ALLCONSTANT) && selectedSegmentIds.excluded.length === 0) {
    return childConditions;
  } else if (selectedSegmentIds.included.length && !selectedSegmentIds.included.includes(ALLCONSTANT)) {
    selectedSegmentIds.included.forEach((singleSegmentId: string) => {
      if (segments || segmentTags) {
        const condition = segmentIdToCondition(singleSegmentId, segments, segmentTags);
        if (condition) {
          includeChildCondition.push(condition);
        }
      }
    });
  }
  if (selectedSegmentIds.excluded.length) {
    // excluded
    selectedSegmentIds.excluded.forEach((singleSegmentId: string) => {
      if (segments || segmentTags) {
        const condition: any = segmentIdToCondition(singleSegmentId, segments, segmentTags);
        if (condition) {
          condition.notCondition = true;
          excludeChildCondition.push(condition);
        }
      }
    });
  }
  if ((selectedSegmentIds.included.length || selectedSegmentIds.excluded.length) && (segments || segmentTags)) {
    if (includeChildCondition.length) {
      childConditions.push({
        segmentId: '',
        childConditions: includeChildCondition,
        logicalOperator: 'or',
      });
    }
    if (excludeChildCondition.length) {
      childConditions.push({
        segmentId: '',
        childConditions: excludeChildCondition,
        logicalOperator: 'and',
      });
    }
    return childConditions;
  }
};

export const convertToBotTime = (dateString?: string, timezone?: string, date?: string, time?: string) =>
  dateString ? moment(dateString).tz(timezone || '') : moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm').tz(timezone || '');

export const hasBroadcastClicksCountData = (analytics?: BroadcastTypeUI['analytics']) => {
  if (!analytics || !Array.isArray(analytics)) {
    return false;
  }
  const hasClicksData = analytics.find((metrics: Metrics) => {
    if (metrics?.metric === 'clicks') {
      return !!metrics.number;
    }
    return false;
  });

  return !!hasClicksData;
};

export const shouldShowClickMetric = (broadcast: BroadcastTypeUI) => {
  return (
    broadcast &&
    (broadcast.type === broadcastTypeLabels[broadcastTypes.NOTIFICATION] ||
      (broadcast.enableClickTracking && isUrlPresentInBroadcastMessage(broadcast)) ||
      hasBroadcastClicksCountData(broadcast.analytics))
  );
};

export const checkForLatestBroadcast: any = (broadcastList?: any, timezone?: string) => {
  //any broadcast sent in last 30 minutes
  const dateToday = moment().tz(timezone || '');
  const allBroadcasts: any = broadcastList && Object.values(broadcastList);

  return allBroadcasts?.some((broadcastItem: any) => {
    if (!broadcastItem?.date || !broadcastItem?.time) {
      return false;
    }
    const broadcastSentDate: any = moment.utc(broadcastItem?.date + ' ' + broadcastItem?.time, 'YYYY-MM-DD HH:mm').tz(timezone || '');

    const timeDiff = dateToday?.diff(broadcastSentDate, 'minutes');
    if (!(timeDiff > TIME_IN_MINUTES_FOR_BROADCASTS_POLLING) && timeDiff > -1) {
      return true;
    }
    return false;
  });
};

export const getOldestBroadcastDate = (activeBroadcasts: BroadcastTypeUI[]) => {
  if (!activeBroadcasts?.length) return null;
  const sortedBroadcast = activeBroadcasts.sort((a: BroadcastTypeUI, b: BroadcastTypeUI) => (moment(a.createdDate).isBefore(moment(b.createdDate)) ? -1 : 1));
  return moment(sortedBroadcast[0]?.createdDate).subtract(1, 'days').format('YYYYMMDD'); // subtracting -1 to make sure we don't miss any session / analytics data
};

export const getPaddedDigits = (value: any) => (value && value > 9 ? value : `0${value || ''}`);
//for fb insta platforms display notif broadcasts only for now. use this for filtering platform
export const checkPlatformInstaFB = (platform?: string) => platform && InstaFBPlatforms.includes(platform);
export const checkIfWaTemplateMacroValid = (selectedTemplate?: any) => {
  if (!selectedTemplate || [waTemplateStatus.PENDING, waTemplateStatus.REJECTED].includes(selectedTemplate.status)) return false;
  let errorObj;
  const platformTemplateMacroRegex = /{{\d+}}/g;
  const { message: templateMessage = {} } = selectedTemplate || {};
  const { title: templateTitle = '', titleMacroValues = [], headerText = '', headerTextMacroValues = [], gallery: galleryItems = [] } = templateMessage;
  let hasErrorForTitleMediaURL = false;
  if (templateMessage?.urlType === 'image' && (!templateMessage?.url || templateMessage?.url.trim() === '')) {
    hasErrorForTitleMediaURL = true;
  }
  if (templateMessage?.urlType === 'video' && (!templateMessage?.url || templateMessage?.url.trim() === '')) {
    hasErrorForTitleMediaURL = true;
  }
  const templateTitleMacroCount = templateTitle?.match(platformTemplateMacroRegex);
  const isValidTitle = templateTitleMacroCount
    ? templateTitleMacroCount?.length === titleMacroValues?.length && titleMacroValues?.every((varVal?: any) => varVal?.trim() && varVal.length < 61)
    : true;
  const isValidActionUrlForTitle = templateMessage?.actions
    ? templateMessage?.actions?.every((action: any) => {
        const validActionUrl = action.subtype ? action?.url && isValidUrl(action?.url) : true;
        return validActionUrl;
      })
    : true;

  const isValidActionModuleForTitle = templateMessage?.actions
    ? templateMessage?.actions?.every((action?: any) => {
        const validModule = !action.subtype ? action?.nextNodes?.length > 0 : true;
        return validModule;
      })
    : true;

  const headetTextMacroCount = headerText?.match(platformTemplateMacroRegex);
  const isValidHeaderText = headetTextMacroCount ? headerTextMacroValues?.every((varVal?: any) => varVal?.trim() && varVal?.length < 61) : true;
  const isValidGalleryTitle = galleryItems
    ? galleryItems?.every((gal: any) => {
        const galleryTitleMacroCount = gal?.title?.match(platformTemplateMacroRegex);
        const galleryTitles = galleryTitleMacroCount
          ? galleryTitleMacroCount?.length === gal?.titleMacroValues?.length && gal?.titleMacroValues?.every((macroValue: any) => macroValue?.trim() && macroValue.length < 61)
          : true;
        return galleryTitles;
      })
    : true;

  const isValidActionModuleForGallery = galleryItems
    ? galleryItems?.every((gal?: any) => {
        const validActions = gal?.actions?.every((action: any) => {
          const validModule = !action.subtype ? action?.nextNodes?.length > 0 : true;
          return validModule;
        });
        return validActions;
      })
    : true;

  const isValidActionUrlForGallery = galleryItems
    ? galleryItems?.every((gal?: any) => {
        const validActions = gal?.actions?.every((action?: any) => {
          const validActionUrl = action.subtype ? action?.url && isValidUrl(action?.url) : true;
          return validActionUrl;
        });
        return validActions;
      })
    : true;

  const isValidGalleryImageUrl = galleryItems
    ? galleryItems?.every((gal?: any) => {
        const validGalleryUrl = ['image', 'video'].includes(gal?.urlType) ? gal?.url?.trim() && isValidUrl(gal?.url) : true;
        return validGalleryUrl;
      })
    : true;

  if (!isValidTitle || !isValidHeaderText || !isValidGalleryTitle) {
    errorObj = { showPreventSaveModal: true, customError: true, customMessage: waTemplateErrors.targetingOnMacroFields };
  } else if (!isValidActionModuleForGallery || !isValidActionModuleForTitle) {
    errorObj = { showPreventSaveModal: true, customError: true, customMessage: waTemplateErrors.targetingOnActionModules };
  } else if (!isValidActionUrlForGallery || !isValidActionUrlForTitle) {
    errorObj = { showPreventSaveModal: true, customError: true, customMessage: waTemplateErrors.targetingToCheckValidActionUrl };
  } else if (hasErrorForTitleMediaURL || !isValidGalleryImageUrl) {
    errorObj = { showPreventSaveModal: true, customError: true, customMessage: waTemplateErrors.targetingOnImageUrls };
  }
  return {
    isValid:
      isValidTitle &&
      isValidHeaderText &&
      isValidGalleryTitle &&
      isValidActionUrlForTitle &&
      isValidActionModuleForTitle &&
      isValidActionModuleForGallery &&
      isValidActionUrlForGallery &&
      isValidGalleryImageUrl &&
      !hasErrorForTitleMediaURL,
    errorObj,
  };
};

export const convertToNotificationLabelOptions = (labels: string | any[] = []) => {
  const allLabels: any = [];

  labels.length &&
    typeof labels !== 'string' &&
    Object.values(labels).forEach((labelName: string) => {
      allLabels.push({ label: labelName, value: labelName });
    });

  return allLabels;
};

export const hasAllSelectedModuleInModuleList = (modules: string[] = [], modulesList?: Modules) => {
  return modules.some((moduleId: string) => Boolean(modulesList && modulesList[moduleId]));
};

export const convertToBroadcastModuleOptions = (modules: string[] = [], modulesList?: Modules) => {
  const allModules: TagSelectOptions = [];
  modules.length &&
    Object.values(modules).forEach((moduleId: string) => {
      moduleId && allModules.push({ label: modulesList && modulesList[moduleId]?.name ? modulesList[moduleId].name || moduleId : moduleId, value: moduleId, disabled: false });
    });
  return allModules;
};

export const convertToBroadcastModuleName = (modules: string[] = [], moduleList?: Modules) => {
  const allModules: string[] = [];

  modules.length &&
    Object.values(modules).forEach((moduleId: string) => {
      const moduleName = moduleList && moduleList[moduleId]?.name ? moduleList[moduleId]?.name || moduleId : moduleId;
      moduleId && allModules.push(moduleName);
    });

  return allModules;
};

export const isValidResponseAvailableForRNBroadcast = (broadcastType: string, isWAPlatform: boolean, broadcastResponses?: BroadcastTypeAPIResponses[]) => {
  if (
    (broadcastType === broadcastTypes.NOTIFICATION || isWAPlatform) &&
    broadcastResponses?.length === 1 &&
    broadcastResponses[0]?.type === 'module' &&
    broadcastResponses[0]?.value?.split(',')?.length === 1
  ) {
    return true;
  }
  return false;
};

export const getEquivalentUrlForPRO = () => {
  const hostname = window && window.location.host;
  let urlForModule = '';
  switch (hostname) {
    case amplifyDomain:
    case cliqzDomain:
      urlForModule = 'https://dashboard.amplify.ai';
      break;
    case trillerDomain:
      urlForModule = 'https://exponential.amplify.ai';
      break;
    case amplifyFeatureDomain:
      urlForModule = 'http://botworx-ui-test.s3-website-us-east-1.amazonaws.com';
      break;
    default:
      urlForModule = 'https://dashboard-staging.amplify.ai';
      // urlForModule = 'https://dashboard.amplify.ai'; //for testing with prod
      // urlForModule = 'http://localhost:3000'; //for local development
      // urlForModule = 'http://botworx-ui-test.s3-website-us-east-1.amazonaws.com'; //for local development
      break;
  }
  return urlForModule;
};

export const generateCSVFromJSON = (data: any[], fileName: string = 'download.csv') => {
  const csv = unparse(data);
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const csvURL = window.URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.setAttribute('download', fileName);
  link.click();
};

export const getNodeIdsFromBroadcast = (broadcasts: BroadcastTypeUI[]) => {
  const nodeId: string[] = [];
  broadcasts &&
    broadcasts.forEach((broadcast: BroadcastTypeUI) => {
      broadcast?.nodes &&
        broadcast?.nodes?.forEach((item: string) => {
          nodeId.push(item);
        });
    });
  return Array.from(new Set(nodeId));
};

const getMentionHTML = (
  index: number,
  variable: VariableType,
  mentionConfig: { [key: string]: boolean } = {},
  allowedMentionKeysInVariables: string[] = ['name', 'type', 'macro', 'id'],
) => {
  const mentionSpan = document.createElement('span');
  mentionSpan.classList.add('mentions');
  const mentionDataValue = {
    index,
    backgroundColor: variableTypeColorMapForMentions[variable.type] || variableTypeColorMapForMentions['default'],
    ...mentionConfig,
    ...Object.keys(variable).reduce((previousValue: any, variableKey: string) => {
      return allowedMentionKeysInVariables.includes(variableKey) ? { ...previousValue, [variableKey]: (variable as any)[variableKey] } : previousValue;
    }, {}), //filter out necessary fields from variables
  };
  Object.keys(mentionDataValue).forEach((key: any) => {
    mentionSpan.dataset[key] = mentionDataValue[key];
  });
  mentionSpan.style.backgroundColor = mentionDataValue.backgroundColor;
  mentionSpan.innerText = variable.name;
  return mentionSpan.outerHTML;
};

const getMergeTagHTMLSnippet = (variableCount: number, variableName: string = '', macro: string = '', mode: MergeTagMode) => {
  return `<span class="mentions" contentEditable="false" readonly data-index="${++variableCount}" data-denotation-char="" data-name="${variableName}" data-type="${mergeTagConstant}" data-mode="${mode}" data-macro="${macro}" data-id="${uuid()}" data-background-color="${
    variableTypeColorMapForMentions[mergeTagConstant]
  }" style="background-color: ${variableTypeColorMapForMentions[mergeTagConstant]}; color: #333333;">&nbsp; ${variableName} &nbsp;</span>`;
};

export const addMentionsToHtml = (
  currentMessage: string,
  variables: VariableType[],
  mentionConfig: { [key: string]: boolean } = {}, //for readOnly attributes
  addSpaceBeforeVariables: boolean = false,
  allowedMentionKeysInVariables: string[] = ['name', 'type', 'macro', 'id'], //for only passing needed keys in mentions tag
) => {
  let variableCount = 0;
  let hasMentions = false;
  combinedRegexForVars.lastIndex = 0;
  let withUrl = new RegExp(combinedRegexForVars.source, 'igm');

  if (mentionConfig?.showActBlueLink) {
    withUrl = new RegExp(actBlueURLRegex.source + '|' + urlRegexForClickTrackingNew.source + '|' + combinedRegexForVars.source, 'igm');
  }
  const message = currentMessage
    .replaceAll(withUrl, (variableName: string) => {
      if (mentionConfig.isActBlueEditor && currentMessage.includes(`data-macro="${variableName}"`)) return variableName;
      if (new RegExp(actBlueURLRegex.source + '|' + urlRegexForClickTrackingNew.source, 'g').test(variableName)) {
        return variableName;
      } else if (advancedTwigTemplateRegex.test(variableName)) {
        hasMentions = true;
        const { hasDateString, variable, dateFormat, elsePart } = extractDateFormattedValues(variableName);
        if (hasDateString && variable) {
          const withDotNotation = convertSquareBracketToDotation(variable);
          const variableObj = variables?.find((v: VariableType) => v.macro === `{{${withDotNotation}}}`);
          const displayText = getDateFormatterDisplayText(variableObj || { name: variable }, dateFormat, elsePart);
          return getMergeTagHTMLSnippet(++variableCount, displayText, variableName, 'dateFormatter');
        }

        return getMergeTagHTMLSnippet(++variableCount, variableName, variableName, 'advanced');
      } else if (defaultTwigTemplate.test(variableName)) {
        // normal variable should also get treated as twig default template
        const varName = convertFromTwigSyntaxToDisplayText(variableName, variables);
        hasMentions = true;

        return getMergeTagHTMLSnippet(++variableCount, addSpaceBeforeVariables ? ` ${varName} ` : varName, variableName, 'default');
      } else if (variableRegex.test(variableName)) {
        const variable = variables.find((v: VariableType) => v.macro === variableName);
        hasMentions = hasMentions || !!variable;

        return getMergeTagHTMLSnippet(++variableCount, variable?.name ? `{${variable?.name}}` : variableName, variableName, 'default');
      }

      const variable = variables.find((v: VariableType) => v.macro === variableName);
      hasMentions = hasMentions || !!variable;

      if (variable) {
        return getMentionHTML(++variableCount, variable, mentionConfig, allowedMentionKeysInVariables);
      }
      return variableName;
    })
    .replaceAll(actBlueURLRegex, (match: string) => {
      if (mentionConfig?.showActBlueLink) {
        const url = match.replaceAll('&amp;', '&');
        hasMentions = true;
        return `<span class="mentions" data-index="${++variableCount}" data-name="${url}" data-type="${mentionLinkConstant}" data-mode="advanced" data-macro="${url}" data-id="${uuid()}" data-background-color="${
          variableTypeColorMapForMentions[mentionLinkConstant]
        }" style="background-color: transparent; color: #1ecfff;">${url}</span>`;
      }
      return match;
    });
  return { message, hasMentions };
};

export const replaceMentionsFromHtml = (currentMessage: string) => {
  const htmlDiv = document.createElement('div');
  htmlDiv.innerHTML += currentMessage;
  htmlDiv.querySelectorAll('.mentions').forEach((i: any) => {
    i.replaceWith(i.getAttribute('data-macro'));
  });
  return htmlDiv.innerHTML;
};

export const extractLocalVariablesFromBroadcasts = (broadcast: BroadcastTypeUI): VariableType[] => {
  if (!broadcast?.actions?.length) return [];
  const localVariables: VariableType[] = [];
  let isLocalVariablesExtracted = false;
  broadcast.actions.forEach((action: any) => {
    if (!action?.components?.length || isLocalVariablesExtracted) return;
    const allVariableComponents = action.components.filter((component: any) => component?.type === 'storeUserVariable');
    if (allVariableComponents.length) {
      isLocalVariablesExtracted = true;
      allVariableComponents.forEach((component: any) => {
        if (component?.conditions?.length) {
          component.conditions.forEach((condition: any) => {
            const variables = condition?.storeVariables ? condition.storeVariables : condition?.storeVariable ? [condition.storeVariable] : [];
            localVariables.push(
              ...variables.map((variable: any) => {
                return { id: uuid(), ...(variable?.variable || {}) } as VariableType;
              }),
            );
          });
        }
        if (component?.elseStoreVariables?.length) {
          localVariables.push(
            ...component?.elseStoreVariables.map((variable: any) => {
              return { id: uuid(), ...(variable?.variable || {}) } as VariableType;
            }),
          );
        }
      });
    }
  });
  return localVariables;
};

const replaceMacrosWithObjectNotation = (text: string, removeObjNotation: boolean = false, addQuotes: boolean = false) => {
  let macro = text?.replaceAll(/\{\{.*?\}\}/g, (match: string) => {
    const macroSplit = match.replaceAll(/{{|}}/g, '').split('.');
    let replaceValue = '';
    if (macroSplit.length > 1) {
      macroSplit.forEach((i: any, index: any) => {
        replaceValue += index !== macroSplit.length - 1 ? i : removeObjNotation ? `.${i}` : `['${i}']`;
        replaceValue += index < macroSplit.length - 2 ? '.' : '';
      });
      replaceValue = '{{' + replaceValue + '}}';
    } else {
      replaceValue = '{{' + macroSplit.join('.') + '}}';
    }
    return replaceValue;
  });

  if (!addQuotes) {
    if (checkObjNotation.test(macro)) {
      macro = macro.replaceAll('{{', '').replaceAll('}}', '');
    } else if (!checkObjNotation.test(macro) && !variableRegex.test(macro)) {
      macro = `'${macro}'`;
    }
  }
  return macro;
};

const getOperationalStatement = (ifStatement: any) => {
  const macro = replaceMacrosWithObjectNotation(ifStatement.variable.macro);
  const macroValue = replaceMacrosWithObjectNotation(ifStatement.value, false);
  switch (ifStatement.condition) {
    case 'equal':
      return `${macro} == ${macroValue}`;
    case 'not equal':
      return `${macro} != ${macroValue}`;
    case 'greater than':
      return `${macro} > ${macroValue}`;
    case 'less than':
      return `${macro} < ${macroValue}`;
    case 'substring':
    case 'word':
      return `${macroValue} in ${macro}`;
    case 'not substring':
    case 'not word':
      return `${macroValue} not in ${macro}`;
    case 'newer than':
      return `${macro.replace('}}', '')}|date('Y-m-d H:i:s')}} > {{"now"|date_modify("-${macroValue} ${ifStatement.valueUnit}")|date('Y-m-d H:i:s')}}`;
    case 'older than':
      return `${macro.replace('}}', '')}|date('Y-m-d H:i:s')}} < {{"now"|date_modify("-${macroValue} ${ifStatement.valueUnit}")|date('Y-m-d H:i:s')}}`;
    case 'not exist':
      return macro + ' is not defined';
    case 'exist':
    default:
      return macro + ' is defined';
  }
};

export const extractConditionsFromBroadcast = (broadcast: BroadcastTypeUI | null, localVariables: VariableType[]) => {
  if (!broadcast?.actions?.length) return [];

  let newLocalVariables: any = Object.values(localVariables).reduce((previousValue: any, localVariable: any) => ({ ...previousValue, [localVariable.macro]: '' }), {});
  for (const action of broadcast.actions) {
    if (!action?.components?.length) return;

    const allVariableComponents = action.components.filter((component: any) => component?.type === 'storeUserVariable');

    if (allVariableComponents?.length) {
      for (const content of allVariableComponents) {
        if (!content?.conditions?.length) {
          const locallyStoredVariables = content?.elseStoreVariables?.map((variable: any) => ({
            macro: variable?.variable?.macro,
            value: replaceMacrosWithObjectNotation(variable?.value, true, true),
          }));

          // eslint-disable-next-line no-loop-func
          locallyStoredVariables?.forEach((locallyStoredVariable: any) => {
            if (newLocalVariables?.hasOwnProperty(locallyStoredVariable?.macro)) {
              newLocalVariables[locallyStoredVariable?.macro] += locallyStoredVariable?.value;
            } else {
              newLocalVariables[locallyStoredVariable?.macro] = locallyStoredVariable?.value;
            }
          });
        } else {
          const locallyStoredVariables: any = {};
          const conditions = content?.conditions;

          // eslint-disable-next-line array-callback-return
          conditions?.map((condition: any) => {
            let conditionalIf = '';
            condition?.ifStatements?.forEach(
              (ifStatement: any, ifIndex: any) => (conditionalIf += (ifIndex === 0 ? '' : ` ${condition?.logicalOperator} `) + getOperationalStatement(ifStatement)),
            );

            condition?.storeVariables?.forEach((oneStoreVariable: any) => {
              if (locallyStoredVariables[oneStoreVariable.variable.macro]) {
                locallyStoredVariables[oneStoreVariable.variable.macro] += `{% elseif ${conditionalIf} %}${replaceMacrosWithObjectNotation(oneStoreVariable.value)}`;
              } else {
                locallyStoredVariables[oneStoreVariable.variable.macro] = `{% if ${conditionalIf} %}${replaceMacrosWithObjectNotation(oneStoreVariable.value)}`;
              }
            });
          });

          // eslint-disable-next-line array-callback-return
          content?.elseStoreVariables?.map((elseStoreVariable: any) => {
            if (locallyStoredVariables[elseStoreVariable.variable.macro]) {
              locallyStoredVariables[elseStoreVariable.variable.macro] += `{% else %}${replaceMacrosWithObjectNotation(elseStoreVariable.value)}{% endif %}`;
            } else {
              locallyStoredVariables[elseStoreVariable.variable.macro] = replaceMacrosWithObjectNotation(elseStoreVariable.value);
            }
          });

          Object.keys(locallyStoredVariables).forEach((locallyStoredVariableName: any) => {
            const locallyStoredVariable = locallyStoredVariables[locallyStoredVariableName];
            if (locallyStoredVariable.includes(`{% if`) && !locallyStoredVariable.includes(`{% endif %}`)) {
              locallyStoredVariables[locallyStoredVariableName] += `{% endif %}`;
            }
          });

          newLocalVariables = { ...newLocalVariables, ...locallyStoredVariables };
        }
      }
    }
  }

  Object.entries(newLocalVariables).forEach(([key, value]: any) => {
    if (value.match(/\{% if .* is defined %\}.*\{% else %\}.*\{% endif %\}/g)) {
      const ifMacro = value.match(/\{% if (.*?) is defined %\}/)?.[1];
      const ifValueMatch = value.match(/\}(.*)\{% else %\}/);
      const ifValue = ifValueMatch ? ifValueMatch[1].replaceAll('{% else %}', '') : undefined;

      if (!!ifMacro && ifMacro === ifValue) {
        const objNotation = ifMacro.match(checkObjNotation);
        const replacedValue = ifMacro?.replace(objNotation?.[0], `.${objNotation?.[1]}`) || ifMacro;
        const defaultValueMatch = value.match(/\{% else %\}(.*?)\{% endif %\}/);
        const defaultValue = defaultValueMatch ? defaultValueMatch[1]?.replaceAll('{% else %}', '')?.replaceAll('{% endif %}', '') : undefined;

        if (!!defaultValue && !defaultValue.match(/\{\{.*?\}\}/g)) {
          newLocalVariables[key] = `{{${replacedValue} | default(${defaultValue})}}`;
        }
      }
    }
  });
  return newLocalVariables;
};

export const calculateMessageParams = async (
  bot: Bot,
  currentMessage: string,
  appendTextValue: string,
  enableClickTracking: boolean,
  excludeOptoutText: boolean,
  hasMedia: boolean,
  isSMSPlatform: boolean,
  viewMode: boolean,
) => {
  const customDomain = bot?.config?.shortUrlConfig?.customDomain;
  const convertMMSToSMSWithLink = bot?.config?.messaging?.convertMMSToSMSWithLink;
  const mmsConversionThreshold = bot?.config?.messaging?.segmentCountThresholdToCovertToMMS || SEGMENT_COUNT_THRESHOLD;
  let transformedMessage = isSMSPlatform ? await html2unicode(replaceMentionsFromHtml(currentMessage)) : currentMessage;
  const textToCopy = appendText(transformedMessage, !viewMode && isSMSPlatform && excludeOptoutText, appendTextValue);
  const { message, variables, allUrls = [], replacedChars, specialCharsFound } = transformMessageForSegmentCount(transformedMessage, enableClickTracking, customDomain);
  const isMessageEmpty = !variables?.length && message.length === 0;
  transformedMessage = appendText(message, !viewMode && isSMSPlatform && excludeOptoutText, appendTextValue, !isMessageEmpty);
  if (convertMMSToSMSWithLink && hasMedia) {
    transformedMessage += getShortUrl(customDomain) + '\n';
  }
  const segmentParams = getSegmentsCount(
    transformedMessage ? transformedMessage : appendText(transformedMessage, !viewMode && isSMSPlatform && excludeOptoutText, appendTextValue, true),
  );
  return {
    allUrls,
    macrosUsed: !!variables,
    textToCopy,
    replacedChars,
    specialCharsFound,
    ...segmentParams,
    isSegmentValid: !isSMSPlatform ? true : convertMMSToSMSWithLink ? segmentParams.numberOfMsgs < mmsConversionThreshold : segmentParams.messageLength <= 1600,
    isMessageEmpty,
    macroUsedCount: variables?.length || 0,
  };
};

export const isActBlueUrlPresent = (message: string) => {
  return message.includes(actBlueBaseUrl);
};
export const getUserVariableValues = (userVariableMapWithValues: any[] = [], originalBroadcastData?: any) => {
  let setVariables: any = [];

  const existingUserVariables = originalBroadcastData?.setVariables?.filter((b: any) => b?.variable?.type === 'user');
  const existingSetVariable = originalBroadcastData?.setVariables?.filter((b: any) => b?.id !== existingUserVariables?.[0]?.id);
  existingSetVariable?.length && (setVariables = [...existingSetVariable]);

  userVariableMapWithValues?.forEach((varMap: any) => {
    const { variable, value } = varMap;
    variable &&
      setVariables?.push({
        type: 'storeUserVariable',
        // value,
        // variable,
        elseStoreVariables: [{ value, variableAction: 'equal', variable }],
        conditions: [],
      });
  });

  return { setVariables };
};
export const getMessageWithVarWithTwigSyntax = (message: string) => {
  let messageWithVarInTwigSyntax = message;
  combinedTwigPattern.lastIndex = 0;
  const isTwig = combinedTwigPattern.test(message);
  if (isTwig) {
    messageWithVarInTwigSyntax = message.replaceAll(combinedRegexForVars, (variableName: string) => {
      if (advancedTwigTemplateRegex.test(variableName) || variableName === broadcastVariableMacro) {
        //we are assuming it is written in valid tig pattern
        return variableName;
      }

      //if default template Match

      const defaultTemplateMatch = variableName.match(defaultTwigTemplate);
      if (defaultTemplateMatch) {
        //converting to square notation
        return variableName.replace(defaultTemplateMatch[1], `${convertDotToSquareBracketNotation(defaultTemplateMatch[1].trim())}`);
      }

      //if variable match
      const varMatch = variableName.match(variableRegex);

      if (varMatch) {
        //converting to square notation
        return variableName.replace(varMatch[1], convertDotToSquareBracketNotation(varMatch[1].trim()));
      }

      return variableName;
    });
  }
  return { messageWithVarInTwigSyntax, isTwig };
};

export const twigVarSyntaxToAmplifySyntax = (message: string) => {
  combinedTwigPattern.lastIndex = 0;
  const { list, text } = removeRegexMatchAndGiveList(message, actBlueURLRegex);
  //this is done to prevent macros in actBlueUrl from getting converted to Dot Notation
  let messageWithVarInAmplifySyntax = text;

  messageWithVarInAmplifySyntax = messageWithVarInAmplifySyntax.replaceAll(combinedRegexForVars, (variableName: string) => {
    if (advancedTwigTemplateRegex.test(variableName) || variableName === broadcastVariableMacro) {
      //we are assuming it is written in valid tig pattern
      return variableName;
    }

    //if default template Match

    const defaultTemplateMatch = variableName.match(defaultTwigTemplate);
    if (defaultTemplateMatch) {
      //converting to square notation
      return variableName.replace(defaultTemplateMatch[1], `${convertSquareBracketToDotation(defaultTemplateMatch[1].trim())}`);
    }

    //if variable match
    const varMatch = variableName.match(variableRegex);

    if (varMatch) {
      //converting to square notation
      return variableName.replace(varMatch[1], convertSquareBracketToDotation(varMatch[1].trim()));
    }
    return variableName;
  });
  messageWithVarInAmplifySyntax = updateMatcherStringsWithList(messageWithVarInAmplifySyntax, list);
  return messageWithVarInAmplifySyntax;
};

// export function convertDotToSquareBracketNotation(str: string) {
//   const [objName, ...rest] = str.split('.');
//   return rest.reduce((acc: string, cur: string) => {
//     if (/\s/.test(cur) && !cur.includes('[')) {
//       return `${acc}['${cur}']`;
//     }
//     return `${acc}.${cur}`;

//   }, objName);
// }
//this converts x.y.z to x['y']['z']
// export function convertDotToSquareBracketNotation(str: string) {
//   const [objName, ...rest] = str.split('.');
//   return rest.reduce((acc: string, cur: string) => {
//     if (cur.includes('[')) {
//       const index = cur.indexOf("[");
//       const varName = cur.slice(0, index);
//       const varProps = cur.slice(index);

//       return `${acc}['${varName}']${varProps}`;
//     }
//     return `${acc}['${cur}']`;

//   }, objName);
// }

// this coverts x.y.z a to x.y['z a']

export function convertDotToSquareBracketNotation(str: string) {
  const lastIndex = str.lastIndexOf('.');
  let varName;
  let varProps;
  if (lastIndex > 0) {
    varName = str.slice(0, lastIndex);
    varProps = str.slice(lastIndex + 1);
    if (varProps.includes('[')) {
      return str;
    }
    return `${varName}['${varProps}']`;
  }
  return str;
}

export function convertSquareBracketToDotation(str: string) {
  return str.replaceAll(/\[['"]{1}/g, '.').replaceAll(/['"]{1}\]/g, '');
}
export const getWaTemplateResponse = (selectedWaTemplate?: any) => {
  if (selectedWaTemplate?.id) {
    const { name, id, message } = selectedWaTemplate;
    const hasCarousel = message?.headerText && message?.gallery;
    const waResponses: any = [
      {
        type: broadcastResponseTypes.TEMPLATES,
        components: [
          {
            type: 'platform_template',
            subType: hasCarousel ? 'carousel' : 'standard',
            id,
            value: {
              name,
              message,
            },
          },
        ],
      },
    ];
    return waResponses;
  }
  return null;
};
export const checkIsGSM7 = (data: any) => {
  for (const arr of data) {
    for (const obj of arr) {
      if (obj.isGSM7 === false) {
        return false;
      }
    }
  }
  return true;
};

export const getVariables = (state: any, currentBot: any, localVariables: any) => {
  const currentPlatformBotId = state?.pageUIState.selectedPlatformBot;
  const currentPlatformBotObj = currentPlatformBotId && state?.entities.platformBots?.[currentPlatformBotId];
  const configData = state.platformActionConfig?.configData;
  const platform = currentPlatformBotObj?.platformAsInSystemSettings;

  if (!currentBot || !state.entities?.variables || !state.entities?.variables?.length) {
    return [];
  }
  let variables = state.entities?.variables && Object.values(state.entities?.variables);
  localVariables &&
    Object.keys(localVariables).forEach((v: any) => {
      variables.push(localVariables[v]);
    });
  const systemSettingsForPlatform = configData?.[platform] || {};
  const { variableTypes } = systemSettingsForPlatform;

  variables = variables.filter((variable: any) => {
    if (variableTypes) {
      //if variableType is not defined, we are showing everything
      if (!variableTypes?.[variable?.type]?.disabled) {
        const allowedVariableNames = variableTypes?.[variable?.type]?.allowedVariableNames;
        if (allowedVariableNames) {
          //if variableType is not defined, we are showing every variable
          return allowedVariableNames.includes(variable.name);
        }
        return true;
      }
      return false;
    }
    return true;
  });

  variables = variables.sort(function (a: any, b: any) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  return variables;
};
export const getNewLocalVariable = (name: any) => {
  name = name && name.trim();
  return {
    name,
    botId: null,
    type: 'local',
    macro: `{{volatile.privateVariables.${name}}}`,
    id: uuid(),
  };
};
export const extractVariables = (components: any[] = []) => {
  const variables: any = {};
  Object.values(components).forEach((component: any) => {
    component &&
      component.content &&
      component.content.elseStoreVariables &&
      //   component.content.elseStoreVariables.forEach(({ variable: { name } }) => { //code was running into exception here
      component.content.elseStoreVariables.forEach(({ variable }: any) => {
        if (variable) {
          const newLocalVariable = getNewLocalVariable(variable.name);
          variables[variable.name] = newLocalVariable;
        }
      });
    component &&
      component.content &&
      component.content.conditions &&
      component.content.conditions.forEach((condition: any) => {
        condition &&
          condition.storeVariable &&
          condition.storeVariable.forEach((storeVariableStatement: any) => {
            if (storeVariableStatement && storeVariableStatement.variable && storeVariableStatement.variable.name) {
              const newLocalVariable = getNewLocalVariable(storeVariableStatement.variable.name);
              variables[storeVariableStatement.variable.name] = newLocalVariable;
            }
          });
      });
  });
  return variables;
};

export const getSetVariableComponent = (conditions: any = [], elseStoreVariables: any = []) => {
  return {
    content: { conditions, elseStoreVariables },
    type: 'SetAttribute',
    id: uuid(),
  };
};

export const mapRefCodeToBroadcast = (donationRefcodeAnalytics: any, refcodeBroadcastMapping: any, donationDataFotOtherBroadcasts: any = []) => {
  if (!donationRefcodeAnalytics?.length) {
    return donationDataFotOtherBroadcasts;
  }
  // Group analytics by broadcastId
  const groupedAnalytics = _.groupBy(donationRefcodeAnalytics, (analytics: any) => refcodeBroadcastMapping[analytics.key]);
  delete groupedAnalytics['undefined'];

  // Aggregate values for each broadcastId
  const donationBroadcastAnalytics = _.mapValues(groupedAnalytics, (analytics: any) => {
    const broadcastId = analytics?.[0]?.key && refcodeBroadcastMapping?.[analytics[0].key];
    return {
      donationAmount: _.sumBy(analytics, 'donationAmount'),
      donationCount: _.sumBy(analytics, 'donationCount'),
      refundAmount: _.sumBy(analytics, 'refundAmount'),
      refundCount: _.sumBy(analytics, 'refundCount'),
      key: broadcastId,
    };
  });

  return { ...donationBroadcastAnalytics, ...donationDataFotOtherBroadcasts };
};

export const parseBroadcastsForClickTracking = (broadcasts: any) => {
  const clickTrackEnabledbroadcasts: any = {};
  const clickTrackDisabledbroadcasts: any = {};
  const allDisabledClicksRefCodes: string[] = [];
  const refcodeBroadcastMapping: any = {};

  broadcasts.forEach((broadcast: any) => {
    const { id, createdDate } = broadcast || {};
    // const broadcastDate: any =
    //   broadcast?.date &&
    //   broadcast?.time &&
    //   (broadcast?.timeZone === timezoneTypes.USER
    //     ? moment.utc(broadcast?.date + ' ' + broadcast?.time, 'YYYY-MM-DD HH:mm')
    //     : convertToBotTime(undefined, timezone, broadcast?.date, broadcast?.time));
    const broadcastContainsDonationLink = checkIfBroadcastContainsActBlueUrl(broadcast);
    const utilizeBroadcastDonation = moment(createdDate)?.isAfter(donationIndexStartDate) && (broadcastContainsDonationLink || !!broadcast?.refcodes?.length);

    if (broadcast.enableClickTracking && utilizeBroadcastDonation) {
      clickTrackEnabledbroadcasts[broadcast.id] = { id, createdDate };
    } else if (utilizeBroadcastDonation) {
      clickTrackDisabledbroadcasts[broadcast.id] = { id, createdDate };

      broadcast?.refcodes?.length &&
        broadcast?.refcodes?.forEach((refcodeVal: string) => {
          allDisabledClicksRefCodes?.push(refcodeVal);
          refcodeBroadcastMapping[refcodeVal] = broadcast?.id;
        });

      if (clickTrackDisabledbroadcasts[broadcast.id].length === 0) {
        delete clickTrackDisabledbroadcasts[broadcast.id];
      }
    }
  });

  return {
    clickTrackEnabledbroadcasts,
    clickTrackDisabledbroadcasts,
    allDisabledClicksRefCodes,
    refcodeBroadcastMapping,
  };
};

export const checkIfBroadcastContainsActBlueUrl = (broadcast?: any) => {
  return broadcast?.responses?.[0]?.components?.some((broadcastResComopnent?: any) => {
    return broadcastResComopnent?.type === 'text' && broadcastResComopnent?.text?.match(actBlueURLRegex);
  });
};
export const getDonationConfigIfHidden = (broadcast: any, timezone: any) => {
  const broadcastContainsDonationLink = checkIfBroadcastContainsActBlueUrl(broadcast);
  const broadcastDate: any =
    broadcast?.date &&
    broadcast?.time &&
    (broadcast?.timeZone === timezoneTypes.USER
      ? moment.utc(broadcast?.date + ' ' + broadcast?.time, 'YYYY-MM-DD HH:mm')
      : convertToBotTime(undefined, timezone, broadcast?.date, broadcast?.time));
  const utilizeBroadcastDonation = moment(broadcastDate)?.isAfter(donationIndexStartDate) && (broadcastContainsDonationLink || !!broadcast?.refcodes?.length);

  return utilizeBroadcastDonation ? {} : { engagementStats: { hideStats: donationUiVariables } };
};
export const getSegmentCostStatAsPerResponses = (broadcast: any) => {
  let allowMmsSegmentCostStat = false;
  let allowSmsSegmentCostStat = false;
  if (broadcast?.responses) {
    for (const res of broadcast.responses) {
      if (res?.type === broadcastResponseTypes.MODULE) {
        allowSmsSegmentCostStat = false;
        allowMmsSegmentCostStat = false;
        break;
      }
      if (broadcast?.isBroadcastMMS || res?.type === broadcastResponseTypes.MMS) {
        allowMmsSegmentCostStat = true;
      } else {
        allowSmsSegmentCostStat = true;
      }
    }
  }
  return { allowSmsSegmentCostStat, allowMmsSegmentCostStat };
};

export const getNotificationLabelConditions = (timeZone: string, notificationLabels: string[] = []) => {
  const accountTimezone = getTimeZone(timeZone);
  const timeNow = moment.tz(accountTimezone || '');
  const startOfMonth = moment.tz(accountTimezone || '').startOf('month');
  const startOfWeek = moment
    .tz(accountTimezone || '')
    .startOf('week')
    .add(1, 'day');
  const startOfday = moment.tz(accountTimezone || '').startOf('day');
  const secondsPassedFromFirstofMonth = timeNow.diff(startOfMonth, 'seconds');
  const secondsPassedFromFirstofWeek = timeNow.diff(startOfWeek, 'seconds');
  const secondsPassedFromToday = timeNow.diff(startOfday, 'seconds');
  let labelsValue = '';
  notificationLabels.forEach((label: string, index: number) => {
    labelsValue += `${label}${index < notificationLabels.length - 1 ? '|' : ''}`;
  });
  labelsValue = `^(${labelsValue})$`;
  const labelConditions = {
    logicalOperator: 'and',
    childConditions: [
      {
        logicalOperator: 'and',
        ifStatements: [
          {
            variable: {
              macro: '{{notifications.label}}',
            },
            condition: 'regex',
            value: labelsValue, // selected label.
          },
          {
            variable: {
              macro: '{{notifications.expiry}}',
            },
            condition: 'newer than',
            value: '0',
          },
        ],
      },
      {
        logicalOperator: 'or',
        childConditions: [
          {
            logicalOperator: 'and',
            ifStatements: [
              {
                variable: {
                  macro: '{{notifications.frequency}}',
                },
                condition: 'not exist',
                value: '',
              },
              {
                variable: {
                  macro: '{{notifications.lastSentTime}}',
                },
                condition: 'equal',
                value: '0',
              },
            ],
          },
          {
            logicalOperator: 'and',
            ifStatements: [
              {
                variable: {
                  macro: '{{notifications.frequency}}',
                },
                condition: 'equal',
                value: 'daily',
              },
              {
                variable: {
                  macro: '{{notifications.lastSentTime}}',
                },
                condition: 'older than',
                value: secondsPassedFromToday.toString(),
                valueUnit: 'seconds',
              },
            ],
          },
          {
            logicalOperator: 'and',
            ifStatements: [
              {
                variable: {
                  macro: '{{notifications.frequency}}',
                },
                condition: 'equal',
                value: 'weekly',
              },
              {
                variable: {
                  macro: '{{notifications.lastSentTime}}',
                },
                condition: 'older than',
                value: secondsPassedFromFirstofWeek.toString(),
                valueUnit: 'seconds',
              },
            ],
          },
          {
            logicalOperator: 'and',
            ifStatements: [
              {
                variable: {
                  macro: '{{notifications.frequency}}',
                },
                condition: 'equal',
                value: 'monthly',
              },
              {
                variable: {
                  macro: '{{notifications.lastSentTime}}',
                },
                condition: 'older than',
                value: secondsPassedFromFirstofMonth.toString(),
                valueUnit: 'seconds',
              },
            ],
          },
        ],
      },
    ],
  };
  return notificationLabels.length ? labelConditions : [];
};

export const getSubscriptionBroadcastConditions = (broadcast: BroadcastTypeUI | null) => {
  const conditions = {
    logicalOperator: 'and',
    childConditions: [cloneDeep(subscribedIfStmtCondition)],
  };
  if (broadcast?.subscriptionId && broadcast?.subscriptionId !== 'ALL') {
    conditions.childConditions[0].ifStatements.push({
      variable: {
        name: 'subscriptions',
        type: '',
        macro: '{{subscriptions}}',
      },
      value: broadcast.subscriptionId,
      condition: 'equal',
    });
  }
  return conditions;
};

export const migrateBroadcast = (
  broadcast: BroadcastTypeUI,
  localVariables: VariableType[],
  setVariables: React.Dispatch<React.SetStateAction<VariableType[]>>,
  setCurrentMessage: React.Dispatch<React.SetStateAction<string>>,
  dispatch: any,
  storeBroadcast: any,
  setIsNotMigratable?: React.Dispatch<React.SetStateAction<boolean>>,
  setTouched?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >,
  touched?: {
    [key: string]: boolean;
  },
) => {
  const conditions = extractConditionsFromBroadcast(broadcast, localVariables);
  if (!Object.values(conditions || {}).some((value: any) => !value)) {
    const updatedBroadcast = {
      ...broadcast,
      hasComplexTargetingNResponse: false,
      hasLocalVars: false,
      // hasUserVars:false,
      preventMessageEditWithWarning: '',
      actions: broadcast?.actions?.map((action: any) => ({
        ...action,
        components: action?.components
          ?.filter((component: any) => component?.type !== 'storeUserVariable')
          ?.map((component: any) => {
            if (component.type === 'text') {
              return {
                ...component,
                text: Object.keys(conditions).reduce((acc: string, conditionKey: string) => {
                  const conditionValue = conditions[conditionKey];
                  setVariables((variable: VariableType[]) => {
                    return variable.filter((vars: VariableType) => {
                      return vars.macro !== conditionKey;
                    });
                  });
                  setCurrentMessage((value: string) => value.replaceAll(conditionKey, conditionValue));
                  return acc.replaceAll(conditionKey, conditionValue);
                }, component?.text || ''),
              };
            }
            return component;
          }),
      })),
      responses: broadcast?.responses?.map((response: any) => ({
        ...response,
        components: response?.components
          ?.filter((component: any) => component?.type !== 'storeUserVariable')
          ?.map((component: any) => {
            if (component.type === 'text') {
              return {
                ...component,
                text: Object.keys(conditions).reduce((acc: string, conditionKey: string) => {
                  const conditionValue = conditions[conditionKey];
                  setVariables((variable: VariableType[]) => {
                    return variable.filter((vars: VariableType) => {
                      return vars.macro !== conditionKey;
                    });
                  });
                  setCurrentMessage((value: string) => value.replaceAll(conditionKey, conditionValue));
                  return acc.replaceAll(conditionKey, conditionValue);
                }, component?.text || ''),
              };
            }
            return component;
          }),
      })),
    };
    dispatch(storeBroadcast(updatedBroadcast));
    setTouched && setTouched({ ...touched, message: true });
  } else {
    setIsNotMigratable && setIsNotMigratable(true);
  }
};
export const toSortBroadcastsAsPerScheduledDate = (a: BroadcastTypeUI, b: BroadcastTypeUI) => {
  if (a?.scheduledDateAndTime === 'Invalid date') {
    return 1;
  } else if (b?.scheduledDateAndTime === 'Invalid date') {
    return -1;
  }
  return moment(a?.scheduledDateAndTime)?.isAfter(b?.scheduledDateAndTime) ? -1 : 1;
};

export const toSortBroadcastsAsPerLastUpdatedDate = (a: BroadcastTypeUI, b: BroadcastTypeUI) => {
  if (a?.lastUpdatedDate === 'Invalid date') {
    return 1;
  } else if (b?.lastUpdatedDate === 'Invalid date') {
    return -1;
  }
  return moment(a?.lastUpdatedDate)?.isAfter(b?.lastUpdatedDate) ? -1 : 1;
};

export const extractMacroAndValueFromTwigSyntax = (twigSyntax: string, availableVariables: VariableType[]) => {
  const [firstPart, secondPart] = twigSyntax.split('|');
  let macro = `{{${firstPart
    .replace('{{', '')
    .replace(secondPart ? '' : '}}', '')
    .trim()}}}`;
  macro = convertSquareBracketToDotation(macro);
  const variable = availableVariables?.find((v: VariableType) => v.macro === macro);
  const defaultValue = secondPart
    ? `${secondPart
        .replace('default', '')
        .replaceAll(/[}()'"]/gi, '')
        .trim()}`
    : '';

  return {
    variable: { macro: variable?.macro || macro, name: variable?.name || '', type: variable?.type || '' },
    value: defaultValue,
  };
};

export const addMentionsToHtmlForActBlueUrl = (currentMessage: string, variables: VariableType[]) => {
  let variableCount = 0;
  let hasMentions = false;
  combinedRegexForVars.lastIndex = 0;
  const defaultTwigRegex = new RegExp(defaultTwigTemplate.source, 'igm');
  const variableReg = new RegExp(variableRegex.source, 'igm');
  let message = currentMessage;
  if (message.includes(broadcastVariableMacro)) {
    message = message.replaceAll(broadcastVariableMacro, (variableName: string) => {
      if (currentMessage.includes(`data-macro="${variableName}"`) && !defaultTwigRegex.test(variableName)) return variableName;
      const variable = variables.find((v: VariableType) => v.macro === variableName);
      hasMentions = hasMentions || !!variable;
      return getMergeTagHTMLSnippet(++variableCount, variable?.name ? `{${variable?.name}}` : variableName, variableName, 'default');
    });
  } else if (message.includes(broadcastVariableMacroSquareBracket)) {
    message = message.replaceAll(broadcastVariableMacroSquareBracket, (variableName: string) => {
      if (currentMessage.includes(`data-macro="${variableName}"`) && !defaultTwigRegex.test(variableName)) return variableName;
      const variable = variables.find((v: VariableType) => v.macro === variableName);
      hasMentions = hasMentions || !!variable;
      return getMergeTagHTMLSnippet(++variableCount, variable?.name ? `{${variable?.name}}` : variableName, variableName, 'default');
    });
  }
  message = message.replaceAll(variableReg, (variableName: string) => {
    if (message.includes(`data-macro="${variableName}"`) || variableName === broadcastVariableMacro || variableName === broadcastVariableMacroSquareBracket) {
      return variableName;
    }
    const { value, variable } = extractMacroAndValueFromTwigSyntax(variableName, variables);

    return getMergeTagHTMLSnippet(++variableCount, `{${variable.name}${value.length ? ' | ' + value : ''}}`, variableName, 'default');
  });
  return { message, hasMentions };
};

export const appendBroadcastReceivedCondition = (broadcastId: string, name: string, existingConditions?: GCConditions) => {
  const broadcastReceivedCondition = {
    ifStatements: [
      {
        variable: {
          id: broadcastId || '',
          name: name || '',
          macro: `{{messageHistory.broadcastHistory.${broadcastId}}}`,
        },
        condition: 'exist',
        value: '',
      },
    ],
    logicalOperator: 'and',
  };
  return {
    logicalOperator: 'and',
    childConditions: [...(existingConditions ? [existingConditions] : []), broadcastReceivedCondition],
  };
};

export const getStartAndEndDateFromMonth = (selectedMonth: string, format: string = 'YYYY-MM-DD') => {
  const startOfMonth = moment(selectedMonth).startOf('month').format(format);
  const endOfMonth = moment(selectedMonth).endOf('month').format(format);
  // Determine if the current date is before or on the end of the month
  // If so, set `endDate` to the current date; otherwise, set it to the end of the month
  const endDate = moment() <= moment(endOfMonth) ? moment().format(format) : endOfMonth;
  return { startOfMonth, endDate };
};

export const getLastMonths = (monthsCount: number): Option[] => {
  const monthOptionsArr: Option[] = [];

  for (let i = 0; i < monthsCount; i++) {
    const date = moment().subtract(i, 'months');

    const value = date.format('YYYY-MM');
    const label = date.format('MMMM YYYY');
    const option: Option = {
      value,
      label,
    };

    // if (date.isBefore(moment(minDate))) {
    //   option.disabled = true;
    // }

    monthOptionsArr.push(option);
  }

  return monthOptionsArr;
};
