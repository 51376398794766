import React from 'react';
import { encodeValue } from '../../../common/utils/persist-time-filter';
import { GenericObject } from '../../../types';
import { isCharacterALetter, isCharacterANumber } from '../../../utils';
import { accountTypeMatcher } from '../../../utils/regex';
import { FetchMessagingApiResponse, InboxMessagesUI } from '../types';
import moment from 'moment-timezone';
import { commentFilterKeys } from '../../dashboard/constants';
import { sessionStartTypes } from '../constants';

export const getProfilePicPlaceholderProps = (displayName?: string, id?: string) => {
  let fallBackImgSrc;
  let customTextForFallback;

  if (displayName && typeof displayName === 'string') {
    if (displayName[0] && isCharacterALetter(displayName[0])) {
      customTextForFallback = displayName[0];
    } else {
      const removedAccountTypeIndicator = displayName.replace(accountTypeMatcher, '').trim();
      customTextForFallback = removedAccountTypeIndicator[0];
    }
  } else if (id) {
    if (isCharacterANumber(id)) {
      customTextForFallback = id[0] + id[1] + id[2] + id[3];
    }
  } else {
    fallBackImgSrc = '/images/icons/profile-placeholder.svg';
  }
  return {
    customTextForFallback,
    fallBackImgSrc,
  };
};
export const manipulateData = (data: any) => {
  let result: any = [];
  if (data) {
    result = Object.entries(data).map(([key, value]: any) => {
      return { key, value };
    });
  }
  return result;
};

export const isBulkActionMode = (selectedMessages: any) => {
  return Boolean(Object.keys(selectedMessages)?.length);
};
export const getShortenName = (name: string) => {
  let val = '';
  if (name.length > 14) {
    val = name.slice(0, 14) + '...';
  } else {
    val = name;
  }
  return val;
};

export const getFilteredSelectedMessages = (allSelectedMessages: GenericObject, messagesIncurrentTab: InboxMessagesUI) => {
  const filteredSelectedMessagesToAPI: FetchMessagingApiResponse = [];
  const filteredSelectedMessages = Object.keys(allSelectedMessages)
    .filter((key: string) => {
      return messagesIncurrentTab[key];
    })
    .map((key: string) => {
      filteredSelectedMessagesToAPI.push(messagesIncurrentTab[key].messageInbox);
      return messagesIncurrentTab[key];
    });
  return { filteredSelectedMessagesCount: filteredSelectedMessagesToAPI?.length, filteredSelectedMessagesToAPI, filteredSelectedMessages };
};

export const IsDocumentVisible = () => {
  return !document['hidden'];
};

export const checkValueInOptionGroups = (value: string = '', optGroups: any[] = []) => {
  return (
    Array.isArray(optGroups) &&
    optGroups?.some((eachOptGroup: any) => Array.isArray(eachOptGroup?.options) && eachOptGroup?.options?.some((option: any) => option?.value === value))
  );
};

export const errorMessage = (conversationData: any) => {
  const errorPayload = JSON.parse(conversationData?.rendererResponse?.payload);
  const error: any = {};
  if (conversationData?.rendererResponse?.error) {
    error.code = errorPayload?.payload?.code;
    error.subCode = errorPayload?.payload?.error_subcode;
    error.msg = 'Message send failed';
  } else if (conversationData?.deliveryResponse?.payload) {
    error.code = conversationData?.deliveryResponse?.payload?.code;
    error.subCode = conversationData?.deliveryResponse?.payload?.error_subcode;
    error.msg = conversationData?.deliveryResponse?.payload?.errorMessage || 'Message delivery failed.';
  }

  return (
    <div>
      {error?.code && (
        <p>
          <strong>Error code: </strong>
          {error?.code}
        </p>
      )}
      {error?.subCode && (
        <p>
          <strong>Error subcode: </strong>
          {error?.subCode}
        </p>
      )}
      <p>
        <strong>Error msg: </strong>
        {error.msg}
      </p>
    </div>
  );
};

export const redirectLink = (history: any, sessionData: any, timeZone: any) => {
  const timeStamp = moment(sessionData?.privateReplyResponses?.lastPrivateReply?.timestamp).tz(timeZone);
  const tagForQueryParam = encodeValue({
    tabName: 'Total',
    tag: [],
    time: 'customRange',
    posts: [],
    customFilter: sessionData?.privateReplyResponses?.lastPrivateReply?.commentId,
    startDate: timeStamp,
    endDate: timeStamp,
    timeZone,
    filterBy: commentFilterKeys.COMMENTID,
  });
  history.push({
    pathname: `./messages/comments/moderation`,
    search: `filters=${tagForQueryParam}`,
  });
};

export const isSessionValid = (sessionData: any) => {
  const hasLastPrivateReply = sessionData?.privateReplyResponses?.hasOwnProperty('lastPrivateReply');
  if (!sessionData?.sessionHistory || sessionData.sessionHistory.length === 0) {
    return hasLastPrivateReply && sessionData?.sessionStartType === sessionStartTypes.COMMENT_RESPONSE;
  }
  const hasCommentResponseInHistory = sessionData.sessionHistory.some((session: any) => session?.sessionStartType === sessionStartTypes.COMMENT_RESPONSE);
  return hasLastPrivateReply && hasCommentResponseInHistory;
};
