import { RoutesMap } from '../../constants/index';
export const UnAuthorizedErrorRedirecting: string = 'Your session has expired! Redirecting to login.';
export const NotAuthorized: string = 'You do not have permission to perform this action.';
const templatesHelp = {
  text: 'Templates allow you to create and customize reusable replies.',
  helpLink: 'https://amplifyai.zendesk.com/hc/en-us/categories/1500000902742-Templates',
};
const dashboardHelp = {
  text: 'Amplify dashboard provides the user a comprehensive view of the comment activity on any specific Facebook Page or Instagram Business Account.',
  helpLink: 'https://amplifyai.zendesk.com/hc/en-us/categories/1500000238622-Dashboard',
};
const inboxHelp = {
  text: 'Inbox allows user to view the SMS received or sent.',
  helpLink: 'https://amplifyai.zendesk.com/hc/en-us/categories/4404986308371-Inbox',
};
const commentModerationHelp = {
  text: 'The comment inbox is a window that opens up when you tap on the dashboard. The inbox organizes comments into tabs that correspond to the metrics shown on the dashboard',
  helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/360063012273-What-is-comment-inbox-and-what-can-I-do-with-that-tool-',
};
const broadcastHelp = {
  text: 'Broadcast allows to send SMS or MMS to users who have engaged with the bot.',
  helpLink: 'https://amplifyai.zendesk.com/hc/en-us/categories/4411175583379-Broadcasts-Beta',
};
const broadcastCreateEditHelp = {
  text: 'Broadcast allows to send SMS or MMS to users who have engaged with the bot.',
  helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/4411183632019-How-to-send-a-Broadcast-',
};
export const pageIdHelp = {
  text: 'How to find page ID and accept page request?',
  helpLink: 'https://support.amplify.ai/hc/en-us/articles/25545614810643-How-to-find-Facebook-Page-Id',
};
export const PageDescriptionRouteMap = {
  [RoutesMap.DASHBOARD]: dashboardHelp,
  [RoutesMap.RECENT_BROADCASTS]: broadcastHelp,
  [RoutesMap.DRAFT_BROADCASTS]: broadcastHelp,
  [RoutesMap.SCHEDULED_BROADCASTS]: broadcastHelp,
  [RoutesMap.REPEAT_BROADCASTS]: broadcastHelp,
  [RoutesMap.ARCHIVED_BROADCASTS]: broadcastHelp,
  [RoutesMap.EDIT_BROADCAST]: broadcastCreateEditHelp,
  [RoutesMap.VIEW_BROADCAST]: broadcastHelp,
  [RoutesMap.CREATE_BROADCAST]: broadcastCreateEditHelp,
  [RoutesMap.INBOX]: inboxHelp,
  [RoutesMap.TAGS]: {
    text: 'Tags feature allows you to add various conditions (rules) to identify & label comments. One of the powerful capabilities of Tags is to also add suggested actions such as hide, like, public reply, delete, etc. to filter through comments.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/categories/360006232013-Tags-',
  },
  [RoutesMap.COMMENTS_ENGAGEMENTS]: commentModerationHelp,
  [RoutesMap.COMMENTS_MODERATION]: commentModerationHelp,
  [RoutesMap.MENTION_ENGAGEMENTS]: commentModerationHelp,
  [RoutesMap.MENTION_MODERATION]: commentModerationHelp,
  [RoutesMap.KEYWORD_GROUPS]: {
    text: 'Keyword Groups provide a way to organize words expressed by your customers either related to your brand or products or services. This provides our AI engine to label your comments when specific words occur in comments.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/categories/360005172634-Keyword-Groups',
  },
  [RoutesMap.MODERATION]: {
    text: `Moderation is a feature wherein you can set the system to automatically hide, delete comments and ban user based on certain set rules. This enables you to automatically hide, delete or ban user with those comments which can be negative or hostile or even represent competitor's brand name.`,
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/sections/360012685193-Moderation',
  },
  [RoutesMap.BRAND]: {
    text: `Brand Lift feature generates a automated like reaction to user's comments.`,
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/sections/1500000475682-Brand-Lift',
  },
  [RoutesMap.SMART]: {
    text: 'Smart Replies are the automated public responses which can be configured to auto-respond to the user comments publicly over the same thread without manually replying to the comments.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/sections/1500000433381-Smart-Replies',
  },
  [RoutesMap.GETTING_STARTED]: {
    text: 'Welcome to Amplify, the first AI-driven comment moderation solution that enables you to do social brand defense at a superhuman scale. This product enables you to manage your social customer care workflows by quickly identifying comments that need your attention either from paid or organic posts.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/categories/360005157834-Getting-Started',
  },
  [RoutesMap.COMMENT_VIEWER]: {
    text: 'The comment inbox is a window that opens up when you tap on the dashboard. The inbox organizes comments into tabs that correspond to the metrics shown on the dashboard',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/360063012273-What-is-comment-inbox-and-what-can-I-do-with-that-tool-',
  },
  [RoutesMap.SOCIAL_MEDIA_ACCOUNTS]: {
    text: 'Link your Facebook/Instagram pages with Amplify Lite effortlessly and efficiently.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500002637201-How-to-connect-your-social-media-accounts-',
  },
  [RoutesMap.INVITE_ADD_TEAM_MEMBERS]: {
    text: 'As an Account Owner of an account, you can add users in Amplify. You can add multiple people as admins.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/360061335934-How-to-invite-or-add-other-team-members-',
  },
  [RoutesMap.PROFILE]: {
    text: 'You can update your user profile by following the steps given in the below URL.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500002721181-How-to-update-my-user-profile-and-account-settings-',
  },
  [RoutesMap.ACCOUNT_SETTINGS]: {
    text: 'You can update your account settings by following the steps given in the below URL.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500002721181-How-to-update-my-user-profile-and-account-settings-',
  },
  [RoutesMap.CREATE_USER]: {
    text: 'You can add other team members by following the steps given in the below URL.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/360061335934-How-to-invite-or-add-other-team-members-',
  },
  [RoutesMap.CREATE_TAG]: {
    text: 'You can easily create custom tags based on what you observe in terms of daily trends, issues your social care likes to tackle, or any activity in your feed that creates a business threat to your brand.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/360061262114-How-can-I-create-Custom-tags',
  },

  [RoutesMap.CREATE_KEYWORD_GROUP]: {
    text: 'Custom groups provide the functionality wherein you can create a custom group of your choice with a specific name and can train the system to identify the comments and label with the keywords associated with the group.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500002933821-How-to-create-custom-groups-and-add-keywords-',
  },
  [RoutesMap.CREATE_BRAND_LIFT]: {
    text: 'If you wish to create a custom brand lift for specific set of comments you can choose custom campaign feature available under brand lift which enables you to target a specific tag and like those comments associated with the tag.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500003190982-Setting-up-Custom-Campaign-Brand-Lift',
  },
  [RoutesMap.CREATE_MODERATION]: {
    text: 'After you have spent some time studying the pattern of comments pouring in on your post, there might be a need of a custom campaign which you wish to create to perform actions (Hide/Delete/Ban user) on a certain set of comments.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500003033261-Setting-up-Custom-Campaign',
  },
  [RoutesMap.CREATE_SMART_REPLIES]: {
    text: 'If you wish to create a custom smart reply for specific set of comments you can choose custom campaign feature available under smart replies which enables you to target a specific tag and post public response to comments associated with the tag.',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500003189842-Setting-up-Custom-Campaign-Smart-Replies',
  },
  [RoutesMap.TEMPLATES]: templatesHelp,
  [RoutesMap.CREATE_TEMPLATE]: templatesHelp,
  [RoutesMap.EDIT_TEMPLATE]: templatesHelp,
  [RoutesMap.REPORTS]: {
    text: 'Reports show the most recent 10 reports that were sent to you',
    helpLink: 'https://amplifyai.zendesk.com/hc/en-us/articles/1500002637341-Daily-Reports',
  },
};

export const uiConfigSettingsConstants = {
  enableDefaultGlobalTargeting: {
    helpText: 'It will enable global targeting while creating a broadcast. When it is disabled then countryCode equals to US targeting will be applied while creating a broadcast.',
    title: 'Enable Default Global targeting',
    label: 'Default Global Targeting',
    type: 'boolean',
  },
  disableSendAsMms: {
    helpText: 'Hides the "Send as MMS" feature while creating or editing broadcast.',
    title: 'Disable Send as Mms',
    label: 'Disable Send as Mms',
    type: 'boolean',
  },
  convertMMSToSMSWithLink: {
    helpText: '',
    title: 'Convert MMS to SMS',
    label: 'Convert MMS to SMS',
    type: 'boolean',
  },
  enableAssignMe: {
    helpText: 'Enabling this allows User Assignment feature in the Inbox',
    title: 'Enable Inbox Assignment',
    label: 'Inbox Assignment',
    type: 'boolean',
  },
  appendOptOutText: {
    helpText: 'It will be appended to the broadcast message and it will impact the message length and segment count.',
    title: 'Enter Text to Opt-out',
    label: 'Opt-out Text',
    type: 'string',
  },
  showNotificationBroadcastOnCM: {
    helpText: 'It will enable the display of marketing broadcasts.',
    title: 'Display Marketing Messages Broadcast',
    label: 'Show Marketing Messages Broadcast',
    type: 'boolean',
  },
  showBroadcastDonation: {
    helpText: 'This will enable display of broadcast donation details.',
    title: 'Display Broadcast Donation details',
    label: 'Show Broadcast Donation details',
    type: 'boolean',
  },
  showPopupQR: {
    helpText: 'This will enable the display of QR on the popup to contact.',
    title: 'Display Popup with QR',
    label: 'Show Popup with QR to contact',
    type: 'boolean',
  },
  showCarrierFees: {
    helpText: 'This will hide the carriers charge information',
    title: 'Hide Carrier Fees',
    label: 'Hide Carrier Fees',
    type: 'boolean',
  },
  enableBroadcastCaptureUserInputAndVariables: {
    helpText: 'This will enable the capture user input and set user variables advanced options for broadcasts.',
    title: 'Enable Broadcast Capture User Input and Variables',
    label: 'Enable Broadcast Capture User Input and Variables',
    type: 'boolean',
  },
};

export const UserActivityKey = 'USER_ACTIVITY';
export const LogActivityTimeLimit = 10 * 60 * 1000; // 10 Minutes

export const dateToCheckForBroadcastToFetchClickDataFromClicksIndex = '2023-10-01T23:08:57.819Z';

export const countryNamesAndValuesForBroadcastTargeting = {
  US: {
    name: 'United States',
    value: 'US',
  },
  ALL: {
    name: 'All',
    value: 'ALL',
  },
};
