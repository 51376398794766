import { AccountInfoInputFields, BotMacros, PopupAPI } from './../settings.types';
import { Account, BotProfile } from '../../../common/entities/entities.types';
import { DynamicFieldUI } from '../settings.types';
import { fbMeURL, IgMeURL } from 'src/pages/broadcasts/constants';
import { platforms } from 'src/common/entities/entities.constants';
import { getUserNameFromPlatformObj } from 'src/pages/user-profile-pages/components/social-media-accounts/utils';

export const getsegregatedSettings = (settings?: { [key: string]: DynamicFieldUI }) => {
  const settingsArr = Object.values(settings || {});
  const adminSettings: DynamicFieldUI[] = [];
  const nonAdminSettings: DynamicFieldUI[] = [];
  settingsArr.forEach((setting: DynamicFieldUI) => (setting.isAdmin ? adminSettings.push(setting) : nonAdminSettings.push(setting)));
  return { adminSettings, nonAdminSettings };
};

export const validateProfileDetails = (profileDetails: BotProfile) => {
  if (!profileDetails.name) {
    return false;
  }
  return true;
};

export const getPopupConfigForPreview = (popup: PopupAPI | null, profileDetails: BotProfile) =>
  popup
    ? {
        logo: '/images/amplify-logo.png',
        popups: {
          [popup.popUpName]: {
            ...popup,
            popUpName: profileDetails.name,
            logo: profileDetails.profilePic,
            regExEnabled: true,
            popupStateConfig: {
              ...popup?.popupStateConfig,
              expand: {
                ...popup?.popupStateConfig?.expand,
                autoClick: false, //disable autoClick for preview mode(avoids opening contacts while previewing)
              },
            },
          },
        },
        targeting: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'sms.html': [{ popupId: popup.popUpName }],
        },
      }
    : {};

export const identifyCliqzPopups = (
  allPopups: PopupAPI[] = [],
  { popupNameFromBot, popupId, botMacrosDefault }: { botMacrosDefault?: BotMacros; popupNameFromBot?: string; popupId?: string },
) => {
  const popupNameFromMacros = botMacrosDefault?.BRAND || popupNameFromBot;
  const popupIdFromMacros = botMacrosDefault?.POPUPID || popupId;
  if (popupIdFromMacros) {
    return allPopups.filter((popup: PopupAPI) => popup.id === popupIdFromMacros);
  } else if (popupNameFromMacros) {
    return allPopups.filter((popup: PopupAPI) => (popup.name === popupNameFromMacros || popup.subType === 'cliqz') && popup.deployed);
  }
};

export const trimExtraWhiteSpace = (str: string = '') => str.replace(/\s+/g, ' ').trim();

export const extractFirstLastName = (fullName: string = '') => {
  const name: string = trimExtraWhiteSpace(fullName);
  const lastSpaceIndex = name.lastIndexOf(' ');
  const lastName = lastSpaceIndex > -1 ? name.slice(lastSpaceIndex + 1) : '';
  const naming = { firstName: lastName ? name.slice(0, lastSpaceIndex) : name, lastName };
  return naming;
};

export const getContrastColor = (hexColor?: string): string => {
  if (!hexColor) return '';
  const r: number = parseInt(hexColor.substring(1, 3), 16);
  const g: number = parseInt(hexColor.substring(3, 5), 16);
  const b: number = parseInt(hexColor.substring(5, 7), 16);

  const rNorm: number = r / 255;
  const gNorm: number = g / 255;
  const bNorm: number = b / 255;

  const luminance: number = 0.2126 * rNorm + 0.7152 * gNorm + 0.0722 * bNorm;

  return luminance > 0.5 ? '#000000' : '#ffffff';
};

export const getInboxUrl = (platformBotObj: any) => {
  const platform = platformBotObj?.platform;
  const userName = getUserNameFromPlatformObj(platformBotObj);
  if (platform === platforms.FACEBOOK) {
    return platformBotObj?.platformBotId ? `${fbMeURL}${platformBotObj.platformBotId}` : undefined;
  }
  return userName ? `${IgMeURL}${userName}` : undefined;
};

export const getMetaUserFields = (account?: Account, bot?: any, platformBotObj?: any): AccountInfoInputFields[] => {
  const inboxUrl = platformBotObj ? getInboxUrl(platformBotObj) : undefined;

  return [
    {
      label: 'Account Name',
      value: account?.name,
      showIf: true,
    },
    {
      label: 'Bot Name',
      value: bot?.name,
      showIf: true,
    },
    {
      label: 'Bot Description',
      value: bot?.description,
      showIf: !!bot?.description,
    },
    {
      label: 'Platform Bot ID',
      value: platformBotObj?.platformBotId,
      showIf: true,
    },
    {
      label: 'Inbox URL',
      value: inboxUrl,
      showIf: true,
    },
  ];
};
