/* eslint-disable @typescript-eslint/naming-convention */
import { Amplitude as AmplitudeConfig } from '../config';
import amplitude from 'amplitude-js';
import { AmplitudeEventProps } from '../types';
import * as eventConst from './event-constants';
import { processType, steps } from '../pages/ingest-list/constants';

const AmplitudeInstance = amplitude.getInstance();
AmplitudeInstance.init(AmplitudeConfig.apiKey as string);

/* eslint-disable max-lines-per-function */
export const RegisterAmplitudeAnalytics = () => {
  return {
    logIn: () => {
      AmplitudeInstance.logEvent('login', { method: 'Email' });
      AmplitudeInstance.logEvent('user_id_available');
    },
    signUp: () => {
      AmplitudeInstance.logEvent('sign_up', { method: 'Email' });
    },
    signUpFailed: () => {
      AmplitudeInstance.logEvent('sign_up_failed', { method: 'Email' });
    },
    userMenuCLick: () => {
      AmplitudeInstance.logEvent('click_top_menu');
    },
    onCommentTabView: (tabName: string, eventProps: AmplitudeEventProps) => {
      // replace / and space with underscore.
      tabName = tabName.replaceAll(/\//g, '_').replaceAll(/\s/g, '_');
      AmplitudeInstance.logEvent(`click_comment_inbox_${tabName}_tab`, {
        ...eventProps,
      });
    },
    onDashboardCommentSectionClick: (tabName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('click_dashboard_comment_section', {
        section_name: `${tabName}`,
        ...eventProps,
      });
    },
    commentSearch: (searchType: string, searchValue: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('search_comments', {
        search_value: searchValue,
        search_type: searchType,
        ...eventProps,
      });
    },
    unlinkAPage: (pageName: string) => {
      AmplitudeInstance.logEvent('click_unlink_page', {
        page_name: pageName,
      });
    },
    readyToAmplify: () => {
      AmplitudeInstance.logEvent('click_ready_to_amplify');
    },
    connectSocialMedia: (socialMediaName: string) => {
      AmplitudeInstance.logEvent(`click_connect_to_${socialMediaName}`);
    },
    actionOnComments: (actionType: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`comment_action_${actionType}`, {
        ...eventProps,
      });
    },
    handleHomeConnectTOFB: () => {
      AmplitudeInstance.logEvent(`click_connect_to_fb_home`);
    },
    toggleTagStatus: (tagStatus: boolean, tagName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`${tagStatus ? 'enable' : 'disable'}_tag`, {
        tag_name: tagName,
        ...eventProps,
      });
    },
    toggleCampaignStatus: (campaignStatus: boolean, campaignName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`${campaignStatus ? 'enable' : 'disable'}_campaign`, {
        campaign_name: campaignName,
        ...eventProps,
      });
    },
    createTag: (tagName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`create_tag`, {
        tag_name: tagName,
        ...eventProps,
      });
    },
    updateTag: (tagName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`update_tag`, {
        tag_name: tagName,
        ...eventProps,
      });
    },
    createCampaign: (campaignName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`create_campaign`, {
        campaign_name: campaignName,
        ...eventProps,
      });
    },
    updateCampaign: (campaignName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`update_campaign`, {
        campaign_name: campaignName,
        ...eventProps,
      });
    },
    createKeywordGroup: (keywordName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`create_keyword`, {
        keyword_name: keywordName,
        ...eventProps,
      });
    },
    manageKeywordGroup: (keywordName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`manage_keyword`, {
        keyword_name: keywordName,
        ...eventProps,
      });
    },
    trainedButtonClicked: (eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`click_train_button`, {
        ...eventProps,
      });
    },
    dateRangeClicks: (pageSection: string, selectedDateRage: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`click_date_range`, {
        page_section: pageSection,
        selected_date_range: selectedDateRage,
        ...eventProps,
      });
    },
    clickGoToComment: (url: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`go_to_comment_url`, {
        comment_url: url,
        ...eventProps,
      });
    },
    setAccountUserIdInConfig: (accountId: string, userId: string, externalUser: boolean) => {
      AmplitudeInstance.setUserProperties({
        account_id: accountId,
        user_id: userId,
        external_user: externalUser,
      });
      AmplitudeInstance.setUserId(userId);
    },
    createTemplate: (templateName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('create_template', {
        template_name: templateName,
        ...eventProps,
      });
    },
    updateTemplate: (templateName: string, templateId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('update_template', {
        template_name: templateName,
        template_id: templateId,
        ...eventProps,
      });
    },
    deleteTemplate: (templateName: string, templateId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('delete_template', {
        template_name: templateName,
        template_id: templateId,
        ...eventProps,
      });
    },
    createBroadcast: (broadcastName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('create_broadcast', {
        broadcast_name: broadcastName,
        ...eventProps,
      });
    },
    updateBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('update_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    archiveBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('archive_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    activateBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('activate_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    testBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('test_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    viewBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('view_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    clickReportDownload: (reportName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('click_report_download', {
        report_name: reportName,
        ...eventProps,
      });
    },
    reportEmailSent: (reportName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('report_sent_via_email', {
        report_name: reportName,
        ...eventProps,
      });
    },
    inboxMessageMoved: (fromTab: string, toTab: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('inbox_message_moved', {
        from_tab: fromTab,
        to_tab: toTab,
        ...eventProps,
      });
    },
    inboxMessageStatusChanged: (fromStatus: string, toStatus: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('inbox_message_status_changed', {
        from_status: fromStatus,
        to_status: toStatus,
        ...eventProps,
      });
    },
    messageReplied: (repliedTo: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('message_replied', {
        replied_to: repliedTo,
        ...eventProps,
      });
    },
    inboxMessageLabelAdded: (labelName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('inbox_message_Label_added', {
        label_name: labelName,
        ...eventProps,
      });
    },
    inboxMessageLabelRemoved: (labelName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('inbox_message_Label_removed', {
        label_name: labelName,
        ...eventProps,
      });
    },
    bulkActionTaken: (messageIds: string, actionType: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent('message_bulk_action_taken', {
        message_ids: messageIds,
        action_type: actionType,
        ...eventProps,
      });
    },
    createSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`create_segment`, {
        segment_name: segmentName,
        ...eventProps,
      });
    },
    updateSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`update_segment`, {
        segment_name: segmentName,
        ...eventProps,
      });
    },
    deleteSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`delete_segment`, {
        segment_name: segmentName,
        ...eventProps,
      });
    },
    fetchInboxMessages: (pageNumber: number, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.FETCH_MESSAGE_INBOX, {
        page_number: pageNumber,
        ...eventProps,
      });
    },
    createInboxMessageLabel: (labelName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.CREATE_MESSAGE_INBOX_LABEL, {
        label_name: labelName,
        ...eventProps,
      });
    },
    showSessionsBtnClicked: (eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.SHOW_SESSIONS_CLICKED, {
        ...eventProps,
      });
    },
    inboxManageLabelClicked: (eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.SHOW_MANAGE_LABEL_CLICKED, {
        ...eventProps,
      });
    },
    viewConversation: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.VIEW_CONVERSATION, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    platformUserUpdated: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.INBOX_PLATFORM_USER_UPDATED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    platformUserSubscribed: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.INBOX_PLATFORM_USER_SUBSCRIBED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    platformUserUnsubscribed: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.INBOX_PLATFORM_USER_UNSUBSCRIBED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    inboxUserDetailsClicked: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.INBOX_USER_DETAILS_CLICKED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    inboxShowVariablesClicked: (eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(eventConst.INBOX_SHOW_VARIABLES_CLICKED, {
        ...eventProps,
      });
    },
    audienceFiltersApplied: (
      isClearingFilters: boolean,
      eventProps: AmplitudeEventProps,
      countries: string,
      states: string,
      cities: string,
      genders: string,
      ages: string,
      labels: string,
      locales: string,
      timezones: string,
    ) => {
      if (countries || states || cities || genders || ages || labels || locales || timezones) {
        AmplitudeInstance.logEvent(`audience_filters_applied`, {
          ...eventProps,
          ...(countries.length ? { countries } : {}),
          ...(states.length ? { states } : {}),
          ...(cities.length ? { cities } : {}),
          ...(genders.length ? { genders } : {}),
          ...(ages ? { ages } : {}),
          ...(labels.length ? { labels } : {}),
          ...(locales.length ? { locales } : {}),
          ...(timezones.length ? { timezones } : {}),
        });
      } else if (isClearingFilters) {
        AmplitudeInstance.logEvent(`audience_clear_filter`, {
          ...eventProps,
        });
      }
    },
    audienceFansByModalOpen: (category: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_fans_by_${category}_modal_opened`, {
        ...eventProps,
      });
    },
    audienceTabView: (tabName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_${tabName}_tab_view`, {
        ...eventProps,
      });
    },
    audienceToggleUnavailabilityStatus: (isUnavailableHidden: boolean, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_show_data_unavailable`, {
        ...eventProps,
        showUnavailableData: isUnavailableHidden,
      });
    },
    audienceSubscriptionDateChanged: (from: string, to: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_subscription_date_changed`, {
        ...eventProps,
        from,
        to,
      });
    },
    audienceCreateSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_create_segment`, {
        ...eventProps,
        segment_name: segmentName,
      });
    },
    audienceGoToInbox: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_goto_inbox_clicked`, {
        ...eventProps,
        platformUserId,
      });
    },
    audienceSubscriptionChange: (platformUserId: string, isUnsubcribeEvent: boolean, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(isUnsubcribeEvent ? `audience_unsubscribe_clicked` : `audience_subscribe_clicked`, {
        ...eventProps,
        platformUserId,
      });
    },
    audienceModifyNewMemberLabels: (platformUserId: string, label: string, isAdd: boolean, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(isAdd ? `audience_custom_label_added` : `audience_custom_label_removed`, {
        ...eventProps,
        platformUserId,
        label,
      });
    },
    audienceAllNewMemberSelected: (pageName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_select_all_new_members_clicked`, {
        ...eventProps,
        pageName,
      });
    },
    audienceNewMemberClicked: (platformUserId: string, pageName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_select_new_member_clicked`, {
        ...eventProps,
        pageName,
        platformUserId,
      });
    },
    audienceSendMessageClicked: (pageName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_send_message_button_clicked`, {
        ...eventProps,
        pageName,
      });
    },
    audienceMessageSent: (platformUserIds: string, pageName: string, eventProps: AmplitudeEventProps) => {
      AmplitudeInstance.logEvent(`audience_message_sent`, {
        ...eventProps,
        pageName,
        platformUserIds,
      });
    },
    handleIngestClickList: () => {
      AmplitudeInstance.logEvent('ingest_list_click', {});
    },
    downloadStandardTemplate: (key: string) => {
      if (key === processType.STANDARD) {
        AmplitudeInstance.logEvent('ingest_downloaded_cliqzStandard_template', {});
      } else if (key === 'CommunityComplete') {
        AmplitudeInstance.logEvent('ingest_downloaded_communityComplete_template', {});
      } else if (key === processType.COMMUNITY) {
        AmplitudeInstance.logEvent('ingest_downloaded_community_template', {});
      }
    },
    ingestSteps: (stepkey: string) => {
      if (stepkey === steps.FILE_UPLOAD) {
        AmplitudeInstance.logEvent('ingest__step1', {});
      } else if (stepkey === steps.SEGMENT_CREATION) {
        AmplitudeInstance.logEvent('ingest_step2', {});
      } else if (stepkey === steps.FEILD_MAPPING) {
        AmplitudeInstance.logEvent('ingest_step3', {});
      } else if (stepkey === steps.FILE_PREVIEW) {
        AmplitudeInstance.logEvent('ingest_step4', {});
      }
    },
    handleClickDoneIngest: () => {
      AmplitudeInstance.logEvent('ingest_user_clicked_done', {});
    },
    handleViewClickIngest: () => {
      AmplitudeInstance.logEvent('ingest_user_clicked_view');
    },
    handleRequestAccess: (pageId: string) => {
      AmplitudeInstance.logEvent('request_page_access_sent', {
        pageName: pageId,
      });
    },
    handleCancelRequest: (id?: string) => {
      AmplitudeInstance.logEvent('request_page_access_cancel', { id });
    },
    addSystemUser: (id?: string) => {
      AmplitudeInstance.logEvent('request_page_access_add_system_user', { id });
    },
  };
};
