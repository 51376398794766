/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTestUsersToBot, deleteBroadcastFromSore, setLatestBroadcastTime, storeBroadcast } from '../../../common/entities/actions';
import {
  useCurrentBot,
  useTimeZone,
  useAmplitudeEventProps,
  useCurrentPlatformBotObj,
  useCurrentUser,
  useAllUsers,
  useIds,
  useCurrentAccount,
  useIsWhatsAppPlatform,
  useVariables,
} from '../../../hooks';
import { v4 as uuid } from 'uuid';
import { Dispatch, RootState } from '../../../types';
import { showMessage } from '../../common/toast-notification';
import { setMedia } from '../../dashboard/components/comments-viewer/actions';
import { TemplateMessageError } from '../../templates/templates.types';
import {
  createBroadcast,
  fetchAudienceForBot,
  fetchEntityAuditLogs,
  pauseResumeBroadcast,
  sendTestBroadcastMessages,
  updateBroadcast,
  fetchNotificationLabelsForBotVersion,
} from '../actions/async-actions';
import { broadcastsToUI, cloneBroadcastUiToApi, createBroadcastUiToApi, updateBroadcastUiToApi } from '../transformer';
import {
  BroadcastTypeAPI,
  BroadcastTypeUI,
  UserCountUpdated,
  SelectedSegments,
  MetricsBroadcastMessageData,
  TestBroadcastStateProps,
  broadcastEngagementSettingsTypeUI,
  UseRelativeTimeOptions,
} from '../types';
import { Bot, VariableType } from '../../../common/entities/entities.types';
import moment, { Moment } from 'moment';
import {
  broadcastConstants,
  broadcastScheduleConst,
  broadcastTypes,
  createbraodcastSegmentCountArea,
  searchByOptionsForTest,
  searchByOptionsForTestPhone,
  broadcastEngagementPages,
  elasticKeysMap,
  apiIndexVariablesMap,
  broadcastToastMsgTimeStampInSeconds,
  timezoneTypes,
  usNumbersCondition,
  donationUiVariables,
  broadcastTypeLabels,
  segmentCostUiVariables,
  // apiIndexVariablesMap,
} from '../constants';
import { testUsingPhoneNumberBotPlatforms } from '../../../config/platform-action-map';
import { ALLCONSTANT, botPlatformApiToUIMapper } from '../../automation/constants';
import { fetchTargetingUsers } from '../../../common/entities/actions/broadcast';
import { formattedPhoneInput, getAccountEngagementSettingsForDonation, getEngagementSettingsForSegmentCost } from '../../../utils';
import { useHistory } from 'react-router';
import { registerAmplifyAnalytics } from '../../../analytics/utils';
import { setTestBroadcastSent } from '../actions';
import {
  checkIfAdvancedSegmentsValid,
  checkIfScheduledForPast,
  checkIfWaTemplateMacroValid,
  checkPlatformInstaFB,
  detectInvalidURL,
  getBroadcastTypeForAPI,
  getIncludeExcludeSegmentsIds,
  getNodeIdsFromBroadcast,
  getSegmentsAsOptions,
  getSegmentsChildConditions,
  getAdvancedSegmentConditionsWithoutIds,
  getSegregatedBroadcasts,
  isBroadcastSentWithin5Min,
  removeAppendedText,
  removeShortURLTrackingFromText,
  getDonationConfigIfHidden,
  getNotificationLabelConditions,
  getSubscriptionBroadcastConditions,
  getSegmentTagsAsOptions,
  getTagIdGroupNameAsKey,
  migrateBroadcast,
  extractLocalVariablesFromBroadcasts,
  detectInvalidActBlueURL,
  getUserVariablesData,
} from '../utils';
import { fetchSegmentCount, fetchSegments, getUserSegments } from '../../../common/entities/actions/segments.async-actions';
import { CommentsFilterKeysInsideFilter, pageFilterKeys, pageKeysForFilter } from '../../dashboard/constants';
import { broadcastSubPagesForMobile } from '../../../mobile/config';
import { useMobileNavigation } from '../../../mobile/hooks';
import { setFilter } from '../../../common/page-ui-state/actions';
import { ActiveSubTabProps } from '../../../mobile/types';
import _, { uniqBy } from 'lodash';
import { getBucketLimit, getTargetedUsers } from '../../../common/entities/utils';
import { Segments, segmentUserCountAPIRequest } from '../../segments/segment.types';
import { trackPromise } from 'react-promise-tracker';
import { GCChildCondition } from '../../../types/api-calls';
import { platforms } from '../../../common/entities/entities.constants';
import { checkUserSubscriptions } from '../../user-profile-pages/actions/async-actions';
import { useIsCliqzTypeBotConfig } from '../../user-profile-pages/hooks';
import { fetchAnalyticsOther, fetchModules } from '../../../common/entities/actions/broadcasts.async-actions';
import { BroadcastPageContext } from '../context';
import { encodeValue } from '../../../common/utils/persist-time-filter';
import { useIsMobile } from '../../../mobile/utils';
import { unicode2html } from '../../../common/utils/unicodeTransforms';
import { DEFAULT_OPT_OUT_TEXT } from '../config';
import { combinedRegexForVars } from '../../../utils/regex';
import { AddCommaSeparator, humanizeNumberWithComma } from '../../../common/utils/humanize';
import { fetchSegmentTags } from '../../../common/entities/actions/segmentTags.async-actions';
import { getTransformedScheduleStartDate } from '../../../common/utils';
import { countryNamesAndValuesForBroadcastTargeting } from 'src/common/constants';

export const useBroadcasts = () => {
  return useSelector((state: RootState) => state.entities.broadcasts);
};

export const useMessageUsage = () => {
  return useSelector((state: RootState) => state.broadcastPage.messageUsage);
};

export const useImageModuleDetails = () => {
  const dispatch = useDispatch();
  const currentBot: any = useCurrentBot() || {};
  const versionId = currentBot?.activeVersionId;
  const modules = useSelector((state: RootState) => state.entities.versions?.[versionId]?.modules);
  const moduleLength = Object.values(modules || {}).length;

  useEffect(() => {
    if (versionId && !moduleLength) {
      dispatch(fetchModules(versionId, true));
    }
  }, [modules]);
};

// type InitialState = {
//   currentName: string,
//   scheduleType: string
// };
// type ActionTypes = keyof InitialState | 'updateWith';

// function reducer(state: InitialState, action: { type: ActionTypes, payload: any }) {
//   switch (action.type) {
//     case 'currentName':
//       return { ...state, [action.type]: action.payload };
//     default:
//       throw new Error();
//   }
// }

export const useBroadcastFormFields = (defaultMessage?: string) => {
  const timezone = useTimeZone();
  const bot: any = useCurrentBot();
  const platformObj = useCurrentPlatformBotObj();
  const isFbInstaPlatform = checkPlatformInstaFB(platformObj?.platform);

  const currentTime = moment.tz(timezone);
  const name = currentTime.format('DD MMM YYYY, h.mm A');
  // const initialState = {
  //   currentName: name,
  //   scheduleType: 'immediate'
  // };
  const [currentName, setCurrentName] = useState<string>(name);
  const [enableClickTracking, setEnableClickTracking] = useState<boolean>(true);
  const [excludeOptoutText, setOptoutTextVisiblity] = useState<boolean>(true);
  const [broadcastType, setBroadcastType] = useState<string>(broadcastTypes.SUBSCRIPTION);
  const [labels, setLabels] = useState<string[] | string>([]);
  const [isRepeatBroadcast, setIsRepeatBroadcast] = useState<string>('None');
  const [responseType, setResponseType] = useState<string>(isFbInstaPlatform ? 'text' : 'sms');
  const [currentMessage, setCurrentMessage] = useState<string>(defaultMessage || '');
  const [modules, setModules] = useState<any[]>([]);
  const [isSegmentValid, setIsSegmentValid] = useState<boolean>(false);

  const [scheduleType, setScheduleType] = useState<string>(broadcastScheduleConst.NOW.value);
  const [startDate, setStartDate] = useState(currentTime);
  const [selectedSegmentIds, setSelectedSegmentIds] = useState<SelectedSegments>({ included: [ALLCONSTANT], excluded: [] });
  const [advancedSegmentConditions, setAdvancedSegmentConditions] = useState<any>(undefined);
  const [showScheduleDateWarning, setShowScheduleDateWarning] = useState(false);
  const [sendAsMMS, setSendAsMMS] = useState<boolean>(false);
  const [userVariableComponents, setUserVariableComponents] = useState<any[]>([]);
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [timeZoneType, setTimeZoneType] = useState<string>('bot');
  const [waTemplates, setWaTemplates] = useState<any[]>([]);
  const [selectedWaTemplate, setSelectedWaTemplate] = useState<any>();
  const platformAsInSystemSettings = platformObj?.platformAsInSystemSettings || '';
  const dispatch = useDispatch();
  const attachmentData = useSelector((state: RootState) => state.commentsViewer.comments.attachedMedia);
  const mediaUrl = attachmentData && attachmentData[0]?.mediaURL ? attachmentData[0].mediaURL : undefined;
  const appendTextValue = useSelector((state: RootState) => state.entities.account?.config?.uiConfig?.[platforms.TWILIO_SMS]?.appendOptOutText || DEFAULT_OPT_OUT_TEXT);
  const previousOptOutText = useSelector((state: RootState) => state.entities.account?.config?.uiConfig?.[platforms.TWILIO_SMS]?.previousOptOutText || DEFAULT_OPT_OUT_TEXT);
  const { isValid: isValidWaTemplate } = useCheckValidWaTemplate(selectedWaTemplate);
  const [isvalidAdvancedSegments, setIsValidAdvancedSegments] = useState<any>(true);
  const toSetAdvancedSegmentConditions = useCallback((val?: any) => {
    const hasAllValidSegmentConditions = checkIfAdvancedSegmentsValid(val);
    setIsValidAdvancedSegments(hasAllValidSegmentConditions);
    setAdvancedSegmentConditions(val);
  }, []);

  // const [{ currentName, scheduleType, }, dispatch] = useReducer(reducer, initialState);
  const isFormValid = () => {
    if (isFbInstaPlatform) {
      // we are not allowed to edit for FB/IG so we are not checking any validation.
      return true;
    }
    return isNameValid() &&
      checkIfAdvancedSegmentsValid(advancedSegmentConditions) &&
      (platformAsInSystemSettings !== platforms.WHATSAPP ? isMessageValid().isValid && isSegmentValid : isValidWaTemplate)
      ? true
      : false;
  };
  useEffect(() => {
    dispatch(setTestBroadcastSent(false));
  }, [sendAsMMS, enableClickTracking, currentName, currentMessage, scheduleType, mediaUrl, excludeOptoutText]);

  const isNameValid = () => {
    return currentName ? true : false;
  };
  const isMessageValid = (): TemplateMessageError => {
    let isValid = true;
    const errorMessage = '';
    if (!currentMessage && !modules.length) {
      isValid = false;
    }
    if (bot) {
      const { havingInValidURLs } = detectInvalidURL(currentMessage);
      const { havingInValidActBlueURLs } = detectInvalidActBlueURL(currentMessage);
      if (enableClickTracking && havingInValidURLs) {
        isValid = false;
      }
      if (havingInValidActBlueURLs) {
        isValid = false;
      }
    }
    return { isValid, errorMessage };
  };
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const showInvalidFields = () => {
    const updatedTouched: { [key: string]: boolean } = { ...touched };
    Object.keys(touched).forEach((field: string) => {
      updatedTouched[field] = true;
    });
    setTouched(updatedTouched);
  };
  return {
    isFormValid,
    isNameValid,
    isMessageValid,
    showInvalidFields,
    touched,
    setTouched,
    currentName,
    setCurrentName,
    broadcastType,
    setBroadcastType,
    labels,
    setLabels,
    isRepeatBroadcast,
    setIsRepeatBroadcast,
    responseType,
    setResponseType,
    currentMessage,
    setCurrentMessage,
    modules,
    setModules,
    enableClickTracking,
    setEnableClickTracking,
    excludeOptoutText,
    setOptoutTextVisiblity,
    scheduleType,
    setScheduleType,
    startDate,
    setStartDate,
    showScheduleDateWarning,
    setShowScheduleDateWarning,
    selectedSegmentIds,
    setSelectedSegmentIds,
    advancedSegmentConditions,
    setAdvancedSegmentConditions: toSetAdvancedSegmentConditions,
    isvalidAdvancedSegments,
    sendAsMMS,
    setSendAsMMS,
    userVariableComponents,
    setUserVariableComponents,
    auditLogs,
    setAuditLogs,
    isSegmentValid,
    setIsSegmentValid,
    appendTextValue,
    previousOptOutText,
    setTimeZoneType,
    timeZoneType,
    waTemplates,
    setWaTemplates,
    selectedWaTemplate,
    setSelectedWaTemplate,
    isValidWaTemplate,
  };
};

// eslint-disable-next-line max-lines-per-function
export type BroadcastStateData = {
  currentName: string;
  currentMessage: string;
  broadcastId?: string;
  broadcastType: string;
  enableClickTracking: boolean;
  scheduleType: string;
  startDate?: Moment;
  mediaUrl?: string;
  selectedSegmentIds: SelectedSegments;
  sendAsMMS?: boolean;
  disableSendAsMms?: boolean;
  excludeOptoutText: boolean;
  selectedWaTemplate?: any;
  advancedSegmentConditions?: any;
  userVariableComponents?: any[];
  captureUserInput?: any;
};
export const useManageTemplateCtaButtons = (onCancel?: () => void) => {
  // const { currentName, currentMessage, broadcastId = '', broadcastType, enableClickTracking, scheduleType, startDate, selectedSegmentIds, sendAsMMS } = broadcastData;
  const {
    currentName,
    currentMessage,
    broadcastId,
    broadcastType,
    enableClickTracking,
    scheduleType,
    startDate,
    selectedSegmentIds,
    advancedSegmentConditions,
    sendAsMMS,
    isFormValid,
    isNameValid,
    showInvalidFields,
    onDone: onClose,
    // currentStep,
    setCurrentStep,
    excludeOptoutText,
    appendTextValue,
    timeZoneType,
    isValidWaTemplate,
    selectedWaTemplate,
    userVariableComponents,
    createMode,
    cloneMode,
    selectedBroadcast,
  } = useBroadcastPageContext();
  // const platform = useCurrentPlatformBotObj()?.platform;
  const dispatch = useDispatch<Dispatch>();
  const amplitudeEventProps = useAmplitudeEventProps();
  const attachmentData = useSelector((state: RootState) => state.commentsViewer.comments.attachedMedia);
  const history = useHistory();
  const isMobile = useIsMobile();
  const currentBot: any = useCurrentBot() || {};
  const timezone: any = useTimeZone();
  const mediaUrl = attachmentData && attachmentData[0]?.mediaURL ? attachmentData[0].mediaURL : undefined;
  const broadcasts = useSelector((state: RootState) => state.entities.broadcasts);
  const segments = useSegments();
  const segmentTags = useSegmentTags();
  const isCliqzTypeBotConfig = useIsCliqzTypeBotConfig();
  const selectedAccount = useSelector((state: RootState) => state.entities.account);
  const disableSendAsMms = selectedAccount?.config?.uiConfig?.[platforms.TWILIO_SMS]?.disableSendAsMms;
  const currPlatformObj = useCurrentPlatformBotObj();
  const isFbInstaPlatform = checkPlatformInstaFB(currPlatformObj?.platformAsInSystemSettings);
  const isValidIfWaTemplate = currPlatformObj?.platformAsInSystemSettings !== platforms.WHATSAPP || isValidWaTemplate;
  const enableInputNodeAccessToggle = useMemo(
    () => Boolean(selectedAccount?.config?.uiConfig?.[currPlatformObj?.platformAsInSystemSettings || '']?.enableBroadcastCaptureUserInputAndVariables),
    [currPlatformObj, selectedAccount],
  );
  const isValid = () =>
    isValidIfWaTemplate &&
    (isFormValid() ||
      (isNameValid() &&
        !!currentMessage &&
        (scheduleType === broadcastScheduleConst.NOW.value || moment(startDate).format('YYYY MM DD') !== 'Invalid Date') &&
        checkIfAdvancedSegmentsValid(advancedSegmentConditions)));

  const handleCreate = () => {
    if (isValid()) {
      currentBot?.id &&
        createBroadcastUiToApi(
          {
            currentName,
            currentMessage,
            broadcastType,
            enableClickTracking,
            mediaUrl,
            scheduleType,
            startDate,
            selectedSegmentIds,
            sendAsMMS,
            disableSendAsMms,
            excludeOptoutText,
            selectedWaTemplate,
            advancedSegmentConditions,
            userVariableComponents,
            //  ...(currentStep === 0 ? { userVariableComponents } : {}),
          },
          currentBot.id,
          timezone,
          appendTextValue,
          timeZoneType,
          segments,
          segmentTags,
          currentBot,
          isCliqzTypeBotConfig,
          selectedAccount?.config?.uiConfig?.[currPlatformObj?.platformAsInSystemSettings || '']?.enableDefaultGlobalTargeting,
          currPlatformObj?.platformAsInSystemSettings,
        )
          .then((result: any) => dispatch(createBroadcast(result)))
          .then((b: BroadcastTypeAPI | null) => {
            if (b) {
              registerAmplifyAnalytics.createBroadcast(currentName, amplitudeEventProps);
              const toUI = broadcastsToUI([b], timezone);
              const donationConfig: any = getDonationConfigIfHidden(b, timezone);
              // dispatch(setCurrentActiveTab(broadcastConstants.RECENT));
              donationConfig?.engagementStats && b?.id && (toUI[b.id] = { ...toUI[b?.id], ...donationConfig });
              dispatch(storeBroadcast(Object.values(toUI)[0]));
              history.push(
                `./editBroadcast/${b.id}?filters=${encodeValue({
                  layout: { activeSubPage: { title: 'Edit Broadcast', value: 'viewEditBroadcast' }, extraProps: { bottomContent: b.name } },
                  currentStep: 1,
                })}`,
              );
              !isMobile && setCurrentStep(1);
            }
          });
    } else {
      showInvalidFields();
    }
  };
  const handleClone = (targetBroadcast: BroadcastTypeUI) => {
    if (isValid()) {
      currentBot?.id &&
        targetBroadcast &&
        cloneBroadcastUiToApi(
          targetBroadcast,
          {
            currentName,
            currentMessage,
            broadcastType,
            enableClickTracking,
            mediaUrl,
            scheduleType,
            startDate,
            selectedSegmentIds,
            sendAsMMS,
            disableSendAsMms,
            excludeOptoutText,
            selectedWaTemplate,
            userVariableComponents,
            //  ...(currentStep === 0 ? { userVariableComponents } : {}),
          },
          currentBot.id,
          timezone,
          appendTextValue,
          currentBot,
          currPlatformObj?.platformAsInSystemSettings,
        )
          .then((result: any) => {
            const transformedResult = { ...result };
            if (!enableInputNodeAccessToggle) {
              delete transformedResult.setVariables;
              delete transformedResult.captureUserInput;
            }
            return dispatch(createBroadcast(transformedResult));
          })
          .then((b: BroadcastTypeAPI | null) => {
            if (b) {
              registerAmplifyAnalytics.createBroadcast(currentName, amplitudeEventProps);
              const toUI = broadcastsToUI([b], timezone);
              const donationConfig: any = getDonationConfigIfHidden(b, timezone);
              donationConfig?.engagementStats && b?.id && (toUI[b.id] = { ...toUI[b?.id], ...donationConfig });
              // dispatch(setCurrentActiveTab(broadcastConstants.RECENT));
              dispatch(storeBroadcast(Object.values(toUI)[0]));
              history.push(
                `../editBroadcast/${b.id}?filters=${encodeValue({
                  layout: { activeSubPage: { title: 'Edit Broadcast', value: 'viewEditBroadcast' }, extraProps: { bottomContent: b.name } },
                  currentStep: 1,
                })}`,
              );
              !isMobile && setCurrentStep(1);
            } else {
              showMessage(`Broadcast created`);
              onClose(broadcastConstants.RECENT);
            }
          });
    } else {
      showInvalidFields();
    }
  };
  const handleEdit = (onDone?: () => void, navigateBack?: boolean) => {
    const broadcast = broadcasts[broadcastId];
    if (isValid()) {
      broadcast &&
        currentBot?.id &&
        updateBroadcastUiToApi(
          {
            currentName,
            currentMessage,
            broadcastType,
            enableClickTracking,
            mediaUrl,
            scheduleType,
            startDate,
            selectedSegmentIds,
            sendAsMMS,
            disableSendAsMms,
            excludeOptoutText,
            selectedWaTemplate,
            advancedSegmentConditions,
            userVariableComponents,
            //  ...(currentStep === 0 ? { userVariableComponents } : {}),
          },
          broadcast,
          appendTextValue,
          timeZoneType,
          segments,
          segmentTags,
          timezone,
          currentBot,
          isCliqzTypeBotConfig,
          selectedAccount?.config?.uiConfig?.[currPlatformObj?.platformAsInSystemSettings || '']?.enableDefaultGlobalTargeting,
          isFbInstaPlatform,
          currPlatformObj?.platformAsInSystemSettings,
        )
          .then((result: any) => {
            const inputNodeAccess =
              enableInputNodeAccessToggle ||
              (!createMode && !cloneMode && (selectedBroadcast?.captureUserInput || selectedBroadcast?.setVariables?.length || userVariableComponents?.length));
            return dispatch(updateBroadcast(result, inputNodeAccess));
          })
          .then((b: BroadcastTypeUI | null) => {
            if (b) {
              registerAmplifyAnalytics.updateBroadcast(currentName, broadcast?.id, amplitudeEventProps);
              dispatch(storeBroadcast(b));
              onDone?.();
              navigateBack && onClose();
              showMessage(`Your broadcast is saved in Drafts`);
            }
          });
    } else {
      showInvalidFields();
    }
  };

  const handleDelete = () => {
    // dispatch(deleteTemplate(templateId)).then((success: boolean) => {
    //   if (success) {
    //     onClose();
    //     dispatch(removeTemplate(templateId));
    //     dispatch(deleteTemplateFromMessageMap(templateId));
    //     registerAmplifyAnalytics.deleteTemplate(currentName, templateId, amplitudeEventProps);
    //     showMessage(`${DoneAction.templateDeleted} ${SuccessText}`);
    //   }
    // });
  };

  const pauseResumeBroadcastCallback = () => {
    onClose();
  };

  const handleSend = () => {
    const broadcast = broadcasts[broadcastId];
    const transformedStartDate = getTransformedScheduleStartDate(startDate, timezone, timeZoneType);
    updateBroadcastUiToApi(
      {
        currentName,
        currentMessage,
        broadcastType,
        enableClickTracking,
        mediaUrl,
        scheduleType,
        startDate: transformedStartDate,
        selectedSegmentIds,
        sendAsMMS,
        disableSendAsMms,
        excludeOptoutText,
        selectedWaTemplate,
        advancedSegmentConditions,
        userVariableComponents,
        //  ...(currentStep === 0 ? { userVariableComponents } : {}),
      },
      broadcast,
      appendTextValue,
      timeZoneType,
      segments,
      segmentTags,
      timezone,
      currentBot,
      isCliqzTypeBotConfig,
      selectedAccount?.config?.uiConfig?.[currPlatformObj?.platformAsInSystemSettings || '']?.enableDefaultGlobalTargeting,
      isFbInstaPlatform,
      currPlatformObj?.platformAsInSystemSettings,
    ).then((result: any) => {
      dispatch(pauseResumeBroadcast(broadcastId, 'resume', false, timezone || '', pauseResumeBroadcastCallback, result));
    });
  };

  const handleCancel = () => {
    onCancel ? onCancel() : onClose();
  };
  return { handleCreate, handleClone, handleEdit, handleDelete, handleCancel, handleSend };
};

export const useFetchBroadcastsForEdit = () => {
  const {
    viewMode,
    setSendAsMMS,
    setUserVariableComponents,
    setCurrentName,
    setCurrentMessage,
    setEnableClickTracking,
    setScheduleType,
    setStartDate,
    setShowScheduleDateWarning,
    setSelectedSegmentIds,
    setAdvancedSegmentConditions,
    setBroadcastType,
    setLabels,
    setIsRepeatBroadcast,
    setResponseType,
    setModules,
    createMode,
    broadcastId,
    cloneMode,
    currentStep,
    appendTextValue,
    previousOptOutText,
    setOptoutTextVisiblity,
    setTimeZoneType,
    hasMergeTagConverted,
    selectedBroadcast,
  } = useBroadcastPageContext();
  const callbacks = useMemo(
    () => ({
      setSendAsMMS,
      setUserVariableComponents,
      setCurrentName,
      setCurrentMessage,
      setEnableClickTracking,
      setScheduleType,
      setStartDate,
      setShowScheduleDateWarning,
      setSelectedSegmentIds,
      setAdvancedSegmentConditions,
      setBroadcastType,
      setLabels,
      setIsRepeatBroadcast,
      setResponseType,
      setModules,
      setOptoutTextVisiblity,
      setTimeZoneType,
    }),
    [
      setSendAsMMS,
      setUserVariableComponents,
      setCurrentName,
      setCurrentMessage,
      setEnableClickTracking,
      setScheduleType,
      setStartDate,
      setShowScheduleDateWarning,
      setSelectedSegmentIds,
      setAdvancedSegmentConditions,
      setBroadcastType,
      setLabels,
      setIsRepeatBroadcast,
      setResponseType,
      setModules,
      setOptoutTextVisiblity,
      setTimeZoneType,
    ],
  );
  const platform = useCurrentPlatformBotObj()?.platformAsInSystemSettings;
  const dispatch = useDispatch<Dispatch>();
  const broadcasts = useSelector((state: RootState) => state.entities.broadcasts);
  const timeZone = useTimeZone();
  const setStartDateFunc = (startDate: number, scheduleType: string, timezoneType: string = '') => {
    const currentDate = moment.tz(timeZone);

    const accountOffset = moment.tz(timeZone).utcOffset();
    let startDateFinal = timezoneType === timezoneTypes.USER ? moment(startDate).utcOffset(accountOffset, true) : moment(startDate).tz(timeZone);
    const broadcastDate = startDateFinal.utcOffset(accountOffset, true);
    if ((broadcastDate < currentDate || cloneMode) && scheduleType === broadcastScheduleConst.LATER.value) {
      callbacks.setShowScheduleDateWarning(true);
      startDateFinal = currentDate.add(5, 'minutes');
    }
    callbacks.setStartDate(startDateFinal);
  };
  const broadcastFetchedForEdit = useRef(false);
  const [variables, setVariables] = useState<VariableType[]>([]);
  const botVariables = useVariables();

  useEffect(() => {
    const newVariables: VariableType[] = Object.values(botVariables) || [];
    newVariables.length && setVariables((vars: VariableType[]) => uniqBy([...vars, ...newVariables], 'macro'));
  }, [botVariables]);

  useEffect(() => {
    if (selectedBroadcast?.actions) {
      const localVariables: VariableType[] = extractLocalVariablesFromBroadcasts(selectedBroadcast) || [];
      localVariables.length && setVariables((vars: VariableType[]) => uniqBy([...vars, ...localVariables], 'macro'));
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBroadcast?.actions]);

  useEffect(() => {
    const broadcast = (broadcastId && broadcasts?.[broadcastId]) as BroadcastTypeUI;
    /* here we are updating local state with data from Redux store when there's a selected broadcast
    We are just updating once, but to support pre/next btn to load new broadcast, we are allowing to update local state (if (broadcastFetchedForEdit.current && !viewMode){})
    we can remove this below logic as with new layout change now it takes time to a user to go back to edit page from test page and in between data is updated in redux
    */
    if (!createMode && broadcast) {
      if (broadcastFetchedForEdit.current && !viewMode) {
        return;
      }
      if (!hasMergeTagConverted) {
        broadcastFetchedForEdit.current = true;
      }

      if (broadcast.notificationLabels) {
        const labelsList = broadcast.notificationLabels;
        labelsList?.length && labelsList?.[0] !== 'ALL' && callbacks.setLabels?.(labelsList);
      }

      broadcast.repeat && callbacks.setIsRepeatBroadcast?.(broadcast.repeat);
      //**verify logic for create broadcast again */
      if (broadcast.responses?.length) {
        const broadcastResponse = broadcast.responses[0];
        broadcastResponse && callbacks.setResponseType?.(broadcastResponse?.type);
        broadcastResponse?.type === 'module' && callbacks.setModules?.(broadcast.responses.map((item: any) => item.id));
      }
      callbacks.setBroadcastType(getBroadcastTypeForAPI(broadcast.type));
      if (cloneMode) {
        callbacks.setCurrentName(`${moment.tz(timeZone).format('DD MMM YYYY, h.mm A')}`);
      } else {
        callbacks.setCurrentName(broadcast.broadcastName);
      }
      callbacks.setScheduleType(broadcast.scheduleType);
      setStartDateFunc(broadcast.startDate, broadcast.scheduleType, broadcast.timeZone);

      // callbacks.setStartDate(moment(broadcasts[broadcastId].startDate).tz(timeZone || ''));
      // callbacks.setSelectedSegmentId((broadcasts[broadcastId].conditions && getFirstSegmentIdFromConditions(broadcasts[broadcastId].conditions)) || ALLCONSTANT);

      const broadcastChildCondtions: any = [];
      broadcast.conditions?.childConditions?.forEach((childC: GCChildCondition) =>
        childC.childConditions?.forEach((childConditionSegment: any) => {
          if (childConditionSegment.segmentId || childConditionSegment.tagId) {
            broadcastChildCondtions.push(childConditionSegment);
          }
        }),
      );
      const { includeSegments, excludeSegments } = getIncludeExcludeSegmentsIds(broadcastChildCondtions);
      callbacks.setSelectedSegmentIds({ included: includeSegments, excluded: excludeSegments });
      if (broadcast.hasAdvancedSegments) {
        const segmentConditions: any = [];
        broadcast?.conditions &&
          broadcast?.conditions.childConditions &&
          broadcast?.conditions.childConditions.forEach((x: any) => {
            if (x.segmentId !== undefined) {
              const allChildCWithIds = x?.childConditions?.map((condition?: any) => {
                const conditionWithSegmentId = { ...condition, id: uuid() };
                if (condition.tagId) {
                  conditionWithSegmentId.segmentId = getTagIdGroupNameAsKey(condition.tagId, condition.tagGroupName);
                  delete conditionWithSegmentId.tagId;
                  delete conditionWithSegmentId.tagGroupName;
                }
                return conditionWithSegmentId;
              });
              segmentConditions.push({ ...x, childConditions: allChildCWithIds, id: uuid() });
            }
          });

        callbacks.setAdvancedSegmentConditions?.({ childConditions: segmentConditions, logicalOperator: broadcast?.conditions?.logicalOperator });
      }
      let shouldResetMedia = true; // this flag makes sure if there is no media in response, we are clearing the media out from redux

      broadcast.actions?.[0]?.components?.forEach((component: any) => {
        if (component.type === 'text') {
          const isSMSPlatform = platform === platforms.TWILIO_SMS;
          let message = component?.text || '';
          message = removeAppendedText(message, !viewMode && isSMSPlatform && broadcast.excludeOptoutText, appendTextValue, previousOptOutText);
          combinedRegexForVars.lastIndex = 0;
          message = isSMSPlatform ? unicode2html(removeShortURLTrackingFromText(message), combinedRegexForVars) : removeShortURLTrackingFromText(message);
          callbacks.setCurrentMessage(removeShortURLTrackingFromText(message));
          callbacks.setEnableClickTracking(broadcast.enableClickTracking);
          callbacks.setSendAsMMS(broadcast.sendAsMMS);
          if (selectedBroadcast?.setVariables?.length === 1 && !selectedBroadcast?.setVariables?.[0]?.conditions?.length) {
            const userVarsToSet = getUserVariablesData(broadcast);
            userVarsToSet?.length && callbacks?.setUserVariableComponents?.(userVarsToSet || []);
          }
          callbacks.setOptoutTextVisiblity(broadcast.excludeOptoutText);

          return;
        }
        if (component.type === 'image') {
          if (component?.url) {
            const mediaType = component.url.split('.')[component.url.split('.').length - 1] || '';
            const substrings = component.url.split('/');
            const mediaName = substrings[substrings.length - 1] || '';
            shouldResetMedia = false;
            dispatch(setMedia([{ mediaType, mediaURL: component.url || '', mediaName }]));
          } else {
            shouldResetMedia = false; //since below resets media
            dispatch(setMedia([{ mediaType: '', mediaURL: '', mediaName: '' }]));
          }
        }
      });
      callbacks.setTimeZoneType(broadcast.timeZone);
      shouldResetMedia && dispatch(setMedia([{ mediaType: '', mediaURL: '', mediaName: '' }]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastId, createMode, callbacks, broadcasts, currentStep]);
  useEffect(() => {
    if (cloneMode && !broadcasts?.[broadcastId]) {
      const newBroadcastId = broadcastId.replace('_clone', '');
      const oldBroadcast = broadcasts[newBroadcastId];
      const localVariables = variables.filter((variable: VariableType) => variable.type === 'local');

      if (oldBroadcast) {
        const newBroadcast = {
          ...oldBroadcast,
          id: broadcastId,
          hasLocalVars: false,
          preventMessageEditWithWarning: '',
        };
        migrateBroadcast(newBroadcast, localVariables, setVariables, setCurrentMessage, dispatch, storeBroadcast);
      }
    }
    return () => {
      if (cloneMode && broadcasts?.[broadcastId]) {
        dispatch(deleteBroadcastFromSore(broadcastId));
      }
    };
  }, [cloneMode, broadcasts, broadcasts?.[broadcastId], variables]);
  useEffect(() => {
    broadcastFetchedForEdit.current = false;
  }, [currentStep]);

  return broadcastId && broadcasts[broadcastId];
};

export const useTestBroadcastModalState = (hideTestModal?: () => void, isWaPlatform?: boolean): TestBroadcastStateProps => {
  const bot = useCurrentBot() as Bot;
  const platformUsers = bot?.platformUsers;
  const allPlatformBots = bot?.platformBots;

  const defaultSearchBy = searchByOptionsForTest[0].value;
  const phoneSearchBy = searchByOptionsForTestPhone[0].value;
  const phoneSearch =
    (allPlatformBots && allPlatformBots.length && allPlatformBots.every((platform: any) => platform && testUsingPhoneNumberBotPlatforms.includes(platform.platform))) || false;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedPlatformUsers, setSelectedPlatformUsers] = useState<any[]>([]);
  const [searchedPlatformUsers, setSearchedPlatformUsers] = useState<any[]>(platformUsers || []);
  const [allPlatfroUsersForTesting, setAllPlatformUserForTesting] = useState<any[]>([]);
  const [deletedPlatformUsers, setDeletedPlatformUsers] = useState<string[]>([]); //we are handling delete action just on UI
  const [noSearchedUser, setNoSearchedUser] = useState<boolean>(false);
  const [platformUserId, setPlatformUserId] = useState('');
  const [searchBy, setSearchBy] = useState<string | boolean>(phoneSearch || defaultSearchBy);
  const [uniquePlatforms, setUniquePlatforms] = useState([]);

  const [platform, setPlatform] = useState(phoneSearch ? allPlatformBots[0].platform : '');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCurrentUserSubscribed, setIsCurrentUserSubscribed] = useState<{ isSubscribed: boolean; isCurrentUserSelected: boolean; isSubscriptionChecked: boolean }>({
    isSubscribed: false,
    isCurrentUserSelected: false,
    isSubscriptionChecked: false,
  });

  const targetedUsers = getTargetedUsers(bot);

  // const handleRemovePlatformUser = (platformUserId: string) => {
  //   //let currentPlatformUser = selectedPlatformUsers.filter((x: any) => x.id == platformUserId)
  //   const updatedSelectedPlatformUsers = selectedPlatformUsers.filter((x: any) => x.platformUserId !== platformUserId);
  //   //let updatedUnselectedPlatformUsers = [...unselectedPlatformUsers]
  //   //updatedUnselectedPlatformUsers.push(currentPlatformUser[0])
  //   //this.setState({unselectedPlatformUsers : updatedUnselectedPlatformUsers})
  //   setSelectedPlatformUsers(updatedSelectedPlatformUsers);
  // };

  const setUniquePlatform = useMemo(
    () => (allPlatformBots: any[]) => {
      const uniquePlatforms: any = [];
      let platform = '';
      allPlatformBots &&
        allPlatformBots.forEach((platformBot: any) => {
          if (!uniquePlatforms.find((platform: any) => platform === platformBot.platform)) {
            uniquePlatforms.push({
              value: platformBot?.platform,
              label: botPlatformApiToUIMapper[platformBot.platform] ? botPlatformApiToUIMapper[platformBot.platform] : platformBot.platform,
            });
          }
        });
      uniquePlatforms.sort((a: any, b: any) => a.label.localeCompare(b.label)); //sort alphabetically
      if (uniquePlatforms.length > 1) {
        //if it has messenger option, that is the default
        uniquePlatforms.find((platformData: any) => {
          const checkPlatformSuccess = isWaPlatform ? platformData.value === platforms.WHATSAPP : platformData.value === platforms.FACEBOOK;
          checkPlatformSuccess && (platform = platforms[isWaPlatform ? 'WHATSAPP' : 'FACEBOOK']);
          return checkPlatformSuccess;
        });
        !platform && (platform = uniquePlatforms[0].value);
      } else if (uniquePlatforms.length === 1) {
        platform = uniquePlatforms[0].value;
        if (testUsingPhoneNumberBotPlatforms.includes(platform)) {
          setSearchBy(phoneSearchBy);
        }
      }
      setPlatform(platform);
      setUniquePlatforms(uniquePlatforms);
    },
    [phoneSearchBy],
  );

  const updatePhoneNumber = ({ target: { value } }: any) => {
    const formattedPhoneNumber = formattedPhoneInput(value);
    const platformUserId = (formattedPhoneNumber && formattedPhoneNumber.length === 14 && `${formattedPhoneNumber.replace(/\D/g, '')}`) || '';
    setPhoneNumber(formattedPhoneNumber || '');
    setPlatformUserId(platformUserId);
  };
  const handleRemovePlatformUser = (platformUserId: string) => {
    //let currentPlatformUser = selectedPlatformUsers.filter((x: any) => x.id == platformUserId)
    const updatedSelectedPlatformUsers = selectedPlatformUsers.filter((x: any) => x.platformUserId !== platformUserId);
    //let updatedUnselectedPlatformUsers = [...unselectedPlatformUsers]
    //updatedUnselectedPlatformUsers.push(currentPlatformUser[0])
    //this.setState({unselectedPlatformUsers : updatedUnselectedPlatformUsers})
    setSelectedPlatformUsers(updatedSelectedPlatformUsers);
    const updatedAllPlatformUsers = allPlatfroUsersForTesting.filter((x: any) => x.platformUserId !== platformUserId);
    setDeletedPlatformUsers([...deletedPlatformUsers, platformUserId]);
    setAllPlatformUserForTesting(updatedAllPlatformUsers);
  };
  const handleAddTargeting = (targetings: any) => {
    // let updatedSelectedPlatformUsers = [...selectedPlatformUsers];
    // updatedSelectedPlatformUsers = updatedSelectedPlatformUsers.concat(targetings);
    setSelectedPlatformUsers(targetings);
  };
  const done = () => {
    // dispatch(setTestBroadcastSent(false));
    setFirstName('');
    setLastName('');
    setSearchBy(defaultSearchBy);
    setPlatformUserId('');
    setPhoneNumber('');
    setUniquePlatform(allPlatformBots);
    setSelectedPlatformUsers([]);
    hideTestModal?.();
  };

  return {
    defaultSearchBy,
    phoneSearchBy,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    selectedPlatformUsers,
    setSelectedPlatformUsers,
    noSearchedUser,
    platformUserId,
    setPlatformUserId,
    searchBy,
    setSearchBy,
    uniquePlatforms,
    platform,
    setPlatform,
    phoneNumber,
    handleRemovePlatformUser,
    handleAddTargeting,
    done,
    updatePhoneNumber,
    allPlatfroUsersForTesting,
    setAllPlatformUserForTesting,
    targetedUsers,
    searchedPlatformUsers,
    setSearchedPlatformUsers,
    setNoSearchedUser,
    isCurrentUserSubscribed,
    setIsCurrentUserSubscribed,
    setUniquePlatform,
    deletedPlatformUsers,
    setDeletedPlatformUsers,
  };
};

export const useTestBroadcastEffects = ({
  isCurrentUserSubscribed,
  setIsCurrentUserSubscribed,
  setNoSearchedUser,
  searchedPlatformUsers,
  setSearchedPlatformUsers,
  selectedPlatformUsers,
  deletedPlatformUsers,
  setDeletedPlatformUsers,
  setSelectedPlatformUsers,
  setAllPlatformUserForTesting,
  setUniquePlatform,
}: TestBroadcastStateProps) => {
  const bot = useCurrentBot() as Bot;
  const dispatch = useDispatch();
  const currentUserId = useCurrentUser()?.id || '';
  const allUsers = useAllUsers();
  const currentPlatformBot = useCurrentPlatformBotObj();
  const platformBotId = useSelector((state: RootState) => state.pageUIState?.selectedPlatformBot) || '';

  const currentUser = allUsers[currentUserId];
  const platformUsers = bot?.platformUsers as any;
  const targetedUsers = getTargetedUsers(bot);
  const allPlatformBots = bot?.platformBots;

  const handleAddPlatformUser = (currentPlatformUser: any) => {
    setSelectedPlatformUsers((selectedPlatformUsers: any) => uniqBy([...selectedPlatformUsers, currentPlatformUser], 'platformUserId'));
  };
  const botId = bot?.id;
  useEffect(() => {
    //return test screen to normal view
    dispatch(setTestBroadcastSent(false));
    bot?.id && dispatch(addTestUsersToBot({ botId: bot?.id, testUsers: [] }));
    setSelectedPlatformUsers([]);
    setIsCurrentUserSubscribed((values: any) => ({ ...values, isCurrentUserSelected: false }));
    if (currentUser && currentUser.phoneNumber && currentPlatformBot && !isCurrentUserSubscribed.isSubscriptionChecked) {
      dispatch(
        checkUserSubscriptions(
          [currentPlatformBot],
          { phoneNumber: currentUser.phoneNumber },
          () => setIsCurrentUserSubscribed({ isSubscribed: true, isCurrentUserSelected: false, isSubscriptionChecked: true }),
          () => setIsCurrentUserSubscribed({ isSubscribed: false, isCurrentUserSelected: false, isSubscriptionChecked: true }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (botId && !bot?.targetedUsers) {
      dispatch(fetchTargetingUsers(botId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, botId]);
  useEffect(() => {
    platformUsers && setNoSearchedUser(platformUsers.length ? false : true);
    if (platformUsers?.length) {
      const newDeletedPlatformUsers = [...deletedPlatformUsers];
      platformUsers.forEach((platformUser: any) => {
        handleAddPlatformUser(platformUser);
        if (newDeletedPlatformUsers.includes(platformUser.platformUserId)) {
          newDeletedPlatformUsers.splice(newDeletedPlatformUsers.indexOf(platformUser.platformUserId), 1);
        }
      });
      setDeletedPlatformUsers(newDeletedPlatformUsers);
    }
    setSearchedPlatformUsers(uniqBy([...(searchedPlatformUsers || []), ...(platformUsers || [])], 'platformUserId'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformUsers]);
  useEffect(() => {
    const preTargeting = targetedUsers?.[0]?.targeting || [];
    const currentUserList: any[] = [];
    if (currentUser && currentUser.phoneNumber && isCurrentUserSubscribed.isSubscribed) {
      currentUserList.push({
        platform: platforms.TWILIO_SMS,
        platformUserId: currentUser.phoneNumber,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        platformBotName: '',
        platformBotId,
        isCurrentUser: true,
      });
      if (!isCurrentUserSubscribed.isCurrentUserSelected) {
        if (!deletedPlatformUsers.includes(currentUser.phoneNumber) && selectedPlatformUsers.findIndex((user: any) => user.platformUserId === currentUser.phoneNumber) === -1) {
          setSelectedPlatformUsers((selectedPlatformUsers: any[]) => [...currentUserList, ...selectedPlatformUsers]);
        }
        setIsCurrentUserSubscribed((value: any) => ({ ...value, isCurrentUserSelected: true }));
      }
    }
    const allUers = uniqBy([...currentUserList, ...preTargeting, ...(searchedPlatformUsers || [])], 'platformUserId');
    const filteredUsers = allUers.filter((x: any) => !deletedPlatformUsers.includes(x.platformUserId));
    setAllPlatformUserForTesting(filteredUsers as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot, searchedPlatformUsers, isCurrentUserSubscribed]);
  useEffect(() => {
    setUniquePlatform(allPlatformBots);
  }, [allPlatformBots, botId, setUniquePlatform]);
};

export const useTestBroadcast = (
  users: [any],
  broadcast: BroadcastTypeUI,
  targetedUsers: Bot['targetedUsers'] /* validateNewTargeting: (platformUserIds: string[]) => boolean*/,
) => {
  const dispatch = useDispatch();
  const currentBot: any = useCurrentBot() || {};

  // const users = _.uniqBy(currentBot.targetedUsers, 'userId');
  // const nodes = [];
  const targetings: any[] = [];
  const platformUserIds: any[] = [];
  // if (!broadcast) return;
  // console.log(users);
  // broadcast &&
  //   broadcast.actions.forEach((action: any) => {
  //     nodes.push(action.id);
  //   });
  users.forEach((user: any) => {
    const targetingUserObj = {
      platformBotId: user.platformBotId,
      platformUserId: user.platformUserId,
      platform: user.platform,
      profilePic: user.profilePic || undefined,
      firstName: user.firstName,
      lastName: user.lastName,
      locale: user.locale || undefined,
      gender: user.gender || undefined,
    };
    platformUserIds.push(user.platformUserId);
    targetings.push(targetingUserObj);
  });

  const sendTestMessage = (onDone?: any) => {
    if (platformUserIds?.length) {
      const targetingId = getTargetingId(platformUserIds, targetedUsers);
      dispatch(sendTestBroadcastMessages(currentBot?.id, broadcast.id, targetings, targetingId, onDone));
    }
  };
  return sendTestMessage;
};

const getTargetingId = (platformUserIds: string[], targetedUsers: any[] | undefined) => {
  if (!targetedUsers?.length) {
    return null;
  }

  const stateTargetedUsers = targetedUsers;

  const currentUsersCsv = platformUserIds.sort().join(',');
  const existingTargeting = _(stateTargetedUsers)?.find(
    (x: any) =>
      x.targeting
        ?.map((x: any) => x.platformUserId)
        .sort()
        .join(',') === currentUsersCsv,
  );
  return existingTargeting && existingTargeting.id ? existingTargeting.id : null;
};

// const useBroadcast = (isClone: boolean) => {
//   return (isClone && this.props.broadcast) ? (this.props.broadcast.id) :
//  (this.props.match.params.broadcastId ? this.props.match.params.broadcastId : getNewBroadCastId(this.props.match.params.botId));
// }

export const useFetchSegments = (platformBotId?: string, doNotShowLoader?: boolean, checkIfAlreadyFetched?: boolean) => {
  const segments = useSegments();
  const dispatch = useDispatch();
  useEffect(() => {
    if (platformBotId && (checkIfAlreadyFetched ? !Object.entries(segments)?.length : true)) {
      dispatch(fetchSegments()); //only fetch segments once platformBotId is known
    }
  }, [dispatch, platformBotId, doNotShowLoader, checkIfAlreadyFetched]);
};

export const useFetchSegmentTags = (botId?: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (botId) {
      dispatch(fetchSegmentTags(botId)); //only fetch segments once platformBotId is known
    }
  }, [dispatch, botId]);
};

export const useGetUserSegments = (platformUserId: string, isBotworxAdmin?: boolean) => {
  const [userSegments, setUserSegments] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    if (platformUserId && isBotworxAdmin) {
      dispatch(getUserSegments(platformUserId, (segments: Segments) => setUserSegments(segments)));
    } else {
      setUserSegments({});
    }
  }, [dispatch, platformUserId, isBotworxAdmin]);

  return userSegments;
};
export const useCheckValidWaTemplate = (selectedWaTemplate?: any) => {
  const [isValidTemplate, setIsValidTemplate] = useState<any>({ error: false, errorObj: null });
  useEffect(() => {
    setIsValidTemplate(selectedWaTemplate ? checkIfWaTemplateMacroValid(selectedWaTemplate) : {});
  }, [selectedWaTemplate]);

  return isValidTemplate;
};
export const useSegmentsAndOptions = (doNotAddALLOption?: boolean) => {
  const segments = useSegments();

  return { segments, ...getSegmentsAsOptions(segments, doNotAddALLOption) };
};

export const useSegmentTagsAndOptions = () => {
  const segmentTags = useSegmentTags();
  return { segmentTags, ...getSegmentTagsAsOptions(segmentTags) };
};

export const useSegments = () => {
  return useSelector((state: RootState) => state.entities.segments) || {};
};
export const useSegmentTags = () => {
  return useSelector((state: RootState) => state.entities.segmentTags) || {};
};

export const useBroadcastUIBackFunctions = () => {
  const { dispatchSetMobileNavigation } = useMobileNavigation();
  const dispatch = useDispatch();
  const onBackToBroadcastListPage = () => {
    dispatchSetMobileNavigation(broadcastSubPagesForMobile.HOME, pageKeysForFilter.BROADCASTS);
    dispatch(
      setFilter(pageKeysForFilter.BROADCASTS, pageFilterKeys.FILTERS, {
        [CommentsFilterKeysInsideFilter.LAY_OUT]: { activeSubPage: broadcastSubPagesForMobile.HOME },
        currentStep: undefined,
      }),
    );
  };
  const onClickViewBroadcast = (name: string, bottomContent?: string, isRepeatTabNotAvailable?: boolean) => {
    dispatchSetMobileNavigation({ title: name, value: 'viewBroadcast', extraProps: { bottomContent } }, pageKeysForFilter.BROADCASTS, {
      ...broadcastSubPagesForMobile.HOME,
      extraProps: {
        // If Repeat Tab Not Avaliable(No Repeat Broadcast Avalible) Then On Press Of Back Navigate to Draft Tab)
        path: isRepeatTabNotAvailable ? window.location.pathname.replace(broadcastConstants.REPEAT, broadcastConstants.DRAFT) : window.location.pathname,
      },
    });
    dispatch(
      setFilter(pageKeysForFilter.BROADCASTS, pageFilterKeys.FILTERS, {
        [CommentsFilterKeysInsideFilter.LAY_OUT]: {
          activeSubPage: { title: name, value: broadcastSubPagesForMobile.VIEW_EDIT.value },
          extraProps: { bottomContent },
        },
        currentStep: 0,
      }),
    );
  };
  const onClickCreateBroadcast = () => {
    dispatchSetMobileNavigation(
      {
        ...broadcastSubPagesForMobile.CREATE,
        extraProps: {},
      },
      pageKeysForFilter.BROADCASTS,
      {
        ...broadcastSubPagesForMobile.HOME,
        extraProps: {
          path: window.location.pathname,
        },
      },
    );
    dispatch(
      setFilter(pageKeysForFilter.BROADCASTS, pageFilterKeys.FILTERS, {
        currentStep: 0,
        [CommentsFilterKeysInsideFilter.LAY_OUT]: { activeSubPage: broadcastSubPagesForMobile.CREATE },
      }),
    );
  };
  return { onClickViewBroadcast, onClickCreateBroadcast, onBackToBroadcastListPage };
};
export const useTotalBroadcastUsers = (broadcastType: string) => {
  const bot = useCurrentPlatformBotObj();
  const audiencedata = useSelector((state: RootState) => state.broadcastPage.audiencedata);

  const totalUsers =
    bot?.platform && audiencedata && audiencedata[bot.platform]
      ? broadcastType === 'broadcast'
        ? audiencedata[bot.platform].total
        : audiencedata[bot.platform].subscribers
      : null;

  return totalUsers;
};

export const useFetchNotificationLabels = () => {
  const bot = useCurrentBot();
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    if (bot) {
      dispatch(fetchNotificationLabelsForBotVersion(bot.id, bot.activeVersionId));
    }
  }, [bot, dispatch]);
};

export const useSubscribedUserCountForSegment = (
  segments: SelectedSegments,
  broadcast: BroadcastTypeUI | null,
  readOnly?: boolean,
  advancedSegmentConditions?: any,
  enableDefaultGlobalTargeting: boolean = true,
  notificationLabels?: { [key: string]: any }, //It is required
) => {
  const [userCount, setUserCount] = useState<UserCountUpdated | null>(null);
  const reduxSegments = useSelector((state: RootState) => state.entities.segments);
  const reduxSegmentTags = useSegmentTags();
  const dispatch = useDispatch<Dispatch>();
  const bot = useCurrentBot();
  const isCliqzTypeBotConfig = useIsCliqzTypeBotConfig();
  const selectedAccount = useSelector((state: RootState) => state.entities.account);
  const segmentCallRef = useRef<number>(0);
  const timezone = useTimeZone();
  //
  // const segment = segments ? segments[segmentId] : null;
  useEffect(() => {
    if (segments && bot && !readOnly) {
      // const childConditionsWithoutSegments: (SegmentGroup | GroupConditionType)[] = isCliqzTypeBotConfig && !enableDefaultGlobalTargeting ? [usNumbersCondition] : [];

      const childConditionsWithoutSegments =
        broadcast?.conditions?.childConditions?.filter((childC?: any) =>
          advancedSegmentConditions || !isCliqzTypeBotConfig || enableDefaultGlobalTargeting
            ? childC?.segmentId === undefined
            : childC?.segmentId === undefined && !_.isEqual(usNumbersCondition, childC),
        ) || [];
      const childConditionsFromTargeting = advancedSegmentConditions
        ? getAdvancedSegmentConditionsWithoutIds(advancedSegmentConditions, childConditionsWithoutSegments)
        : getSegmentsChildConditions(
            segments,
            reduxSegments!,
            reduxSegmentTags!,
            isCliqzTypeBotConfig,
            selectedAccount?.config?.uiConfig?.[platforms.TWILIO_SMS]?.enableDefaultGlobalTargeting,
          );
      const broadcastType = broadcast?.type || broadcastTypeLabels[broadcastTypes.SUBSCRIPTION];
      const broadcastTypeSpecificConditions =
        broadcastType === broadcastTypeLabels[broadcastTypes.NOTIFICATION] && broadcast?.notificationLabels?.length
          ? getNotificationLabelConditions(
              timezone,
              broadcast?.notificationLabels?.[0] !== 'ALL' ? broadcast.notificationLabels.filter((label: any) => !!label) : Object.keys(notificationLabels || {}),
            )
          : broadcastType === broadcastTypeLabels[broadcastTypes.SUBSCRIPTION]
          ? getSubscriptionBroadcastConditions(broadcast)
          : undefined;
      let conditions: any = {
        logicalOperator: (advancedSegmentConditions ? advancedSegmentConditions?.logicalOperator : broadcast?.conditions?.logicalOperator) || 'and',
        childConditions: advancedSegmentConditions ? childConditionsFromTargeting : [...(childConditionsFromTargeting || []), ...(childConditionsWithoutSegments || [])],
      };
      conditions = broadcastTypeSpecificConditions
        ? {
            logicalOperator: 'and',
            childConditions: [conditions, broadcastTypeSpecificConditions],
          }
        : conditions;
      const segmentCountApiPayload: segmentUserCountAPIRequest = {
        botIds: [bot.id],
        conditions,
      };
      segmentCallRef.current = segmentCallRef.current + 1;
      const segmentCallCount = segmentCallRef.current;
      trackPromise(
        dispatch(fetchSegmentCount(segmentCountApiPayload, true)).then((data: number | null) => {
          if (segmentCallCount === segmentCallRef.current) {
            if (data !== null && data >= 0) {
              setUserCount({ count: data, isUpdated: true });
            } else {
              setUserCount({ count: 0, isUpdated: false });
            }
          }
        }),
        createbraodcastSegmentCountArea,
      );
    }
  }, [segments, advancedSegmentConditions, notificationLabels]);
  return userCount;
};

export const useAudience = () => {
  // const audiencedata = useSelector((state: RootState) => state.broadcastPage.audiencedata);
  const bot = useCurrentPlatformBotObj();
  const dispatch = useDispatch();

  useEffect(() => {
    bot?.botId && dispatch(fetchAudienceForBot(bot?.botId));
  }, [bot?.botId]);
};

export const useDispatchUserCount = (userCount: string) => {
  const { dispatchSetMobileNavigation, mobileNavigationProps } = useMobileNavigation();
  useEffect(() => {
    dispatchSetMobileNavigation(
      { ...(mobileNavigationProps?.activeSubPage as ActiveSubTabProps), extraProps: { bottomContent: userCount || '' } },
      mobileNavigationProps?.pageKeyForFilter || '',
      mobileNavigationProps?.prevLocation,
      mobileNavigationProps?.nextSubPage,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCount]);
};

export const BoadcastContext = createContext<{
  showBroadcastSentWarning: boolean;
  setShowBroadcastSentWarning: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  showBroadcastSentWarning: true,
  setShowBroadcastSentWarning: () => {},
});

export const useBoadcastContext = () => {
  return useContext(BoadcastContext);
};

export const useBoadcastFields = () => {
  const [showBroadcastSentWarning, setShowBroadcastSentWarning] = useState(true);
  return {
    showBroadcastSentWarning,
    setShowBroadcastSentWarning,
  };
};

export const useAllBroadcastAnalytics = (startDate?: string, endDate?: string) => {
  const timeZone = useTimeZone();
  const { accountId } = useIds();
  const currentBot: any = useCurrentBot() || {};
  const currentPlatformBotObj = useCurrentPlatformBotObj();
  const account: any = useCurrentAccount();
  const createdDate = startDate || (currentPlatformBotObj && Number(moment(currentPlatformBotObj?.createdDate || account.createdDate).format('YYYYMMDD')));
  const dispatch = useDispatch<Dispatch>();
  const broadcasts = useSelector((state: RootState) => state.entities.broadcasts);
  const [broadcastAnalyticsData, setBroadcastAnalyticsData] = useState<MetricsBroadcastMessageData>({});
  const [isFetch, setFetch] = useState(true);
  const todayDate =
    endDate ||
    Number(
      moment
        .tz(timeZone || '')
        .add('0', 'days')
        .format('YYYYMMDD'),
    );
  const bucketLimit = getBucketLimit(currentPlatformBotObj?.createdDate || '', moment.tz(timeZone || ''));
  const fetchAllBroadcastAnalytics = async () => {
    const commonPayload = {
      startDate: createdDate,
      endDate: todayDate,
      accountId,
      botId: currentBot?.id,
      groupByBucketLimit: Math.max(Object.keys(broadcasts || {})?.length, bucketLimit),
    };
    const payloadForAllMetrics = {
      ...commonPayload,
      filters: [
        {
          field: 'broadcastId',
          eq: Object.keys(broadcasts || {}),
        },
      ],
      aggregationFields: ['broadcasts', 'sent', 'delivered', 'read', 'engaged'],
      indexName: 'broadcasts',
    };

    const allBroadcastNodeIds = getNodeIdsFromBroadcast(broadcasts ? Object?.values(broadcasts) : []);
    const payloadForClicks = {
      ...commonPayload,
      filters: [
        {
          field: 'actionNode',
          eq: allBroadcastNodeIds,
        },
      ],
      groupByBucketLimit: Math.max(allBroadcastNodeIds?.length, bucketLimit),
      aggregationFields: ['clicks'],
      indexName: 'clicks',
    };
    const payloadForMessageType = {
      ...commonPayload,
      filters: [
        {
          field: 'node',
          eq: getNodeIdsFromBroadcast(broadcasts ? Object?.values(broadcasts) : []),
        },
      ],
      aggregationFields: ['count'],
      indexName: 'text_inputs',
    };
    try {
      const apisResponse = await Promise.all([
        dispatch(fetchAnalyticsOther(payloadForAllMetrics)),
        dispatch(fetchAnalyticsOther(payloadForClicks)),
        dispatch(fetchAnalyticsOther(payloadForMessageType)),
      ]);
      let analyticsData = {};
      apisResponse.forEach((item: MetricsBroadcastMessageData[] | null) => {
        if (item) {
          analyticsData = { ...analyticsData, ...item };
        }
      });
      setFetch(false);
      setBroadcastAnalyticsData(analyticsData);
    } catch {
      setFetch(false);
    }
  };

  useEffect(() => {
    fetchAllBroadcastAnalytics();
  }, []);

  return { analytics: { ...broadcastAnalyticsData }, isFetch };
};

export const useBroadcastPageContext = () => useContext(BroadcastPageContext);

export const useFetchEntityAuditLogs = (accountId: string, botId: string, entityDataForLogs?: any, setAuditLogsCallback?: any) => {
  const { broadcastId, editMode, setAuditLogs, currentStep } = useBroadcastPageContext();
  const dispatch = useDispatch();
  const isFetchingAuditLogs = useRef<boolean>(false);
  useEffect(() => {
    const entityId = entityDataForLogs ? entityDataForLogs?.id : editMode && broadcastId;
    if (entityId && !isFetchingAuditLogs.current) {
      isFetchingAuditLogs.current = true;
      dispatch(
        fetchEntityAuditLogs(
          accountId,
          botId,
          entityId,
          (logs: any[]) => {
            isFetchingAuditLogs.current = false;
            entityDataForLogs ? setAuditLogsCallback?.(logs) : setAuditLogs(logs);
          },
          entityDataForLogs?.id ? 'segment' : 'broadcast',
        ),
      );
    }
  }, [broadcastId, editMode, currentStep]);
};

export const useRelativeTime = ({ broadcastDate, timezone, scheduleType, broadcastName, forListingPage = false, alreadySentBroadcast = false }: UseRelativeTimeOptions) => {
  const [relativeTimeVal, setRelativeTimeVal] = useState<string | undefined>();

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const updateRelativeTime = () => {
      const nowInBroadcastTimeZone = moment().tz(timezone);

      const duration = broadcastDate && moment.duration(broadcastDate.diff(nowInBroadcastTimeZone));

      if (duration?.asMilliseconds() < 0 && !forListingPage) {
        if (relativeTimeVal !== 'soon') {
          setRelativeTimeVal('soon');
        }
        if (intervalId) {
          clearInterval(intervalId);
        }
      } else if (duration?.asHours() < 36) {
        if (!intervalId) {
          const relativeTimeCalculated = broadcastDate.fromNow();
          setRelativeTimeVal(relativeTimeCalculated);
          intervalId = setInterval(() => {
            const relativeTimeCalculated = broadcastDate.fromNow();
            if (relativeTimeCalculated !== relativeTimeVal) {
              setRelativeTimeVal(relativeTimeCalculated);
            }
          }, 60000);
        }
      } else if (duration) {
        const relativeTimeCalculated = broadcastDate.fromNow();
        if (relativeTimeCalculated !== relativeTimeVal) {
          setRelativeTimeVal(relativeTimeCalculated);
        }
      } else {
        setRelativeTimeVal(undefined);
      }
    };

    if ((scheduleType === broadcastScheduleConst.LATER.value || alreadySentBroadcast) && broadcastName) {
      updateRelativeTime();
    } else {
      setRelativeTimeVal(undefined);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [broadcastDate, timezone, scheduleType, relativeTimeVal]);

  return relativeTimeVal;
};

export const useFormattedEngagmentStats = (
  pageName: string,
  broadcastData: BroadcastTypeUI | { id?: string; analytics?: any } = {},
  discardPercentageFromLabel?: boolean,
  additionalParamVals: any = {},
) => {
  const engagementSettings = useSelector((state: RootState) => state.entities.broadcastEngagmentSettings);
  const pageStatsSequence = engagementSettings?.pageWiseSegregation?.[pageName];
  const [pageStatsvalues, setPageStatsvalues] = useState([]);

  useEffect(
    () => {
      const broadcastDetails: BroadcastTypeUI | any = broadcastData;
      const analyticsData = broadcastDetails?.engagementStats || (broadcastDetails?.id && { sent: 0, engaged: 0, delivered: 0, clicks: 0, broadcasts: 0, read: 0 });
      if (analyticsData) {
        const statsVals = pageStatsSequence?.map((allStatsDetails: any) => {
          const additionalAnalyticsData = { ...allStatsDetails?.valuesToSpread, ...getAdditionalParamValues(allStatsDetails?.isDynamic, additionalParamVals) }; //segmentsCount
          const { prefixToValue, hideIfZeroValue, preventNegativeValue, ...statsDetails } = allStatsDetails || {};
          const formulaForEval = statsDetails?.formula?.replace(/[{}]/gi, '');
          let hideEngagementStat = false;
          const evaluatedExpression = formulaForEval && (evaluateExpressionWithVariables(formulaForEval, { ...analyticsData, ...additionalAnalyticsData }) || 0);
          let value = formulaForEval ? evaluatedExpression : (statsDetails?.esKey && analyticsData?.[statsDetails.esKey]) || 0;
          value = isNaN(value) || !value || (value < 0 && preventNegativeValue) ? 0 : value;
          (value === 0 && hideIfZeroValue) || (analyticsData?.hideStats?.includes(allStatsDetails?.name) && (hideEngagementStat = true));

          value = statsDetails?.label?.includes('%')
            ? `${value?.toFixed(1)} %`
            : allStatsDetails?.toFixedDecimal
            ? humanizeNumberWithComma(value, allStatsDetails?.toFixedDecimal, allStatsDetails?.toFixedDecimalThreshold)
            : AddCommaSeparator(value);

          let labelName = statsDetails?.label;
          discardPercentageFromLabel && (labelName = statsDetails?.label?.replace(/%/gi, ''));

          return {
            ...statsDetails,
            label: labelName,
            name: labelName,
            value,
            hideEngagementStat,
            ...(prefixToValue ? { prefixToValue } : {}),
            ...(segmentCostUiVariables?.includes(allStatsDetails?.name) ? { carrierFeesExcluded: true } : {}),
          };
        });

        setPageStatsvalues(statsVals);
      }
    },
    additionalParamVals ? [broadcastData?.id, broadcastData?.analytics, additionalParamVals.segmentsCount] : [broadcastData?.id, broadcastData?.analytics],
  );

  return pageStatsvalues;
};

export const useIsTested = () => {
  const { selectedBroadcast, editMode } = useBroadcastPageContext();
  const platformBotObj = useCurrentPlatformBotObj();
  const isAllowTestPlatform = [platforms.TWILIO_SMS, platforms.WHATSAPP].includes(platformBotObj?.platformAsInSystemSettings || '');
  return !isAllowTestPlatform ? true : !!(editMode && selectedBroadcast?.testBroadcastInfo?.testDate);
};

export const getBroadcastEngagementSettings = (accountSettings?: any, systemSettings?: any, platform?: string) => {
  const systemSettingsEngagementsPerPlatform = platform ? systemSettings?.[platform]?.broadcastEngagement : {};
  const systemSettingsEngagementsDefaultPlatform = systemSettings?.default?.broadcastEngagement;
  const accountSettingsEngagementsDefaultPlatform = accountSettings?.default?.broadcastEngagement;
  const accountSettingsEngagementsAsPerPlatform = platform ? accountSettings?.[platform]?.broadcastEngagement : {};

  const allSettingsCombinedPriorityWise = {
    pageStatsMetadata: {
      ...systemSettingsEngagementsDefaultPlatform?.pageStatsMetadata,
      ...systemSettingsEngagementsPerPlatform?.pageStatsMetadata,
      ...accountSettingsEngagementsDefaultPlatform?.pageStatsMetadata,
      ...accountSettingsEngagementsAsPerPlatform?.pageStatsMetadata,
    },
    pageStatsSequenceConfig: {
      ...systemSettingsEngagementsDefaultPlatform?.pageStatsSequenceConfig,
      ...systemSettingsEngagementsPerPlatform?.pageStatsSequenceConfig,
      ...accountSettingsEngagementsDefaultPlatform?.pageStatsSequenceConfig,
      ...accountSettingsEngagementsAsPerPlatform?.pageStatsSequenceConfig,
    },
  };

  //Manipulate settings as per broadcast donation toggle value from accountSettings
  if (platform && accountSettings?.[platform]?.enableBroadcastDonationDisplay) {
    allSettingsCombinedPriorityWise.pageStatsMetadata = _.merge(
      {},
      allSettingsCombinedPriorityWise.pageStatsMetadata,
      getAccountEngagementSettingsForDonation(true)?.pageStatsMetadata,
    );
  }

  const accountConfigForPlatform = platform && accountSettings?.[platform];
  if (allSettingsCombinedPriorityWise?.pageStatsSequenceConfig?.broadcastEngagementPage?.some((b: any) => ['estimatedSMSSegmentCost', 'estimatedMMSCost']?.includes(b))) {
    const { costPerSMSSegmentsInCents, costPerMMSInCents } = accountConfigForPlatform || {};

    allSettingsCombinedPriorityWise.pageStatsMetadata = _.merge(
      {},
      allSettingsCombinedPriorityWise.pageStatsMetadata,
      getEngagementSettingsForSegmentCost({ costPerSMSSegmentsInCents, costPerMMSInCents })?.pageStatsMetadata,
    );
  }
  return allSettingsCombinedPriorityWise;
};

export const evaluateExpressionWithVariables = (expression?: any, variables?: any) => {
  const operators: any = {
    '+': (a: any, b: any) => a + b,
    '-': (a: any, b: any) => a - b,
    '*': (a: any, b: any) => a * b,
    '/': (a: any, b: any) => a / b,
  };

  const precedence: any = {
    '+': 1,
    '-': 1,
    '*': 2,
    '/': 2,
  };

  //tokenize converts expression like 'read/sent' into an array of tokens= ['read', '/' 'sent']. opeartor or brackets are tokens.
  //Anything separated by these are variables that are also part of tokens array.
  const tokenize = (expression?: any) => {
    const tokens = [];
    let numBuffer = '';

    for (const char of expression) {
      if ('0123456789.qwertyuiopasdfghjklzxcvbnm'.includes(char?.toLowerCase())) {
        numBuffer += char;
      } else if (char === ' ') {
        continue;
      } else {
        if (numBuffer !== '') {
          const floatValue = parseFloat(numBuffer);
          tokens.push(parseFloat(isNaN(floatValue) ? variables[numBuffer] : floatValue)); // parseFloat returns Nan for variable. else it is numeric value.
          numBuffer = '';
        }

        if ('+-*/()'.includes(char)) {
          tokens.push(char);
        }
      }
    }

    if (numBuffer !== '') {
      const floatValue = parseFloat(numBuffer);
      tokens.push(parseFloat(isNaN(floatValue) ? variables[numBuffer] : floatValue));
    }

    return tokens;
  };

  const evaluateExpression = (tokens?: any) => {
    //keep an operator & value stack while parsing tokens array.
    const valueStack = [];
    const operatorStack = [];
    //evaluate expression when receiving closing bracket or lesser priority operator
    // like when parser reaches + in expression '4*3+2' then evaluate (4*3) and continue parsing.
    try {
      for (const token of tokens) {
        if (typeof token === 'number') {
          valueStack.push(token);
        } else if (token in operators) {
          while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== '(' && precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]) {
            applyOperator(valueStack, operatorStack);
          }
          operatorStack.push(token);
        } else if (token === '(') {
          operatorStack.push(token);
        } else if (token === ')') {
          while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== '(') {
            applyOperator(valueStack, operatorStack);
          }
          operatorStack.pop(); // Pop '('
        }
      }
      while (operatorStack.length > 0) {
        applyOperator(valueStack, operatorStack);
      }
      return valueStack[0];
    } catch (error: any) {
      //log error
      return;
    }
  };

  const applyOperator = (values: any, opStack: any) => {
    const operator = opStack.pop();
    const b = values.pop();
    const a = values.pop();
    const evaluatedValue = operators[operator](a, b);
    if (isNaN(evaluatedValue) || !isFinite(evaluatedValue)) {
      throw new Error('Infinite or NaN');
    }
    values.push(evaluatedValue); //call operators object that return function of the operation at the very top here
  };

  const tokens = tokenize(expression); //numericValues, arithmetic operators, variables are tokens extracted from the original expression.
  const areTokenInvalid = tokens?.some((token: any) => {
    return isNaN(token) && !'+-*/()'.includes(token);
  });

  if (areTokenInvalid) {
    return;
  }
  return evaluateExpression(tokens);
};

export const parseFormulaForVariables = (formula?: string) => {
  const matches = formula?.match(/\{(.*?)\}/gi) || '';
  const allVars: any = [];

  if (matches) {
    for (let i = 0; i < matches.length; ++i) {
      const match = matches[i];
      allVars.push(match.substring(1, match.length - 1)); // brackets removing
    }
  }
  return allVars;
};

// function below
export const getSegregatedEngagement = (engagmentSettings?: broadcastEngagementSettingsTypeUI) => {
  if (!engagmentSettings?.pageStatsSequenceConfig) {
    return {};
  }
  const variablesNeededForIndex: any = {};
  const { pageStatsMetadata, pageStatsSequenceConfig } = engagmentSettings;
  const pageWiseSegregation: any = {
    [broadcastEngagementPages.HOME_PAGE]: [],
    [broadcastEngagementPages.BROADCAST_CARD_STATS]: [],
    [broadcastEngagementPages.BROADCAST_ENGAGEMENT_PAGE]: [],
  };

  Object.keys(pageStatsSequenceConfig)?.forEach((page: string) => {
    pageStatsSequenceConfig?.[page]?.forEach((uiVariable: string) => {
      const uiVariableData = pageStatsMetadata?.[uiVariable];
      if (uiVariable && !uiVariableData?.disabledByDefault && uiVariableData?.enableOn?.includes(page)) {
        !pageWiseSegregation?.[page] && (pageWiseSegregation[page] = []);
        const isHomePage = page === broadcastEngagementPages.HOME_PAGE;
        uiVariableData?.label &&
          pageWiseSegregation[page].push({
            label: uiVariableData?.label,
            ...(uiVariableData?.esKey ? { esKey: uiVariableData?.esKey } : {}),
            ...(uiVariableData?.formula ? { formula: uiVariableData?.formula } : {}),
            ...(uiVariableData?.prefixToValue ? { prefixToValue: uiVariableData?.prefixToValue } : {}),
            ...(uiVariableData?.toFixedDecimal ? { toFixedDecimal: uiVariableData?.toFixedDecimal, toFixedDecimalThreshold: uiVariableData?.toFixedDecimalThreshold } : {}),
            ...(uiVariableData?.hideIfZeroValue ? { hideIfZeroValue: uiVariableData?.hideIfZeroValue } : {}),
            ...(uiVariableData?.preventNegativeValue ? { preventNegativeValue: uiVariableData?.preventNegativeValue } : {}),
            ...(isHomePage ? { indexName: uiVariableData?.indexName } : {}), //for graphData add api index
            ...([...donationUiVariables, ...segmentCostUiVariables]?.includes(uiVariable) ? { name: uiVariable } : {}),
            ...(segmentCostUiVariables?.includes(uiVariable) ? { valuesToSpread: uiVariableData?.valuesToSpread, isDynamic: uiVariableData?.isDynamic } : {}),
          });
        if (uiVariableData?.esKey && uiVariableData.indexName && !variablesNeededForIndex?.[uiVariableData.indexName]?.includes(uiVariableData.esKey)) {
          !variablesNeededForIndex[uiVariableData.indexName] && (variablesNeededForIndex[uiVariableData.indexName] = []);
          variablesNeededForIndex[uiVariableData.indexName].push(uiVariableData?.esKey);
        }
        //check if api index all vars can be listed as a const somewhere on ui side == apiIndexVariablesMap??
        if (uiVariableData?.formula) {
          const varsUsedInFormula = parseFormulaForVariables(uiVariableData?.formula);

          varsUsedInFormula?.forEach((eachVar: string) => {
            if ([elasticKeysMap.READ, elasticKeysMap.SENT, elasticKeysMap.DELIVERED].includes(eachVar) && !variablesNeededForIndex?.['broadcasts']?.includes(eachVar)) {
              !variablesNeededForIndex['broadcasts'] && (variablesNeededForIndex['broadcasts'] = []);

              variablesNeededForIndex['broadcasts'].push(eachVar);
            } else if (apiIndexVariablesMap.subscriptions.includes(eachVar) && !variablesNeededForIndex?.['subscriptions']?.includes(eachVar)) {
              !variablesNeededForIndex['subscriptions'] && (variablesNeededForIndex['subscriptions'] = []);
              variablesNeededForIndex['subscriptions'].push(eachVar);
            } else if (apiIndexVariablesMap.broadcastsDonations.includes(eachVar) && !variablesNeededForIndex?.['donation']?.includes(eachVar)) {
              !variablesNeededForIndex['donation'] && (variablesNeededForIndex['donation'] = []);
              variablesNeededForIndex['donation'].push(eachVar);
            }
          });
        }
      }
    });
  });

  return { pageWiseSegregation, variablesNeededForIndex };
};

export const getAdditionalParamValues = (dynamicValsList: string[] = [], valuesInState: any) => {
  const additionalParamsMap: any = {};
  dynamicValsList?.forEach((paramName: string) => {
    if (valuesInState?.[paramName]) {
      additionalParamsMap[paramName] = valuesInState?.[paramName];
    }
  });

  return additionalParamsMap;
};

export const usePausePlayBroadcast = (handleEditClick?: () => void) => {
  const [broadcastDetail, setBroadcastDetail] = useState({ id: '', action: '' });
  const [showWarningMsg, setShowWarningMsg] = useState('');
  const [scheduleBroadcastId, setScheduleBroadcastId] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [callBackSentBroadcastTime, setCallBackSentBroadcastTime] = useState<string>('');

  const broadcasts = useBroadcasts();
  const timeZone = useTimeZone();
  const amplitudeEventProps = useAmplitudeEventProps();
  const singleBroadcast = useSelector((state: RootState) => state.entities.broadcastTime);
  const dispatch = useDispatch();
  const history = useHistory();
  const platformObj = useCurrentPlatformBotObj();
  const isWhatsAppPlatform = useIsWhatsAppPlatform(platformObj?.platform);
  const { accountId, platformBotId } = useIds();

  const handlePausePlay = (id: string, action?: string) => {
    const broadcastObj = broadcasts[id];
    action = action || (broadcastObj.paused ? 'resume' : 'pause');

    const checkIfSchuduledForpast = checkIfScheduledForPast(broadcastObj.startDate, timeZone, broadcastObj.timeZone);
    if (checkIfSchuduledForpast && action === 'resume' && broadcastObj.scheduleType === broadcastScheduleConst.LATER.value && broadcastObj.repeat === 'None') {
      history.push({
        pathname: !isWhatsAppPlatform ? `./editBroadcast/${broadcastObj.id}` : `./viewBroadcast/${broadcastObj.id}/engagement`,
        state: { broadcastType: getBroadcastTypeForAPI(broadcastObj?.type || 'Standard') },
      });
    } else {
      setBroadcastDetail({
        id,
        action,
      });
      const { recent } = getSegregatedBroadcasts(broadcasts, timeZone ? timeZone : '');
      const latestRecentBroadcast = isBroadcastSentWithin5Min(recent);
      const lastBroadcastTime = recent && recent.length && (callBackSentBroadcastTime || singleBroadcast || moment(latestRecentBroadcast?.lastUpdatedDate));
      const currentTime = moment.tz(timeZone || '');
      const timeDiffOfLastBroadcastSent = currentTime.diff(lastBroadcastTime, 'seconds');
      const broadcast = broadcasts?.[id];
      const isScheduledType = broadcast?.scheduleType === broadcastScheduleConst.LATER.value;
      const scheduledTime = isScheduledType ? moment(`${broadcast.date} ${broadcast.time}`) : null;
      if (lastBroadcastTime && timeDiffOfLastBroadcastSent < broadcastToastMsgTimeStampInSeconds && !isScheduledType) {
        setShowModal(true);
        setShowWarningMsg(`You sent a broadcast at Today | ${moment(lastBroadcastTime).format('hh:mm a z')}, are you sure you want to send another one?`);
      } else if (
        action === 'resume' &&
        !scheduleBroadcastId &&
        broadcast?.timeZone === timezoneTypes.USER &&
        isScheduledType &&
        scheduledTime &&
        scheduledTime.clone().utc().add(scheduledTime.utcOffset(), 'minutes').subtract(14, 'hours').isBefore(moment.utc())
      ) {
        setScheduleBroadcastId(id);
        setShowWarningMsg('The scheduled date & time might have passed for users in some regions. Do you want to continue scheduling at the same time?');
      } else {
        const done = (latestDateTime?: string) => {
          if (!isScheduledType) {
            const lastBroadcastTime = moment(latestDateTime).tz(timeZone!);
            setCallBackSentBroadcastTime(moment(lastBroadcastTime).format('YYYY-MM-DD[T]HH:mm:ss')!);
            dispatch(setLatestBroadcastTime(moment(lastBroadcastTime).format('YYYY-MM-DD[T]HH:mm:ss')!));
            registerAmplifyAnalytics.activateBroadcast(broadcasts?.[id]?.broadcastName, id, amplitudeEventProps);
          } else if (!history.location.pathname.includes('editBroadcast')) {
            history.push(`/${accountId}/${platformBotId}/broadcasts/${broadcastConstants.DRAFT}`);
          }
        };
        dispatch(pauseResumeBroadcast(id, action, false, timeZone || '', done, undefined));
      }
    }
  };

  const handleConfirm = () => {
    if (showModal) {
      const done = (latestDateTime?: string) => {
        const isScheduleType = broadcasts && broadcasts[broadcastDetail.id]?.scheduleType === broadcastScheduleConst.LATER.value;
        if (isScheduleType) {
          const broadcastType = getBroadcastTypeForAPI(broadcasts[broadcastDetail.id].type);
          history.push({
            pathname: `./editBroadcast/${broadcastDetail.id}`,
            state: {
              broadcastType,
            },
          });
          handleEditClick?.();
          onCancel();
        } else {
          const lastBroadcastTime = moment(latestDateTime).tz(timeZone!);
          setCallBackSentBroadcastTime(moment(lastBroadcastTime).format('YYYY-MM-DD[T]HH:mm:ss')!);
          registerAmplifyAnalytics.activateBroadcast(broadcasts?.[broadcastDetail?.id]?.broadcastName, broadcastDetail.id, amplitudeEventProps);
          dispatch(setLatestBroadcastTime(moment(lastBroadcastTime).format('YYYY-MM-DD[T]HH:mm:ss')!));
          onCancel();
        }
      };
      dispatch(pauseResumeBroadcast(broadcastDetail.id, broadcastDetail.action, false, timeZone || '', done, undefined));
    } else if (scheduleBroadcastId) {
      handlePausePlay(scheduleBroadcastId);
      onCancel();
    }
  };

  const onCancel = () => {
    setShowModal(false);
    setScheduleBroadcastId('');
    setShowWarningMsg('');
  };

  return {
    handlePausePlay,
    broadcastDetail,
    setBroadcastDetail,
    showWarningMsg,
    setShowWarningMsg,
    scheduleBroadcastId,
    setScheduleBroadcastId,
    showModal,
    setShowModal,
    callBackSentBroadcastTime,
    setCallBackSentBroadcastTime,
    handleConfirm,
    onCancel,
  };
};

export const useBroadcastTargetingCountry = (platform: string) => {
  const account = useCurrentAccount();
  return account?.config?.uiConfig?.[platform]?.targetedCountry || countryNamesAndValuesForBroadcastTargeting.ALL.value;
};
